import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgSkip = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M5.63003 2.23C5.48369 2.11037 5.30644 2.03466 5.11883 2.01165C4.93122 1.98864 4.74094 2.01928 4.57003 2.1C4.39985 2.18105 4.25605 2.30854 4.1552 2.46778C4.05435 2.62702 4.00056 2.81151 4.00003 3V21C3.99866 21.1902 4.05157 21.3768 4.15253 21.538C4.25348 21.6992 4.39831 21.8282 4.57003 21.91C4.70559 21.9694 4.85201 22.0001 5.00003 22C5.22902 22.001 5.45141 21.9233 5.63003 21.78L16.63 12.78C16.746 12.6859 16.8395 12.567 16.9035 12.432C16.9676 12.297 17.0005 12.1494 17 12C17.0005 11.8506 16.9676 11.703 16.9035 11.568C16.8395 11.433 16.746 11.3141 16.63 11.22L5.63003 2.23ZM6.00003 18.9V5.11L14.43 12L6.00003 18.9Z" }),
        React.createElement("path", { d: "M19.06 2C18.7948 2 18.5405 2.10536 18.3529 2.29289C18.1654 2.48043 18.06 2.73478 18.06 3V21C18.06 21.2652 18.1654 21.5196 18.3529 21.7071C18.5405 21.8946 18.7948 22 19.06 22C19.3252 22 19.5796 21.8946 19.7671 21.7071C19.9547 21.5196 20.06 21.2652 20.06 21V3C20.06 2.86868 20.0342 2.73864 19.9839 2.61732C19.9337 2.49599 19.86 2.38575 19.7671 2.29289C19.6743 2.20003 19.564 2.12638 19.4427 2.07612C19.3214 2.02587 19.1913 2 19.06 2Z" })));
};
