/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  Caption,
  Body1,
  Card,
  AccordionItem,
  AccordionHeading,
  AccordionHeader,
  Button,
  ArrowRightIcon,
  FileIcon,
  Stack,
  List,
  Body2,
  screen,
} from '@tumelo/designsystem'
import { VotingPolicy, VotingPolicyId } from '@tumelo/shared'
import styled from 'styled-components'
import { useAnalytics } from '../../application/analytics'
import { buildEvent } from '../../application/analytics/eventBuilders'
import { ExternalLinkWithTracking } from '../../buildingBlocks/ExternalLinkWithTracking'

interface Props {
  policy: VotingPolicy
  onSelection: (id: VotingPolicyId) => void
  onDocumentClick: (votingPolicyId: VotingPolicyId) => void
  disabled?: boolean
}

export const PolicyCard: React.FC<Props> = ({
  policy: { title, policyProvider, structuredDescription, currentDocument, votingPolicyId, imageUrl },
  onSelection,
  onDocumentClick,
  disabled = false,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <StyledCard>
      <Stack>
        <StyledImg alt="voting policy" src={imageUrl} />
        <div>
          <Body1 mb="0" semibold>
            {title}
          </Body1>
          {policyProvider && <Caption mb="0">By {policyProvider.title}</Caption>}
        </div>
        <div>
          <Body2 mb="0" semibold>
            This policy prioritises:
          </Body2>
          {structuredDescription.map(({ title, content }, i) => (
            <AccordionItem
              onChange={() => trackEvent(buildEvent.clickVotingPolicyViewMoreAccordion(votingPolicyId, title))}
              key={i}
              ariaLabel={title}
              header={
                <AccordionHeader>
                  <AccordionHeading typography="Body2">{title}</AccordionHeading>
                </AccordionHeader>
              }
            >
              {/* TO DO: This StyledDiv can be removed once the List component is updated */}
              <StyledDiv>
                <List listArray={content} />
              </StyledDiv>
            </AccordionItem>
          ))}
        </div>
        <Button
          label="I pick this one"
          variant="primary"
          iconAfter={<ArrowRightIcon />}
          onClick={() => onSelection(votingPolicyId)}
          isDisabled={disabled}
          fullWidth
        />
        {currentDocument && (
          <ExternalLinkWithTracking key={votingPolicyId} to={currentDocument.pdfUrl}>
            <Button
              label="Policy PDF"
              variant="secondary"
              iconAfter={<FileIcon />}
              onClick={() => {
                onDocumentClick(votingPolicyId)
              }}
              isDisabled={disabled}
              fullWidth
            />
          </ExternalLinkWithTracking>
        )}
      </Stack>
    </StyledCard>
  )
}

const StyledImg = styled.img`
  width: 100%;
`

const StyledCard = styled(Card)`
  max-width: 60rem;
`

const StyledDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.body2.mobile};
  ul {
    line-height: ${({ theme }) => theme.lineHeights.body2.mobile};
  }
  @media ${screen.mediumUp} {
    font-size: ${({ theme }) => theme.fontSizes.body2.default};
    ul {
      line-height: ${({ theme }) => theme.lineHeights.body2.default};
    }
  }
`
