import { useCallback } from 'react';
export function useInfiniteScroll(callbackParam) {
    const callback = useCallback((entries) => {
        if (entries[0].isIntersecting) {
            callbackParam();
        }
    }, [callbackParam]);
    const infiniteScrollRef = useCallback((node) => {
        if (!node) {
            return;
        }
        const intersectionObserver = new IntersectionObserver(callback);
        intersectionObserver.observe(node);
    }, [callback]);
    return infiniteScrollRef;
}
