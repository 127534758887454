/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { timestampToDate, BasePageProps, dateToTimestamp, Timestamp } from '@tumelo/shared'
import { sub } from 'date-fns'
import { useAppSelector, useAppDispatch } from '../application/store'
import OpenVotes, { OpenVotesLoading } from '../components/views/OpenVotes'
import * as PollSelectors from '../application/selectors/ballotComposite'
import * as SearchSelectors from '../application/selectors/searchFilters'
import { RootState } from '../application/rootReducer'
import { clearFilters } from '../application/features/searchFilters'
import { isErrorStates, isValueState } from '../application/payload'
import { BallotWithPollAndOrg } from '../application/types/PollWithOrganization/PollWithOrganization'
import { GenericError } from '../components/Error'
import { useFetchRecentPolls } from '../application/hooks'
import { useBreakdown } from '../application/features/breakdown/hooks'
import { useAccount } from '../application/features/account/hooks'
import { useInvestor } from '../application/features/investor/useInvestor'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { changeTitle } from './changeTitle'

const Page: React.FC = () => {
  const { investor } = useInvestor()
  const account = useAccount(investor)
  const breakdown = useBreakdown(account)
  useFetchRecentPolls(breakdown)
  const dispatch = useAppDispatch()
  const state = useAppSelector((state: RootState) => {
    return state
  })

  const openVotesStates = mapToOpenVotesState(state)

  switch (openVotesStates.type) {
    case 'loading': {
      return <OpenVotesLoading />
    }
    case 'error': {
      return <GenericError onClick={() => window.location.reload()} />
    }
    case 'filter': {
      return (
        <OpenVotes
          polls={openVotesStates.polls}
          awaitingResults={openVotesStates.pollsAwaitingResults}
          resetAllFilters={() => dispatch(clearFilters())}
        />
      )
    }
    case 'fulfilled': {
      return (
        <OpenVotes
          polls={openVotesStates.polls}
          awaitingResults={openVotesStates.pollsAwaitingResults}
          newPolls={openVotesStates.newToThePlatform}
          hotTopics={openVotesStates.hotTopics}
          resetAllFilters={() => dispatch(clearFilters())}
        />
      )
    }
    default: {
      return <OpenVotesLoading />
    }
  }
}

const convertTenDaysAgo = (inputDate: Date): Timestamp => {
  return dateToTimestamp(sub(inputDate, { days: 10 }))
}

export const mapToOpenVotesState = (state: RootState, today?: Date): OpenVotesStates => {
  // DETERMINE LOADING OR ERROR STATES
  const pollsWithBallots = PollSelectors.listOpenVotesFilteredBySearch(state)
  const pollsAwaitingResults = PollSelectors.listVotesAwaitingResultFilteredBySearch(state)

  if (!isValueState(pollsWithBallots)) {
    if (isErrorStates(pollsWithBallots)) {
      return { type: 'error' }
    }
    return { type: 'loading' }
  }

  if (!isValueState(pollsAwaitingResults)) {
    if (isErrorStates(pollsAwaitingResults)) {
      return { type: 'error' }
    }
    return { type: 'loading' }
  }

  // DETERMINE FILTER STATE
  if (state.searchFilters.binaryFilters.myVotes! || SearchSelectors.hasActiveFilters(state)) {
    return { type: 'filter', polls: pollsWithBallots, pollsAwaitingResults }
  }
  const hotTopics = pollsWithBallots
    .filter((pollWithBallot) => {
      return pollWithBallot.poll.isHotTopic && pollWithBallot.poll.teaserText && pollWithBallot.poll.teaserImage
    })
    .sort((a, b) => timestampToDate(a.poll.endDate).getTime() - timestampToDate(b.poll.endDate).getTime())

  const date = today ?? new Date()
  const tenDaysAgo = convertTenDaysAgo(date)

  const newToThePlatformWithPoll = pollsWithBallots
    .filter((pollWithBallot) => {
      return (
        pollWithBallot.poll.teaserText &&
        pollWithBallot.poll.teaserImage &&
        (pollWithBallot.poll.publishAt.unixNano > tenDaysAgo.unixNano || pollWithBallot.poll.isNewToPlatform)
      )
    })
    .sort((a, b) => {
      // true values first
      return a.poll.isNewToPlatform === b.poll.isNewToPlatform
        ? timestampToDate(a.poll.endDate).getTime() - timestampToDate(b.poll.endDate).getTime()
        : a.poll.isNewToPlatform
        ? -1
        : 1
    })

  return {
    type: 'fulfilled',
    polls: pollsWithBallots,
    pollsAwaitingResults,
    hotTopics,
    newToThePlatform: newToThePlatformWithPoll,
  }
}

export type OpenVotesStates =
  | {
      type: 'loading'
    }
  | {
      type: 'error'
    }
  | {
      type: 'filter'
      polls: BallotWithPollAndOrg[]
      pollsAwaitingResults: BallotWithPollAndOrg[]
    }
  | {
      type: 'fulfilled'
      polls: BallotWithPollAndOrg[]
      pollsAwaitingResults: BallotWithPollAndOrg[]
      hotTopics: BallotWithPollAndOrg[]
      newToThePlatform: BallotWithPollAndOrg[]
    }

type Props = BasePageProps

const OpenVotesPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)
  return (
    <ErrorBoundary>
      <Page />
    </ErrorBoundary>
  )
}

export default OpenVotesPage
