import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgEye = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 20.542, height: 12.167, viewBox: "0 0 20.542 12.167" }, props),
        React.createElement("defs", null,
            React.createElement("style", null, '.prefix__cls-1{stroke-width:.5px}')),
        React.createElement("g", { id: "prefix__visibility", transform: "translate(.274 -106.412)" },
            React.createElement("g", { id: "prefix__Group_11663", "data-name": "Group 11663", transform: "translate(0 106.662)" },
                React.createElement("g", { id: "prefix__Group_11662", "data-name": "Group 11662" },
                    React.createElement("path", { id: "prefix__Path_10444", d: "M19.926 112.259c-.158-.229-3.943-5.6-9.926-5.6-5.134 0-9.709 5.337-9.9 5.564a.418.418 0 0 0 0 .538c.193.228 4.768 5.564 9.9 5.564s9.709-5.337 9.9-5.564a.417.417 0 0 0 .026-.502zM10 117.5c-4.116 0-8.029-3.925-9.023-5 .993-1.076 4.9-5 9.023-5 4.816 0 8.215 3.919 9.045 4.978-.958 1.036-4.894 5.022-9.045 5.022z", className: "prefix__cls-1", "data-name": "Path 10444", transform: "translate(0 -106.662)" }))),
            React.createElement("g", { id: "prefix__Group_11665", "data-name": "Group 11665", transform: "translate(6.667 109.162)" },
                React.createElement("g", { id: "prefix__Group_11664", "data-name": "Group 11664" },
                    React.createElement("path", { id: "prefix__Path_10445", d: "M174 170.662a3.333 3.333 0 1 0 3.329 3.338 3.337 3.337 0 0 0-3.329-3.338zm0 5.833a2.5 2.5 0 1 1 2.5-2.5 2.5 2.5 0 0 1-2.5 2.505z", className: "prefix__cls-1", "data-name": "Path 10445", transform: "translate(-170.662 -170.662)" }))))));
};
