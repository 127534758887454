import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgMegaphonesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#EFEDF8" }),
        React.createElement("path", { d: "M20.83 7.16999C20.32 6.65999 19.69 6.29999 19 6.11999V2.99999C19 2.57999 18.74 2.20999 18.35 2.05999C17.96 1.90999 17.52 2.01999 17.25 2.33999C15.86 3.92999 12.48 4.99999 8.83001 4.99999H7.00001C5.85001 4.99999 4.73001 5.39999 3.84001 6.12999C2.95001 6.85999 2.33001 7.87999 2.10001 9.00999C1.87001 10.14 2.04001 11.32 2.57001 12.33C3.03001 13.21 3.75001 13.93 4.62001 14.4L6.63001 20.17C6.85001 20.78 7.28001 21.3 7.84001 21.62C8.26001 21.87 8.74001 22 9.23001 22C9.39001 22 9.54001 21.99 9.70001 21.96C10.34 21.85 10.92 21.52 11.34 21.02C11.76 20.52 11.99 19.89 11.99 19.24V15.27C14.32 15.69 16.27 16.54 17.24 17.65C17.51 17.96 17.96 18.07 18.34 17.93C18.73 17.78 18.99 17.41 18.99 16.99V13.87C19.68 13.69 20.31 13.34 20.82 12.82C21.57 12.07 21.99 11.06 21.99 9.98999C21.99 8.91999 21.57 7.91999 20.82 7.15999L20.83 7.16999ZM10 19.24C10 19.42 9.94001 19.59 9.82001 19.73C9.70001 19.87 9.54001 19.96 9.37001 19.99C9.20001 20.02 9.01001 19.99 8.86001 19.9C8.71001 19.81 8.59001 19.67 8.53001 19.51L6.96001 15.01C6.96001 15.01 6.99001 15.01 7.00001 15.01H8.83001C9.22001 15.01 9.61001 15.02 10 15.05V19.25V19.24ZM10 13.03C9.62001 13.01 9.23001 13 8.83001 13H6.99001C6.60001 13 6.22001 12.92 5.86001 12.78C5.84001 12.77 5.82001 12.76 5.79001 12.75C5.17001 12.48 4.66001 12 4.34001 11.4C4.02001 10.79 3.92001 10.08 4.05001 9.40999C4.19001 8.72999 4.56001 8.11999 5.09001 7.67999C5.62001 7.23999 6.30001 6.99999 6.99001 6.99999H8.82001C9.21001 6.99999 9.60001 6.98999 9.99001 6.96999V13.03H10ZM17 14.92C15.66 14.12 13.94 13.54 12 13.24V6.75999C13.94 6.45999 15.66 5.87999 17 5.07999V14.92ZM19.42 11.41C19.3 11.53 19.16 11.64 19 11.73V8.26999C19.15 8.35999 19.29 8.45999 19.42 8.58999C19.8 8.96999 20 9.46999 20 9.99999C20 10.53 19.79 11.04 19.42 11.41Z", fill: "#6355B5" })));
};
