import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { Body1, Body2 } from '../Typography/Body';
import { H3, H4 } from '../Typography/Headings';
import { ChevronDownIcon } from '../Icons';
import { screen } from '../_utils/mediaQueries';
import { Stack } from '../Stack';
export const AccordionItem = ({ header, ariaLabel, supportSlot, initialIsActiveState = false, isDisabled = false, controlledActiveState, onChange, children, }) => {
    const [isActive, setIsActive] = useState(initialIsActiveState);
    const toggleIsActive = () => setIsActive(!isActive);
    const isExpanded = controlledActiveState === undefined ? isActive : controlledActiveState;
    const id = ariaLabel.split(' ').join('').toLowerCase();
    const { colors } = useTheme();
    const fontColor = isDisabled ? `${colors.dark}33` : colors.dark;
    return (React.createElement(StyledContainer, null,
        React.createElement(ClickableHeading, { "aria-label": ariaLabel, disabled: isDisabled, type: "button", onClick: () => {
                if (onChange !== undefined) {
                    onChange();
                }
                toggleIsActive();
            }, "aria-expanded": isExpanded, id: id, "aria-controls": `${id}_section` },
            header,
            React.createElement(StyledStack, { flexDirection: "row", gap: "0.8rem", alignItems: "center" },
                supportSlot,
                React.createElement(StyledChevronIcon, { width: "3rem", fill: fontColor, active: isExpanded }))),
        React.createElement(Collapse, { in: isExpanded, id: `${id}_section`, role: "region", "aria-labelledby": id },
            React.createElement(StyledContent, null, children))));
};
export const AccordionHeader = styled.div `
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  @media ${screen.mediumUp} {
    padding: 1.6rem 0;
  }
  p,
  span {
    margin: 0;
    text-align: left;
  }
`;
export const AccordionHeading = ({ typography = 'Body1 semi', isDisabled, children }) => {
    const { colors } = useTheme();
    const fontColor = isDisabled ? `${colors.dark}33` : colors.dark;
    switch (typography) {
        case 'Body1':
            return (React.createElement(Body1, { as: "span", color: fontColor, mb: "0" }, children));
        case 'Body2':
            return (React.createElement(Body2, { as: "span", color: fontColor, mb: "0" }, children));
        case 'Body1 semi':
            return (React.createElement(Body1, { as: "span", semibold: true, color: fontColor, mb: "0" }, children));
        case 'Body2 semi':
            return (React.createElement(Body2, { as: "span", semibold: true, color: fontColor, mb: "0" }, children));
        case 'H3':
            return (React.createElement(H3, { as: "span", color: fontColor, mb: "0" }, children));
        case 'H4':
            return (React.createElement(H4, { as: "span", color: fontColor, mb: "0" }, children));
        default:
            return null;
    }
};
export const AccordionHeaderImage = styled.img `
  max-height: 3.2rem;
  max-width: 3.2rem;
  margin-right: 1.6rem;
  border-radius: 4px;
`;
export const AccordionHeaderIcon = styled.div `
  display: flex;
  margin-right: 1.6rem;
  svg {
    fill: ${({ theme }) => theme.colors.dark} !important;

    height: 2.2rem !important;
    width: 2.2rem !important;
  }
`;
const StyledContent = styled.div `
  padding: 1.6rem 0;
`;
const StyledStack = styled(Stack) `
  width: auto;
  margin: 0.8rem 0 0.8rem 0;
  @media ${screen.mediumUp} {
    margin: 0;
  }
`;
const StyledChevronIcon = styled(ChevronDownIcon) `
  transform: ${(p) => p.active && 'rotate(-180deg);'};
  transition: transform 0.3s;
`;
const StyledContainer = styled.div `
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.light};
`;
const ClickableHeading = styled.button `
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
  &:hover,
  &:focus-visible {
    background: ${({ disabled, theme }) => (disabled ? 'none' : theme.colors.background.medium)};
  }
  &:focus-visible {
    border-radius: 0;
    outline-offset: 0;
  }
`;
