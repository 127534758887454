/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { useAppDispatch } from '../application/store'
import { TermsModal } from '../components/TermsModal'
import { PayloadUnwrapper } from '../components/Error/PayloadUnwrapper'
import { deleteAccount } from '../application/asyncActions'
import { useTermsAndConditions } from '../application/features/investor/useTermsAndConditions'
import { useInvestor } from '../application/features/investor/useInvestor'
import { GenericError } from '../components/Error'
import { useLogout } from '../application/hooks'

export const SSOTermsAcceptanceModalConnected: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const { investor } = useInvestor()
  const { logout } = useLogout()
  const termsAndConditions = useTermsAndConditions(investor)

  return (
    <PayloadUnwrapper
      customError={<GenericError onClick={() => window.location.reload()} onLogoutClick={() => logout()} />}
      item={termsAndConditions}
    >
      {({ showTermsAcceptanceModal, acceptTermsAndConditions }) => (
        <>
          {showTermsAcceptanceModal && (
            <TermsModal
              open={isOpen}
              handleAcceptance={() => {
                setIsOpen(false)
                acceptTermsAndConditions()
              }}
              handleNonAcceptance={() => dispatch(deleteAccount())}
            />
          )}
          {children}
        </>
      )}
    </PayloadUnwrapper>
  )
}
