import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgCopyright = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1 12C1 5.92 5.92 1 12 1C18.08 1 23 5.92 23 12C23 18.08 18.08 23 12 23C5.92 23 1 18.08 1 12ZM3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 7.03 16.97 3 12 3C7.03 3 3 7.03 3 12ZM15.22 10.01C14.5711 9.25127 13.7428 8.8613 12.7649 8.86C10.9276 8.86237 9.63002 10.1517 9.63002 12C9.63002 13.85 10.94 15.14 12.77 15.14C13.75 15.14 14.57 14.75 15.23 13.98L16.69 15.32C15.76 16.45 14.35 17.07 12.64 17.07C9.60002 17.07 7.33002 14.95 7.33002 12C7.33002 9.04999 9.60002 6.92999 12.65 6.92999C14.34 6.92999 15.76 7.54999 16.68 8.66999L15.22 10.01Z" })));
};
