import * as React from 'react';
export const FaceBookIcon = ({ fill }) => {
    return (React.createElement("svg", { fill: fill, xmlns: "http://www.w3.org/2000/svg", width: "23", height: "24" },
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "a" },
                React.createElement("path", { "data-name": "Rectangle 6789", transform: "translate(-1.75 -2)", d: "M0 0h23v24H0z", fill: "none" }))),
        React.createElement("g", { "data-name": "Group 21427", transform: "translate(1.75 2)", clipPath: "url(#a)" },
            React.createElement("path", { "data-name": "Path 14113", d: "M19.8 9.798a10 10 0 1 0-11.563 9.878v-6.987H5.7V9.798h2.537v-2.2a3.529 3.529 0 0 1 3.777-3.891 15.372 15.372 0 0 1 2.239.2V6.36h-1.261a1.445 1.445 0 0 0-1.63 1.562v1.876h2.773l-.443 2.891h-2.33v6.988A10 10 0 0 0 19.8 9.798" }),
            React.createElement("path", { "data-name": "Path 14114", d: "m13.693 12.688.443-2.891h-2.773V7.922a1.445 1.445 0 0 1 1.63-1.562h1.261V3.899a15.372 15.372 0 0 0-2.239-.2A3.529 3.529 0 0 0 8.238 7.59v2.2H5.699v2.891h2.539v6.988a10.11 10.11 0 0 0 3.125 0v-6.981z", fill: "#fff" }))));
};
