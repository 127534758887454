import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgNestegg = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M14.3184 12.07C14.4733 12.065 14.6205 12.0012 14.7301 11.8917C14.8397 11.7821 14.9034 11.6349 14.9084 11.48C14.9084 11.3262 14.8473 11.1786 14.7385 11.0699C14.6298 10.9611 14.4823 10.9 14.3284 10.9H11.8484C11.9009 10.7557 11.9279 10.6035 11.9284 10.45V10.27H13.1084C13.2623 10.2649 13.4084 10.2008 13.5164 10.091C13.6243 9.98113 13.6859 9.83399 13.6884 9.68C13.7028 9.59838 13.6995 9.51463 13.6787 9.43439C13.658 9.35416 13.6203 9.2793 13.5681 9.21487C13.516 9.15043 13.4507 9.09791 13.3766 9.06085C13.3024 9.02378 13.2212 9.00304 13.1384 9H11.9384V8.41C11.9596 8.2796 12.0139 8.15683 12.0962 8.05351C12.1785 7.95018 12.2861 7.86977 12.4084 7.82C12.682 7.73529 12.9748 7.73529 13.2484 7.82C13.357 7.86449 13.4496 7.94088 13.5139 8.03908C13.5781 8.13728 13.6111 8.25267 13.6084 8.37C13.6084 8.52383 13.6695 8.67135 13.7783 8.78012C13.8871 8.88889 14.0346 8.95 14.1884 8.95C14.3424 8.94752 14.4896 8.88592 14.5994 8.77795C14.7092 8.66999 14.7733 8.52392 14.7784 8.37C14.775 8.01488 14.6629 7.66933 14.4574 7.37974C14.2518 7.09015 13.9625 6.8704 13.6284 6.75C13.1215 6.585 12.5753 6.585 12.0684 6.75C11.7281 6.87243 11.4292 7.08851 11.2063 7.37335C10.9834 7.65819 10.8455 8.0002 10.8084 8.36V9.11H10.5084C10.3546 9.11 10.2071 9.17111 10.0983 9.27988C9.98953 9.38865 9.92842 9.53617 9.92842 9.69C9.92842 9.84383 9.98953 9.99135 10.0983 10.1001C10.2071 10.2089 10.3546 10.27 10.5084 10.27H10.7484V10.5C10.6725 10.6998 10.5406 10.8734 10.3684 11C10.2717 11.0766 10.2001 11.1804 10.1627 11.2979C10.1253 11.4155 10.1238 11.5416 10.1584 11.66C10.1867 11.7464 10.2347 11.8251 10.2984 11.89C10.4071 11.9988 10.5546 12.0599 10.7084 12.06L14.3184 12.07Z" }),
        React.createElement("path", { d: "M22.9184 13.32C22.8915 13.1868 22.8378 13.0604 22.7604 12.9486C22.683 12.8369 22.5837 12.7421 22.4684 12.67C22.4684 12.67 22.4684 12.61 22.4684 12.57C23.4084 9 21.3484 5.41 18.9984 4.83C18.1418 4.64126 17.2477 4.72159 16.4384 5.06C15.4384 3.32 14.0084 2 12.4384 2C10.8684 2 9.43843 3.32 8.43843 5.13C7.59908 4.74927 6.65979 4.64763 5.75842 4.84C3.51842 5.42 1.44843 9 2.34843 12.58C2.34843 12.65 2.39843 12.71 2.41843 12.77C2.24731 12.9106 2.12772 13.1041 2.07843 13.32C1.93581 14.2757 1.98809 15.2503 2.23213 16.1852C2.47617 17.1201 2.90692 17.996 3.49843 18.76C5.22843 20.92 8.37843 21.88 12.6084 22H12.6684C16.8884 21.88 19.7684 20.92 21.4984 18.76C22.0899 17.996 22.5207 17.1201 22.7647 16.1852C23.0088 15.2503 23.061 14.2757 22.9184 13.32ZM17.2884 6.9C17.6852 6.72865 18.125 6.68327 18.5484 6.77C19.6584 7.05 21.2484 9.43 20.5484 12.07C20.5484 12.21 20.5484 12.36 19.9684 12.66C19.7984 12.76 19.5984 12.86 19.4184 12.95C18.4876 13.388 17.5118 13.7233 16.5084 13.95C17.3194 12.6783 17.7433 11.1982 17.7284 9.69C17.7307 8.74255 17.5822 7.80077 17.2884 6.9ZM12.4984 4C13.6684 4 15.7684 6.85 15.7684 9.65C15.7684 12.11 14.3384 14.17 12.6284 14.28H12.3584C10.6484 14.17 9.22843 12.11 9.22843 9.66C9.22843 6.9 11.3584 4 12.4984 4ZM6.31843 6.78C6.7901 6.70976 7.27197 6.78658 7.69842 7C7.39876 7.86886 7.24337 8.78093 7.23842 9.7C7.22353 11.1487 7.61501 12.5725 8.36842 13.81C7.37136 13.598 6.39187 13.3105 5.43843 12.95C5.43843 12.95 5.36842 12.95 5.33842 12.95C4.65842 12.69 4.39842 12.52 4.33842 12.19C3.61842 9.44 5.19843 7.06 6.31843 6.78ZM19.9384 17.51C18.5984 19.19 16.2284 19.89 12.6384 20C9.05843 19.89 6.41843 19.2 5.07842 17.53C4.39709 16.6759 4.01424 15.6222 3.98843 14.53C4.14444 14.6059 4.3047 14.6726 4.46843 14.73L4.67843 14.8C7.1679 15.7551 9.80279 16.276 12.4684 16.34H12.9984C15.5574 16.3716 18.0916 15.8354 20.4184 14.77C20.6184 14.64 20.8684 14.53 21.0384 14.44C21.0191 15.5564 20.6325 16.6354 19.9384 17.51Z" })));
};
