import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgCount2 = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.00001 12C3.00001 10.22 3.52785 8.47991 4.51678 6.99987C5.50571 5.51983 6.91132 4.36627 8.55585 3.68508C10.2004 3.0039 12.01 2.82567 13.7558 3.17293C15.5016 3.5202 17.1053 4.37737 18.364 5.63604C19.6226 6.89471 20.4798 8.49836 20.8271 10.2442C21.1743 11.99 20.9961 13.7996 20.3149 15.4442C19.6337 17.0887 18.4802 18.4943 17.0001 19.4832C15.5201 20.4722 13.78 21 12 21C9.61306 21 7.32387 20.0518 5.63604 18.364C3.94822 16.6761 3.00001 14.3869 3.00001 12ZM12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM15.87 17V15.15H11.65L13.91 13C14.4356 12.5526 14.876 12.014 15.21 11.41C15.4332 10.9414 15.5493 10.429 15.55 9.91C15.5616 9.36277 15.3968 8.82634 15.08 8.38C14.7642 7.92906 14.3224 7.58123 13.81 7.38C13.2117 7.11717 12.5634 6.98751 11.91 7C11.113 6.98754 10.3234 7.15503 9.60001 7.49C8.95124 7.78676 8.39741 8.25752 8 8.85L9.67 9.92C9.88659 9.60446 10.1835 9.35245 10.53 9.19C10.8957 9.01823 11.296 8.9327 11.7 8.94C12.1123 8.90706 12.5229 9.02036 12.86 9.26C12.9971 9.36184 13.1059 9.4971 13.1759 9.6529C13.2459 9.8087 13.2749 9.97984 13.26 10.15C13.2555 10.4392 13.1836 10.7234 13.05 10.98C12.8347 11.3542 12.5613 11.6917 12.24 11.98L8.46 15.53V17H15.87Z" })));
};
