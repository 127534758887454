/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import { Heading, TypographyCaption, Box, Button } from '@tumelo/shared'
import { HeadingWithHelp } from '../HeadingWithHelp'
import { Markdown } from '../Markdown'

interface Props {
  hasPortfolioChanged: (portfolioHasChanged: boolean) => void
  hasFundChangedText: string
  asterixText: string
  questionMarkMarkdownText: string
  topMarkdownText: string
}

export const HasFundChanged: React.FC<Props> = ({
  hasPortfolioChanged,
  hasFundChangedText,
  asterixText,
  questionMarkMarkdownText,
  topMarkdownText,
}) => (
  <>
    <Heading as="h1">
      First thing&apos;s first. <br />
      Let&apos;s check some details.
    </Heading>
    <Markdown markdown={topMarkdownText} />
    <Box>
      <Header title={hasFundChangedText} helpText={<Markdown markdown={questionMarkMarkdownText} />} />
      <TypographyCaption mt={0}>{asterixText}</TypographyCaption>
      <ButtonContainer>
        <LeftButton onClick={() => hasPortfolioChanged(true)}>Yes</LeftButton>
        <RightButton onClick={() => hasPortfolioChanged(false)}>No</RightButton>
      </ButtonContainer>
    </Box>
  </>
)

const LeftButton = styled(Button)`
  margin-right: 1rem;
`

const RightButton = styled(Button)`
  margin-left: 1rem;
`

const HeadingWithHelpFiltered: React.FC<Omit<React.ComponentProps<typeof HeadingWithHelp>, 'backgroundColor'>> = (
  props
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <HeadingWithHelp backgroundColor="transparent" {...props} />

const Header = styled(HeadingWithHelpFiltered)`
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
