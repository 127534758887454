/* eslint-disable deprecation/deprecation */
import React from 'react'
import { Body } from '@tumelo/shared'
import { selectPortfolioSelectionFlowType } from '../../application/features/config/selectors'
import { useInvestor } from '../../application/features/investor/useInvestor'
import { useAppSelector } from '../../application/store'
import { Error } from '../Error'
import { useLogout } from '../../application/hooks'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'

interface Props {
  children: React.ReactNode
}

export const BuckWarningMessage: React.FC<Props> = ({ children }) => {
  const { investor } = useInvestor()
  const { logout } = useLogout()

  const portfolioSelectionFlow = useAppSelector(selectPortfolioSelectionFlowType)

  return (
    <PayloadUnwrapper item={investor}>
      {(investor) => {
        if (!investor.investorId && portfolioSelectionFlow === 'buck') {
          return (
            <Error
              mainButton={{ text: 'Log out', onClick: logout }}
              title="Sorry, we couldn't find the details for your account..."
            >
              <Body>
                Please log out and try logging in again. If the problem persists,{' '}
                <a href="https://tumelo.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
                  get in touch
                </a>{' '}
                with our support team.
              </Body>
            </Error>
          )
        }

        return children
      }}
    </PayloadUnwrapper>
  )
}
