import React, { useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../application/store'
import { ExplicitPageView } from '../../../application/analytics'
import { selectAwsUseFederatedSignIn, selectSoftConfig } from '../../../application/features/config/selectors'
import { SignInFormValues, SignInForm } from '../Forms/SignInForm'
import { SignInSSOForm } from '../Forms/SignInSSOForm'
import { changeTitle } from '../../../containers/changeTitle'
import { LoadingForm } from '../Forms/LoadingForm'
import { getQueryVariable } from '../../../utils/getQueryVariable'
import { selectAuthError, selectAuthPending } from '../../../application/features/auth/selectors'
import { privacyPolicyUrl, termsAndConditionsUrl } from '../../../config/ExternalLinks'
import { federatedSignIn } from '../../../application/features/auth/asyncActions'

interface Props {
  clickSignUp: (email: string, password: string) => void
  clickResetPassword: (email: string) => void
  submit: (values: SignInFormValues) => Promise<void>
}

export const SignInPage: React.FC<Props> = ({ submit, clickSignUp, clickResetPassword }) => {
  changeTitle('Sign In')
  const dispatch = useAppDispatch()
  const authError = useAppSelector(selectAuthError)
  const authActionPending = useAppSelector(selectAuthPending)
  const ssoProviderName = useAppSelector((state) => selectSoftConfig(state).settings.ssoProviderName)
  const useFederatedSignIn = useAppSelector(selectAwsUseFederatedSignIn)

  const startSignIn = getQueryVariable('startSignIn') === '1'

  const onSignInWithSSO = useCallback(async () => {
    dispatch(federatedSignIn())
  }, [dispatch])

  useEffect(() => {
    if (startSignIn && useFederatedSignIn) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onSignInWithSSO()
    }
  }, [startSignIn, onSignInWithSSO, useFederatedSignIn])

  if (useFederatedSignIn) {
    if (startSignIn || authActionPending) {
      return <LoadingForm />
    }

    return (
      <>
        <ExplicitPageView page="/sign-in" />
        <SignInSSOForm
          providerName={ssoProviderName}
          privacyUrl={privacyPolicyUrl}
          termsUrl={termsAndConditionsUrl}
          signInWithSSO={onSignInWithSSO}
          error={authError?.message}
        />
      </>
    )
  }

  const onSignIn = async (values: SignInFormValues) => {
    await submit(values)
  }
  return (
    <>
      <ExplicitPageView page="/sign-in" />
      <SignInForm
        clickSignUp={clickSignUp}
        clickResetPassword={clickResetPassword}
        submit={onSignIn}
        pending={authActionPending}
        error={authError?.message}
      />
    </>
  )
}
