import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgChevronright = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.71 12.71C15.8037 12.617 15.8781 12.5064 15.9289 12.3846C15.9797 12.2627 16.0058 12.132 16.0058 12C16.0058 11.868 15.9797 11.7373 15.9289 11.6154C15.8781 11.4936 15.8037 11.383 15.71 11.29L9.71 5.29C9.5217 5.1017 9.2663 4.99591 9 4.99591C8.7337 4.99591 8.47831 5.1017 8.29 5.29C8.1017 5.47831 7.99591 5.7337 7.99591 6C7.99591 6.2663 8.1017 6.5217 8.29 6.71L13.59 12L8.29 17.29C8.1017 17.4783 7.99591 17.7337 7.99591 18C7.99591 18.2663 8.1017 18.5217 8.29 18.71C8.47831 18.8983 8.7337 19.0041 9 19.0041C9.2663 19.0041 9.5217 18.8983 9.71 18.71L15.71 12.71Z" })));
};
