import { createEntityAdapter, createSlice, EntityState, PayloadAction, isDraft } from '@reduxjs/toolkit'
import { Ballot, BallotId, PollId } from '@tumelo/shared'
import { isErrorStates, isIntermediaryState, Payload } from '../../payload'

export const ballotsAdapter = createEntityAdapter<Ballot>()
type Link = { id: PollId; ballotId: BallotId }
const linksAdaptor = createEntityAdapter<Link>()

export const ballotsInitialStateFn = () => ({
  ballots: ballotsAdapter.getInitialState(),
  links: linksAdaptor.getInitialState(),
})
type AreaState = {
  ballots: EntityState<Ballot>
  links: EntityState<Link>
}

function getInitialState(): Payload<AreaState> {
  return 'not-initialised'
}

const ballotsSlice = createSlice({
  name: 'ballots',
  initialState: getInitialState(),
  reducers: {
    setBallots: (_, action: PayloadAction<Ballot[]>) => {
      const { payload } = action
      const initState = ballotsInitialStateFn()
      return {
        ballots: ballotsAdapter.setAll(initState.ballots, payload),
        links: linksAdaptor.setAll(
          initState.links,
          payload.map((p) => ({ ballotId: p.id, id: p.pollId }))
        ),
      }
    },
    // eslint-disable-next-line consistent-return
    updateExistingBallot: (state, action: PayloadAction<Ballot>) => {
      const { payload } = action
      if (!isErrorStates(state) && !isIntermediaryState(state)) {
        ballotsAdapter.updateOne(state.ballots, { id: payload.id, changes: payload })
      }
      if (!isDraft(state)) return state
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setPending: (_, __: PayloadAction<void>) => 'pending',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setError: (_, __: PayloadAction<void>) => 'error',
  },
})

export default ballotsSlice.reducer

export const { setBallots, setPending, setError } = ballotsSlice.actions
