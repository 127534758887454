import { sub } from 'date-fns'
import {
  dateToTimestamp,
  Poll,
  PollId,
  sampleGM,
  sampleOrganization,
  samplePoll,
  sampleProposal,
  sampleInvestorStatistics,
  sampleHabitatStatistics,
} from '@tumelo/shared'
import { sleep } from '@tumelo/shared/utils'
import add from 'date-fns/add'
import { PollService } from './PollService'

const poll = samplePoll()
const passedGM = sampleGM()
passedGM.date = dateToTimestamp(sub(new Date(), { days: 5 }))
const passedPollWithNoResult: Poll = {
  title: 'This is a resolution and poll that has happened in the past with no result',
  formattedDescription: 'This is the description resolution and poll that has happened in the past and with no result',
  id: 'fa7b5843-402a-4434-a6fb-0e95d465c8c8' as PollId,
  endDate: dateToTimestamp(sub(new Date(), { days: 3 })),
  tags: [
    { id: '6', title: 'Environment' },
    { id: '9', title: 'Management' },
  ],
  tally: {
    againstCount: 0,
    forCount: 0,
    abstainCount: 0,
    withholdCount: 0,
    noActionCount: 0,
  },
  relationships: {
    generalMeeting: passedGM,
    organization: sampleOrganization(),
    proposal: sampleProposal(),
  },
  isHotTopic: false,
  publishAt: dateToTimestamp(sub(new Date(), { days: 10 })),
  isNewToPlatform: false,
}
const passPollWithResult: Poll = {
  title: 'This is a resolution and poll that has happened in the past with a result',
  formattedDescription: 'This is the description resolution and poll that has happened in the past and with a result',
  id: '7b469b13-f689-4c77-a78b-352652a5cd78' as PollId,
  endDate: dateToTimestamp(sub(new Date(), { days: 3 })),
  tags: [
    { id: '1', title: 'Environment' },
    { id: '3', title: 'Gender Equality' },
  ],
  tally: {
    againstCount: 2,
    forCount: 3,
    abstainCount: 1,
    withholdCount: 0,
    noActionCount: 0,
  },
  relationships: {
    generalMeeting: passedGM,
    organization: sampleOrganization(),
    proposal: sampleProposal(),
  },
  isHotTopic: false,
  publishAt: dateToTimestamp(sub(new Date(), { days: 10 })),
  isNewToPlatform: false,
}

const polls = [poll, passedPollWithNoResult, passPollWithResult]
const openPolls = [poll]

export const PollServiceMock: PollService = {
  async listAllPolls(): Promise<{ polls: Poll[] }> {
    await sleep(500)

    return { polls }
  },
  async fetchRecentPolls(): Promise<{ polls: Poll[] }> {
    await sleep(500)
    return { polls: openPolls }
  },
  listOpenPollsForOrganizations: async (organizationIds: string[]) => {
    await sleep(500)
    return organizationIds.reduce((map, id) => {
      map.set(
        id,
        Array.from(polls).filter((p) => p.relationships.generalMeeting.organizationId === id)
      )
      return map
    }, new Map<string, Poll[]>())
  },
  getPoll: async (pollId: PollId) => {
    await sleep()
    const poll = samplePoll()
    poll.id = pollId
    const organization = sampleOrganization()
    organization.id = poll.relationships.generalMeeting.organizationId
    return { poll, organization }
  },
  getInvestorOpenVotesCloseToExpiration: async () => {
    await sleep()
    const poll = samplePoll()
    const twoDaysTime = add(new Date(), {
      days: 2,
    })
    poll.endDate = dateToTimestamp(twoDaysTime)
    const organization = sampleOrganization()
    organization.id = poll.relationships.generalMeeting.organizationId
    return [{ poll, organization }]
  },
  getInvestorStatistics: async () => {
    await sleep()
    return sampleInvestorStatistics()
  },
  getHabitatStatistics: async () => {
    await sleep()
    return sampleHabitatStatistics()
  },
}
