/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/browser'
import { LoggerService } from './LoggerService'

export class LoggerServiceSentry implements LoggerService {
  constructor(init: { dsn: string; environment: string; release?: string }) {
    // We may want to tune the sample rate in the future if have higher volumes of traffic
    Sentry.init({
      ignoreErrors,
      denyUrls,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 0.2,
      ...init,
    })
  }

  logError(e: Error | undefined): void {
    Sentry.captureException(e)
  }

  setUser(id: string | undefined): void {
    Sentry.setUser({ id })
  }
}

const ignoreErrors = [
  'ResizeObserver loop limit exceeded',
  // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
  'TypeError: Cancelled',
  // Issue impacting WebView (most often browsers embedded in apps like email clients) - no obvious fix
  "Can't find variable: bridge",
  // https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
  "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
]
const denyUrls = [
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
]
