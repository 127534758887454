/* eslint-disable @next/next/no-img-element */
import * as React from 'react'
import { Stack } from '@tumelo/designsystem'
import { PlannedMaintenanceStyles } from './styles'

export const PlannedMaintenance = () => {
  return (
    <>
      <PlannedMaintenanceStyles />
      <Stack alignItems="center" mt="3.2rem">
        <img alt="tumelo" src="/images/tumelo_logo_white.png" width="250" height="55" />
        <h1>
          Planned maintenance: we are currently performing scheduled maintenance. The Tumelo platform will be
          unavailable until approximately 18:00.
        </h1>
      </Stack>
    </>
  )
}
