/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import { TypographyCaption, FontWeight } from '@tumelo/shared'
import { colorOptionType, ProgressWheel, Props as ProgressWheelProps } from '../../buildingBlocks/ProgressWheel'

interface Props extends ProgressWheelProps {
  stepIndex: number
  steps: [string, string, string]
  body: string
}

export const ProgressWheelInfographic: React.FC<Props> = ({ body, steps, stepIndex }) => {
  return (
    <Container>
      <Steps>
        <StyledProgressWheel
          stageOne={stepIndex >= 0 ? colorOptionType.forColor : undefined}
          stageTwo={stepIndex >= 1 ? colorOptionType.forColor : undefined}
          stageThree={stepIndex >= 2 ? colorOptionType.forColor : undefined}
        />
        <Bullets>
          {steps.map((step, index) => (
            <TypographyCaption key={step} m={0}>
              <StepBullet isComplete={index <= stepIndex} />
              <StepText isComplete={index <= stepIndex}>{step}</StepText>
            </TypographyCaption>
          ))}
        </Bullets>
      </Steps>
      <TypographyCaption>{body}</TypographyCaption>
    </Container>
  )
}

const StepBullet = styled.span<{ isComplete: boolean }>`
  display: block;
  color: ${({ theme, isComplete }) => (isComplete ? colorOptionType.forColor : theme.colors.grey.light)};
  position: absolute;
  ::before {
    content: '● ';
    left: -1.4rem;
    position: relative;
  }
`

const StepText = styled.span<{ isComplete: boolean }>`
  font-weight: ${({ isComplete }) => (isComplete ? FontWeight.semiBold : FontWeight.normal)};
`

const Steps = styled.div`
  display: flex;
  margin-bottom: 1.6rem;
`

const Bullets = styled.div`
  flex: 1;
  position: relative;
`

const Container = styled.div`
  flex-direction: column;
  display: flex;
  width: 32rem;
  justify-content: center;
`

const StyledProgressWheel = styled(ProgressWheel)`
  margin-right: 3rem;
  width: 9rem;
  align-self: flex-start;
`
