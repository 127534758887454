/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react'
import { getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { getQueryVariable } from '../../../utils/getQueryVariable'
import { LoggerContext } from '../../../utils/loggerContext'
import { useLogout } from '../../hooks'
import { AuthErrorType, logUnexpectedError, parseError } from './errors'
import { completeAuthAction, setAuthState } from './index'

const MODEL_PORTFOLIO_ID_QUERY_KEY = 'modelPortfolioId'
const IS_NEW_USER_QUERY_KEY = 'newUser'
const END_LAST_SESSION_QUERY_KEY = 'endLastSession'

// useForceNewSession ends any old sessions if "endLastSession" query variable is set
export const useForceNewSession = () => {
  const { logout } = useLogout()
  const { logError } = useContext(LoggerContext)
  const [status, setStatus] = useState<'pending' | 'error' | 'complete'>('pending')
  const endLastSession = getQueryVariable(END_LAST_SESSION_QUERY_KEY) === '1'

  useEffect(() => {
    const forceNewSession = async () => {
      try {
        if (endLastSession) {
          await logout({ withSingleLogout: false })
        }
        setStatus('complete')
      } catch (e) {
        setStatus('error')
        logError(e)
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    forceNewSession()
  }, [endLastSession, setStatus, logout, logError])

  return { status }
}

// useInitAuthState checks what state the user is currently in and sets the auth state accordingly
export const useInitAuthState = () => {
  const dispatch = useAppDispatch()
  const authState = useAppSelector((state) => state.auth.authState)
  const logger = useContext(LoggerContext)
  const modelPortfolioID = getQueryVariable(MODEL_PORTFOLIO_ID_QUERY_KEY)
  const isNewUser = getQueryVariable(IS_NEW_USER_QUERY_KEY)?.toLowerCase() === 'true'
  const targetPage = modelPortfolioID || isNewUser ? 'signUp' : 'signIn'

  React.useEffect(() => {
    if (authState === 'not-initialized') {
      getCurrentUser()
        .then(() => {
          dispatch(setAuthState('authenticated'))
        })
        .catch((err) => {
          dispatch(setAuthState(targetPage))
          const authError = parseError(err)

          logUnexpectedError(logger, authError, [
            AuthErrorType.UserUnAuthenticatedException,
            AuthErrorType.NotAuthorizedException,
          ])
        })
    }
  }, [authState, targetPage, logger, dispatch])

  React.useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      const { event, message } = payload
      if (event === 'signInWithRedirect_failure') {
        const authError = {
          type: AuthErrorType.SignInWithRedirectFailure,
          message: message ?? '',
        }
        logger.logError(new Error(`${event}: ${message} \n data: ${JSON.stringify(payload.data)}`))
        dispatch(completeAuthAction(authError))
      }
    })
    return unsubscribe
  }, [dispatch, logger])
}
