var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable deprecation/deprecation */
/* eslint-disable no-nested-ternary, react/jsx-key */
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import { BreakpointAsNumbers, Breakpoint } from '../../styles';
import { ListItemWithIndustryIcon, ListItemWithPercentage, useInfiniteScroll } from '..';
import { Loader, NoResultsMessage } from '../../buildingBlocks';
import { SubHeading } from '../../buildingBlocks/Typography';
import { sleep, useWindowWidth } from '../../utils';
// PAGE_SIZE dictates how many items are loaded in a "page" in the infinite scroller. It was chosen to be a multiple of
// 2 and 3 in order so to load entire rows
const PAGE_SIZE = 100;
const LOAD_MORE_DELAY_MS = 100;
export const ListOrganizationsAZ = ({ orgsAz, navigate, showPercentageWeights, companyFilters, organizationIndustriesFilters, clearFiltersAction, }) => {
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const upperLimit = page * PAGE_SIZE;
    const loadMore = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield sleep(LOAD_MORE_DELAY_MS);
        setPage(page + 1);
        setLoading(false);
    });
    const infiniteScrollRef = useInfiniteScroll(loadMore);
    const [isMobile, setIsMobile] = useState(false);
    const currentWindowSize = useWindowWidth();
    useEffect(() => {
        setIsMobile(BreakpointAsNumbers.mobile > currentWindowSize);
    }, [currentWindowSize]);
    const allHtmlEntries = [];
    let index = 0;
    orgsAz.forEach((orgAz) => {
        index += 1;
        allHtmlEntries.push(React.createElement(SubtitleContainer, { ref: index === upperLimit - 1 ? infiniteScrollRef : undefined, key: orgAz.category },
            React.createElement(SubHeading, { mb: "2rem", mt: "1.6rem" }, orgAz.category),
            React.createElement(HRLine, null)));
        const filteredOrgs = ApplyFilters(orgAz.orgs, companyFilters, organizationIndustriesFilters);
        filteredOrgs.forEach((org) => {
            index += 1;
            allHtmlEntries.push(showPercentageWeights ? (React.createElement(ListItemContainer, { key: org.organization.id, ref: index === upperLimit - 1 ? infiniteScrollRef : undefined },
                React.createElement(ListItemWithPercentage, { key: org.organization.id, text: org.organization.title, logoUrl: org.organization.logoUrl, onClick: () => navigate(org.organization.id), votesAvailable: org.hasOpenVotes, truncatedTextLength: isMobile ? undefined : 32, percentageWeight: org.percentageWeight }))) : (React.createElement(ListItemContainer, { key: org.organization.id, ref: index === upperLimit - 1 ? infiniteScrollRef : undefined },
                React.createElement(ListItemWithIndustryIcon, { key: org.organization.id, text: org.organization.title, logoUrl: org.organization.logoUrl, onClick: () => navigate(org.organization.id), votesAvailable: org.hasOpenVotes, truncatedTextLength: isMobile ? undefined : 32, industryCategory: org.organization.industryCategory }))));
        });
        // Remove teh letter entry if the filter has removed all companies starting with that letter
        if (filteredOrgs.length === 0) {
            allHtmlEntries.pop();
        }
    });
    const htmlEntries = allHtmlEntries.slice(0, upperLimit);
    if (htmlEntries.length === 0) {
        return (React.createElement(NoResultsMessage, { heading: "No results", body: "Sorry, there aren't any companies that match the criteria you've selected from the filters. To see all companies,\n      clear all the filters.", button: { onClick: clearFiltersAction, text: 'Clear all filters' } }));
    }
    return (React.createElement(Container, null,
        htmlEntries,
        loading ? (React.createElement(LoaderContainer, null,
            React.createElement(Loader, null))) : undefined));
};
const ApplyFilters = (unfilteredItems, companyFilters, organizationIndustriesFilters) => {
    let filteredOrgs = unfilteredItems;
    if (companyFilters.withVotes) {
        filteredOrgs = filteredOrgs.filter((org) => org.hasOpenVotes);
    }
    if (Object.values(organizationIndustriesFilters).includes(true)) {
        const industries = Object.entries(organizationIndustriesFilters).reduce((prev, [category, active]) => (active ? prev.add(category) : prev), new Set());
        filteredOrgs = filteredOrgs.filter((org) => org.organization.industryCategory && industries.has(org.organization.industryCategory));
    }
    if (companyFilters.name) {
        const fuse = new Fuse(filteredOrgs, {
            keys: ['organization.title'],
            threshold: 0.2,
        });
        filteredOrgs = fuse.search(companyFilters.name).map((x) => x.item);
    }
    return filteredOrgs;
};
const LoaderContainer = styled.div `
  width: 100%;
`;
const Container = styled.div `
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${Breakpoint.tablet}) {
    justify-content: space-evenly;
  }
`;
const ListItemContainer = styled.div `
  padding: 0 0 1rem 0;
  flex: 0 0 33%;

  @media (max-width: ${Breakpoint.tablet}) {
    flex: 0 0 48%;
  }

  @media (max-width: ${Breakpoint.mobile}) {
    flex: 0 0 100%;
  }
`;
const HRLine = styled.div `
  border: 1px solid ${({ theme }) => theme.colors.grey.light};
`;
const SubtitleContainer = styled.div `
  width: 100%;
  margin-bottom: 2rem;
`;
export const ListOrganizationsAzMemo = memo(ListOrganizationsAZ);
