import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgMoneyfilesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E3F3F3" }),
        React.createElement("path", { d: "M10.92 9.81C10.4 10.33 10.11 11.04 10.11 11.78V13.44H9.33C8.78 13.44 8.33 13.89 8.33 14.44C8.33 14.99 8.78 15.44 9.33 15.44H10.11V16.22C10.11 16.43 10.03 16.63 9.87 16.78C9.74 16.92 9.54 17 9.33 17C8.78 17 8.33 17.45 8.33 18C8.33 18.55 8.78 19 9.33 19H14.66C15.21 19 15.66 18.55 15.66 18C15.66 17.45 15.21 17 14.66 17H12C12.07 16.75 12.11 16.49 12.11 16.22V15.44H12.89C13.44 15.44 13.89 14.99 13.89 14.44C13.89 13.89 13.44 13.44 12.89 13.44H12.11V11.78C12.11 11.58 12.19 11.37 12.33 11.24C12.65 10.93 13.17 10.95 13.44 11.22C13.59 11.38 13.68 11.58 13.68 11.78C13.68 12.33 14.13 12.78 14.68 12.78C15.23 12.78 15.68 12.33 15.68 11.78C15.68 11.05 15.39 10.36 14.87 9.81C13.84 8.78 12.03 8.75 10.94 9.81H10.92ZM19.96 6.75C19.96 6.75 19.94 6.66 19.92 6.61C19.87 6.49 19.8 6.38 19.71 6.29L15.71 2.29C15.52 2.11 15.27 2 15 2H5C4.45 2 4 2.45 4 3V21C4 21.55 4.45 22 5 22H19C19.55 22 20 21.55 20 21V7C20 6.92 19.99 6.83 19.96 6.75ZM15 4.41L16.58 6H15V4.41ZM18 20H6V4H13V7C13 7.55 13.45 8 14 8H18V20Z", fill: "#1D7A6C" })));
};
