import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgSuccess = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36627 6.91131 3.68508 8.55585C3.0039 10.2004 2.82567 12.01 3.17293 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89471 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32386 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3ZM1 12C1 9.8244 1.64514 7.69767 2.85383 5.88873C4.06253 4.07979 5.78049 2.66989 7.79048 1.83732C9.80047 1.00476 12.0122 0.786924 14.146 1.21136C16.2798 1.6358 18.2398 2.68345 19.7782 4.22182C21.3166 5.7602 22.3642 7.72021 22.7886 9.85401C23.2131 11.9878 22.9952 14.1995 22.1627 16.2095C21.3301 18.2195 19.9202 19.9375 18.1113 21.1462C16.3023 22.3549 14.1756 23 12 23C9.08262 23 6.28473 21.8411 4.22183 19.7782C2.15893 17.7153 1 14.9174 1 12ZM7.29 11.29C7.38296 11.1963 7.49356 11.1219 7.61542 11.0711C7.73728 11.0203 7.86799 10.9942 8 10.9942C8.13201 10.9942 8.26272 11.0203 8.38458 11.0711C8.50644 11.1219 8.61704 11.1963 8.71 11.29L11 13.59L16.29 8.29C16.4783 8.1017 16.7337 7.99591 17 7.99591C17.2663 7.99591 17.5217 8.1017 17.71 8.29C17.8983 8.4783 18.0041 8.7337 18.0041 9C18.0041 9.2663 17.8983 9.5217 17.71 9.71L11.71 15.71C11.617 15.8037 11.5064 15.8781 11.3846 15.9289C11.2627 15.9797 11.132 16.0058 11 16.0058C10.868 16.0058 10.7373 15.9797 10.6154 15.9289C10.4936 15.8781 10.383 15.8037 10.29 15.71L7.29 12.71C7.19627 12.617 7.12188 12.5064 7.07111 12.3846C7.02034 12.2627 6.9942 12.132 6.9942 12C6.9942 11.868 7.02034 11.7373 7.07111 11.6154C7.12188 11.4936 7.19627 11.383 7.29 11.29Z" })));
};
