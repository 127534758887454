/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import { Breakpoint } from '@tumelo/shared'
import { Branding } from '../TopBarLayout/Branding'

const TopBarSimple = () => {
  return (
    <Header>
      <Flex>
        <Branding invertColours smallerMobileLogo />
        <Logo src="/images/tumelo_logo_dark.svg" alt="Powered by tumelo" />
      </Flex>
    </Header>
  )
}

const Header = styled.header`
  width: 100%;
  margin: 0;
  padding: 7rem 12rem 0 12rem;
  background-color: white;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1.8rem 1.6rem 1.7rem;
  @media (min-width: ${Breakpoint.tablet}) {
    box-shadow: none;
    padding: 7rem 12rem 0;
  }
  @media (min-width: ${Breakpoint.desktop}) {
    box-shadow: none;
    padding: 7rem 1rem 0 1rem;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: ${Breakpoint.desktop}) {
    max-width: 52rem;
    margin: 0 auto;
  }
  @media (min-width: ${Breakpoint.fullhd}) {
    max-width: 64rem;
    margin: 0 auto;
  }
`
const Logo = styled.img`
  width: 70px;
  height: auto;
  align-self: center;
`

export default TopBarSimple
