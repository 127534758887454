/* eslint-disable deprecation/deprecation */
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Poll, SmallBody } from '@tumelo/shared'
import { useAppSelector } from '../../../application/store'
import { ProfileImage } from '../../ProfileImage'
import { selectBallotByPollId } from '../../../application/selectors/ballotComposite'
import { pipe } from '../../../utils/functional/common'
import { isValueState, Payload } from '../../../application/payload'
import { selectInvestor } from '../../../application/features/investor/investorSelector'

interface Props {
  poll: Poll
}

export const TotalVotesContainer: React.FC<Props> = ({ poll }) => {
  const ballot = useAppSelector((state) => pipe(state, selectBallotByPollId(poll.id), Payload.toMaybe))
  const hasVoted = ballot?.investorVote?.response != null
  const totalCount = poll.tally.forCount + poll.tally.againstCount

  return <TotalVotes totalCount={totalCount} hasVoted={hasVoted} />
}

interface TotalVotesProps {
  totalCount: number
  hasVoted: boolean
}

export const TotalVotes: React.FC<TotalVotesProps> = ({ totalCount, hasVoted }) => {
  const investor = useAppSelector(selectInvestor)
  const { colors } = useContext(ThemeContext)

  if (totalCount === 0) {
    return <Row />
  }

  return (
    <Row>
      {isValueState(investor) && investor.email && hasVoted && (
        <Margin>
          <ProfileImage email={investor.email} size={40} />
        </Margin>
      )}
      <Count my={0} color={colors.grey.dark}>
        <strong>{totalCount}</strong> {`${totalCount === 1 ? 'person' : 'people'}`}
        <br />
        voted
      </Count>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 1rem 0.5rem 0;
  max-width: 13rem;
`

const Count = styled(SmallBody)`
  line-height: 2rem;
`

const Margin = styled.div`
  margin-right: 1rem;
`
