import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgArrowright = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.92 12.38C20.02 12.1365 20.02 11.8635 19.92 11.62C19.8724 11.4973 19.801 11.3851 19.71 11.29L12.71 4.29C12.6168 4.19676 12.5061 4.1228 12.3842 4.07234C12.2624 4.02188 12.1319 3.99591 12 3.99591C11.7337 3.99591 11.4783 4.1017 11.29 4.29C11.1968 4.38324 11.1228 4.49393 11.0723 4.61575C11.0219 4.73758 10.9959 4.86814 10.9959 5C10.9959 5.2663 11.1017 5.5217 11.29 5.71L16.59 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H16.59L11.29 18.29C11.1017 18.4783 10.9959 18.7337 10.9959 19C10.9959 19.2663 11.1017 19.5217 11.29 19.71C11.4783 19.8983 11.7337 20.0041 12 20.0041C12.2663 20.0041 12.5217 19.8983 12.71 19.71L19.71 12.71C19.801 12.6149 19.8724 12.5028 19.92 12.38Z" })));
};
