/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { FontWeight, OrganizationLogoWithFallback, SmallBody } from '@tumelo/shared'
import { useBreakpoints, hexAlpha } from '@tumelo/shared/utils'
import {
  AppNotification,
  notificationIsResolved,
  readableAppNotification,
} from '../../application/types/AppNotification/AppNotification'
import { useAnalytics } from '../../application/analytics'
import { buildEvent } from '../../application/analytics/eventBuilders'
import { useNavigateWithAbsoluteURLs } from '../../utils/useNavigateWithAbsoluteURLs'
import { createDateString } from './createDateString'
import { NotificationIcon } from './NotificationIcon'

interface Props {
  notification: AppNotification
  onClick: (notification: AppNotification) => void
}

export const NotificationCardlet: React.FC<Props> = ({ notification, onClick }) => {
  const { colors } = useTheme()
  const { trackEvent } = useAnalytics()
  const navigate = useNavigateWithAbsoluteURLs()
  const { isTabletOrSmaller } = useBreakpoints()

  return (
    <Container
      isTabletOrSmaller={isTabletOrSmaller}
      resolved={notificationIsResolved(notification)}
      onClick={() => {
        onClick(notification)
        trackEvent(buildEvent.clickNotification(notification.type, notification.title))
        navigate(notification.link)
      }}
    >
      <Row>
        <NotificationIcon notificationType={notification.type} />
        <SmallBody mb="0" mt="0" ml="0.7rem" fontWeight={FontWeight.semiBold} color={colors.grey.dark}>
          {readableAppNotification(notification.type)}
        </SmallBody>
        <DateTag>
          <SmallBody mb="0" mt="0" ml="0.7rem" fontWeight={FontWeight.semiBold} color={colors.grey.dark}>
            {createDateString(notification.createTime)}
          </SmallBody>
        </DateTag>
      </Row>
      <SecondRow>
        <SmallBody
          mb="0"
          mt="0"
          mr={isTabletOrSmaller ? '0.8rem' : '7.5rem'}
          fontWeight={FontWeight.semiBold}
          color={notification.resolution && colors.grey.dark}
        >
          {notification.title}
        </SmallBody>
        <SecondRowImageWrapper>
          {notification.logo ? (
            <OrganizationLogoWithFallback src={notification.logo} alt={notification.title} style={IMAGE_STYLE} />
          ) : null}
        </SecondRowImageWrapper>
      </SecondRow>
    </Container>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1.4rem;
  margin-bottom: 0.8rem;
`

const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1.4rem;
`

const SecondRowImageWrapper = styled.div`
  minwidth: 2.5rem;
  padding: 0;
  margin: 0;
`

interface ContainerProps {
  resolved: boolean
  isTabletOrSmaller: boolean
}

const Container = styled.div<ContainerProps>`
  cursor: pointer;
  box-sizing: border-box;
  --border-padding: 0rem;
  ${({ theme, resolved }) =>
    !resolved ? `border-left: 0.8rem solid ${theme.colors.secondary.light}` : `--border-padding: 0.8rem`};
  ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller
      ? `padding-left: calc(1.7rem + var(--border-padding))`
      : `padding-left: calc(3.2rem + var(--border-padding))`};
  ${({ isTabletOrSmaller }) => (isTabletOrSmaller ? `padding-right: 2.2rem` : `padding-right: 4rem`)};
  :hover {
    background-color: ${({ theme }) => hexAlpha(theme.colors.grey.dark, 0.07)};
  }
`

const DateTag = styled.div`
  margin-left: auto;
`

const IMAGE_STYLE: React.CSSProperties = {
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '20%',
  objectFit: 'contain',
}
