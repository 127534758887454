/* eslint-disable deprecation/deprecation */
import * as React from 'react'
import styled from 'styled-components'
import { Text, Subtitle, Breakpoint, Button } from '@tumelo/shared'

interface Props {
  title: string
  bodyText?: string | React.ReactNode
  leftButton?: {
    text: string
    onClick: () => void
  }
  mainButton?: {
    text: string
    onClick: () => void
  }
}

const supportNode = (
  <>
    <a href="https://tumelo.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
      get in touch
    </a>{' '}
    with our support team
  </>
)

export const Error: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  bodyText,
  leftButton,
  mainButton,
  children,
}) => {
  return (
    <Container>
      <InsideContainer>
        <Image src="/images/error.svg" alt="Warning sign and error imagery" />
        <Subtitle as="h1">{title}</Subtitle>
        {bodyText && <Text>{bodyText}</Text>}
        {children}
        <div style={{ height: '2rem' }} />
        <FlexSpread>
          {leftButton ? (
            <Button size="medium" inverse onClick={leftButton.onClick}>
              {leftButton.text}
            </Button>
          ) : null}
          {mainButton ? (
            <Button size="medium" onClick={mainButton.onClick}>
              {mainButton.text}
            </Button>
          ) : null}
        </FlexSpread>
      </InsideContainer>
    </Container>
  )
}

export const InlineError: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  bodyText,
  leftButton,
  mainButton,
  children,
}) => {
  const hasButton: boolean = leftButton !== undefined || mainButton !== undefined

  return (
    <Container>
      <InlineInsideContainer>
        <Subtitle as="h1">{title}</Subtitle>
        {bodyText && <Text>{bodyText}</Text>}
        {children}
        {hasButton && (
          <>
            <div style={{ height: '2rem' }} />
            <FlexSpread>
              {leftButton ? (
                <Button size="medium" inverse onClick={leftButton.onClick}>
                  {leftButton.text}
                </Button>
              ) : null}
              {mainButton ? (
                <Button size="medium" onClick={mainButton.onClick}>
                  {mainButton.text}
                </Button>
              ) : null}
            </FlexSpread>
          </>
        )}
      </InlineInsideContainer>
    </Container>
  )
}

const FlexSpread = styled.div`
  display: flex;
  justify-content: space-around;
`

export const GenericError: React.FC<{ onClick: () => void; onLogoutClick?: () => void }> = ({
  onClick,
  onLogoutClick,
}) => {
  const msg = `Try refreshing the page to get things going again. If that doesn't work `
  return (
    <Error
      mainButton={{ onClick, text: 'Try Again' }}
      leftButton={onLogoutClick ? { text: 'Log out', onClick: onLogoutClick } : undefined}
      title="Sorry, something went wrong..."
    >
      <Text>
        {msg} {supportNode}.
      </Text>
    </Error>
  )
}

export const NotFoundError: React.FC<{ goBack: () => void }> = ({ goBack }) => (
  <Error title="Sorry, we can't find what you're looking for" mainButton={{ onClick: goBack, text: 'Back' }}>
    <Text>Head back to the dashboard or {supportNode}.</Text>
  </Error>
)
export const NotFoundInlineError: React.FC<{ goBack: () => void }> = ({ goBack }) => (
  <InlineError title="Sorry, we can't find what you're looking for" mainButton={{ onClick: goBack, text: 'Back' }}>
    <Text>Head back to the dashboard or {supportNode}.</Text>
  </InlineError>
)

const Image = styled.img`
  max-width: 90vw;
  @media (min-width: ${Breakpoint.tablet}) {
    max-width: 48rem;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const InsideContainer = styled.div`
  padding: 1rem;
  max-width: 52rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InlineInsideContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
