import * as React from 'react';
export const TwitterIcon = ({ fill }) => {
    return (React.createElement("svg", { fill: fill, xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20" },
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "a" },
                React.createElement("path", { "data-name": "Rectangle 6792", d: "M0 0h20v20H0z", fill: "none" }))),
        React.createElement("g", { "data-name": "Group 21433", clipPath: "url(#a)" },
            React.createElement("path", { "data-name": "Path 14120", d: "M20 10A10 10 0 1 1 10 0a10 10 0 0 1 10 10", fillRule: "evenodd" }),
            React.createElement("path", { "data-name": "Path 14121", d: "M7.979 15.759a7.5 7.5 0 0 0 7.555-7.555q0-.172-.008-.343a5.406 5.406 0 0 0 1.325-1.375 5.3 5.3 0 0 1-1.525.418 2.665 2.665 0 0 0 1.168-1.469 5.323 5.323 0 0 1-1.686.645 2.658 2.658 0 0 0-4.525 2.422A7.539 7.539 0 0 1 4.81 5.728a2.657 2.657 0 0 0 .822 3.545 2.637 2.637 0 0 1-1.2-.332v.034a2.657 2.657 0 0 0 2.13 2.6 2.653 2.653 0 0 1-1.2.045 2.658 2.658 0 0 0 2.478 1.846 5.328 5.328 0 0 1-3.3 1.137 5.389 5.389 0 0 1-.633-.037 7.517 7.517 0 0 0 4.07 1.193", fill: "#fff" }))));
};
