/* eslint-disable deprecation/deprecation */
import * as React from 'react'
import styled from 'styled-components'
import {
  SubHeading,
  Body,
  CardContent,
  CardHeader,
  GridCard,
  CardCTA,
  ListItemWithIndustryIcon,
  OrganizationBreakdown,
} from '@tumelo/shared'
import { LoadingItem } from '../LoadingItem'
import { Payload } from '../../application/payload'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'
import { InlineError } from '../Error'

export interface TopHoldingsProps {
  breakdown: Payload<OrganizationBreakdown>
  onClickCta: () => void
  onCompanyClick: (id: string) => void
  isMinimal: boolean
}

export const TopHoldings: React.FC<TopHoldingsProps> = ({ breakdown, onClickCta, onCompanyClick, isMinimal }) => {
  const cta = isMinimal ? 'See the full companies list' : 'See all your companies'

  const helpText = isMinimal
    ? `Your pension money is pooled together with others' to buy bits (shares) of companies.

Head to the Companies page to see a breakdown.`
    : `Your pension money has been used to buy bits of these companies. These are called shares.

The percentage shows how much of your pension money is invested in a specific company.`

  return (
    <GridCard columnStart={1} columnSpan={3} stretchY>
      <PayloadUnwrapper
        item={breakdown}
        customNotInitialized={<CustomLoadingScreen isMinimal={isMinimal} />}
        customSpinner={<CustomLoadingScreen isMinimal={isMinimal} />}
        customError={<CustomError isMinimal={isMinimal} />}
      >
        {(breakdown) => {
          const numberOfOrganizations = breakdown.organizationEntries.length
          const noOrganizations = numberOfOrganizations === 0

          if (noOrganizations) {
            return <TopHoldingsCardContent isMinimal={isMinimal} numberOfOrganizations={numberOfOrganizations} />
          }

          return (
            <TopHoldingsCardContent
              isMinimal={isMinimal}
              numberOfOrganizations={breakdown.organizationEntries.length}
              helpText={helpText}
            >
              {breakdown.organizationEntries.map(({ organization, percentageWeight, hasOpenVotes }) => {
                const key = `${organization.id}_${percentageWeight}`
                return (
                  <ListItemWithIndustryIcon
                    text={organization.title}
                    percentage={isMinimal ? undefined : percentageWeight}
                    logoUrl={organization.logoUrl}
                    key={key}
                    onClick={() => onCompanyClick(organization.id)}
                    votesAvailable={hasOpenVotes}
                    industryCategory={organization.industryCategory}
                  />
                )
              })}
            </TopHoldingsCardContent>
          )
        }}
      </PayloadUnwrapper>
      <CardCTA onClick={onClickCta} callToAction={cta} />
    </GridCard>
  )
}

const TopHoldingsCardContent: React.FC<
  React.PropsWithChildren<{
    helpText?: string
    isMinimal: boolean
    numberOfOrganizations?: number
  }>
> = ({ helpText, isMinimal, numberOfOrganizations, children }) => {
  return (
    <>
      <CardHeader helpText={helpText}>
        <SubHeading id="companies">Companies</SubHeading>
        <Subtitle isMinimal={isMinimal} numberOfOrganizations={numberOfOrganizations} />
      </CardHeader>
      <CardContent>
        <Companies> {children}</Companies>
      </CardContent>
    </>
  )
}

const CustomLoadingScreen: React.FC<{ isMinimal: boolean }> = ({ isMinimal }) => (
  <TopHoldingsCardContent isMinimal={isMinimal}>
    <LoadingItem key={1} />
    <LoadingItem key={2} />
    <LoadingItem key={3} />
    <LoadingItem key={4} />
    <LoadingItem key={5} />
  </TopHoldingsCardContent>
)

const Companies = styled.div``

const Subtitle: React.FC<{ isMinimal: boolean; numberOfOrganizations?: number }> = ({
  isMinimal,
  numberOfOrganizations,
}) => {
  if (numberOfOrganizations === 0) {
    return (
      <>
        <Body>
          Your pension money is pooled together with others&apos; to buy assets like shares, bonds, property or cash.
        </Body>
        <Body>
          Currently, there are no companies invested in your pension fund. Either it&apos;s yet to be invested, or
          it&apos;s invested entirely in other assets (not shares in companies).
        </Body>
      </>
    )
  }

  if (isMinimal) {
    return (
      <Body>{`Your pension money is invested in these companies${
        numberOfOrganizations && numberOfOrganizations === 5 ? ', and more!' : '.'
      }`}</Body>
    )
  }

  return <Body>{`Top ${numberOfOrganizations || 5} companies you are invested in.`}</Body>
}

const CustomError: React.FC<{ isMinimal: boolean }> = ({ isMinimal }) => {
  const msg = `Sorry, something unexpected has happened. Try again by refreshing the page. If the problem persists `
  const supportNode = (
    <>
      <a href="https://tumelo.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
        get in touch
      </a>{' '}
      with our support team
    </>
  )
  return (
    <TopHoldingsCardContent isMinimal={isMinimal}>
      <InlineError title="Something went wrong...">
        {msg} {supportNode}.
      </InlineError>
    </TopHoldingsCardContent>
  )
}
