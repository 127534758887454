var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Caption } from '..';
export const Badge = ({ color, children }) => {
    const { colors } = useTheme();
    return React.createElement(StyledDiv, { color: color || colors.dark }, children);
};
export const BadgeContent = (_a) => {
    var { children, color = 'white' } = _a, rest = __rest(_a, ["children", "color"]);
    return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    React.createElement(Caption, Object.assign({ mb: "0", color: color }, rest, { semibold: true }), children));
};
const StyledDiv = styled.div `
  border-radius: 1.2rem;
  height: 2.4rem;
  padding: 0.8rem;
  min-width: 2.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, color }) => color || theme.colors.dark};
`;
