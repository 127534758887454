import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgVote = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M21.5 16.64C21.4921 16.5338 21.4685 16.4293 21.43 16.33V16.18L19.43 12.62C19.3423 12.464 19.2145 12.3345 19.0597 12.2448C18.9049 12.1551 18.7289 12.1085 18.55 12.11H17.55C17.2848 12.11 17.0304 12.2154 16.8429 12.4029C16.6554 12.5904 16.55 12.8448 16.55 13.11C16.55 13.3752 16.6554 13.6296 16.8429 13.8171C17.0304 14.0046 17.2848 14.11 17.55 14.11H17.96L18.86 15.69H5.24L6.14 14.11C6.40521 14.11 6.65957 14.0046 6.8471 13.8171C7.03464 13.6296 7.14 13.3752 7.14 13.11C7.14 12.8448 7.03464 12.5904 6.8471 12.4029C6.65957 12.2154 6.40521 12.11 6.14 12.11H5.54C5.36111 12.1085 5.1851 12.1551 5.03031 12.2448C4.87553 12.3345 4.74765 12.464 4.66 12.62L2.66 16.18V16.33C2.6215 16.4293 2.59792 16.5338 2.59 16.64V21C2.59 21.2652 2.69535 21.5196 2.88289 21.7071C3.07043 21.8946 3.32478 22 3.59 22H20.59C20.8552 22 21.1096 21.8946 21.2971 21.7071C21.4846 21.5196 21.59 21.2652 21.59 21V16.64H21.5ZM19.5 20.02H4.53V17.65H19.47L19.5 20.02Z" }),
        React.createElement("path", { d: "M11.1 15.34C11.1934 15.4327 11.3043 15.506 11.4261 15.5558C11.5479 15.6055 11.6784 15.6308 11.81 15.63C12.078 15.6278 12.3353 15.5242 12.53 15.34L19.38 8.66C19.5632 8.47307 19.6659 8.22175 19.6659 7.96C19.6659 7.69825 19.5632 7.44693 19.38 7.26L14.29 2.26C14.1967 2.16322 14.0849 2.08624 13.9612 2.03365C13.8375 1.98107 13.7044 1.95397 13.57 1.95397C13.4356 1.95397 13.3025 1.98107 13.1788 2.03365C13.0551 2.08624 12.9433 2.16322 12.85 2.26L6 9C5.81677 9.18693 5.71414 9.43825 5.71414 9.7C5.71414 9.96175 5.81677 10.2131 6 10.4L11.1 15.34ZM13.57 4.39L17.23 8L11.81 13.28L8.15 9.67L13.57 4.39Z" })));
};
