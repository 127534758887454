import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgLinkedinfill = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 21, height: 20, viewBox: "0 0 21 20", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M17.05 17.04H14.08V12.4C14.08 11.29 14.06 9.87 12.54 9.87C11.02 9.87 10.76 11.08 10.76 12.32V17.04H7.8V7.5H10.64V8.8H10.68C11.26 7.81 12.34 7.22 13.49 7.26C16.49 7.26 17.05 9.23 17.05 11.81V17.05V17.04ZM4.45 6.19C3.5 6.19 2.73 5.42 2.73 4.47C2.73 3.52 3.5 2.75 4.45 2.75C5.4 2.75 6.17 3.52 6.17 4.47C6.17 5.42 5.4 6.19 4.45 6.19ZM5.94 17.04H2.96V7.5H5.93V17.04H5.94ZM18.53 0H1.48C0.67 0 0 0.64 0 1.44V18.56C0 19.37 0.67 20.01 1.48 20H18.53C19.34 20 20 19.36 20.01 18.56V1.44C20 0.64 19.33 0 18.53 0Z" })));
};
