/* eslint-disable deprecation/deprecation */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TypographyCaption } from '..';
export const HamburgerIcon = ({ onClick, color }) => {
    const theme = useTheme();
    const menuColor = color || theme.colors.info.medium;
    return (React.createElement(Container, { onClick: onClick },
        React.createElement(MenuIcon, { color: menuColor, id: "active", htmlFor: "menu-btn" },
            React.createElement("span", null)),
        React.createElement(TypographyCaption, { mt: "1.6rem", color: menuColor }, "Menu")));
};
const Container = styled.button `
  cursor: pointer;
  display: flex;
  height: 16px;
  align-items: center;
  background: none;
  border: none;
  z-index: 50;
`;
const MenuIcon = styled.label `
  display: inline-block;
  cursor: pointer;
  margin: 0;
  position: relative;
  user-select: none;
  color: ${({ color }) => color};
  margin-right: 2rem;
  span {
    background: ${({ color }) => color};
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 2rem;
  }
  &:after,
  &:before {
    background: ${({ color }) => color};
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: all 0.2s ease-out;
    border-radius: 2px;
  }
  &:before {
    top: -7px;
    width: 100%;
    border-radius: 2px;
  }
  &:after {
    top: 7px;
    width: 80%;
    border-radius: 2px;
  }
`;
