import { combineReducers, createAction } from '@reduxjs/toolkit'
import account from './features/account'
import breakdown from './features/breakdown'
import config from './features/config'
import organizations from './features/organizations'
import ballots from './features/ballots'
import singleBallot from './features/singleBallot'
import polls from './features/polls'
import investor from './features/investor'
import investorVotes from './features/investorVotes'
import accountComposition from './features/accountComposition'
import searchFilters from './features/searchFilters'
import notifications from './features/notifications'
import generalMeetings from './features/generalMeetings'
import userPreferences from './features/userPreferences'
import fundFilter from './features/fundFilter'
import fundBreakdowns from './features/fundBreakdowns'
import pushSubscriptions from './features/pushSubscriptions'
import featuredVotes from './features/featuredVotes'
import statistics from './features/statistics'
import topOrganizations from './features/topOrganizations'
import auth from './features/auth'
import votingPolicies from './features/votingPolicies'
import voteResults from './features/voteResults'

const appReducer = combineReducers({
  account,
  breakdown,
  ballots,
  singleBallot,
  generalMeetings,
  organizations,
  polls,
  investor,
  investorVotes,
  accountComposition,
  searchFilters,
  config,
  notifications,
  userPreferences,
  fundFilter,
  fundBreakdowns,
  pushSubscriptions,
  featuredVotes,
  statistics,
  topOrganizations,
  auth,
  votingPolicies,
  voteResults,
})

export const logOutAction = createAction('auth/logOut')

const rootReducer = (state: any, action: any) => {
  if (action.type === logOutAction.type) {
    // eslint-disable-next-line no-param-reassign
    state = { config: state.config } // only keep the things we want to pass through
  }

  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
