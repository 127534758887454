import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgChevronleft = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.29 12.71C8.19627 12.617 8.12188 12.5064 8.07111 12.3846C8.02034 12.2627 7.9942 12.132 7.9942 12C7.9942 11.868 8.02034 11.7373 8.07111 11.6154C8.12188 11.4936 8.19627 11.383 8.29 11.29L14.29 5.29C14.4783 5.1017 14.7337 4.99591 15 4.99591C15.2663 4.99591 15.5217 5.1017 15.71 5.29C15.8983 5.47831 16.0041 5.7337 16.0041 6C16.0041 6.2663 15.8983 6.5217 15.71 6.71L10.41 12L15.71 17.29C15.8983 17.4783 16.0041 17.7337 16.0041 18C16.0041 18.2663 15.8983 18.5217 15.71 18.71C15.5217 18.8983 15.2663 19.0041 15 19.0041C14.7337 19.0041 14.4783 18.8983 14.29 18.71L8.29 12.71Z" })));
};
