import * as yup from 'yup'
import { timestampToDate, Organization, Poll, PollId, InvestorStatistics, HabitatStatistics } from '@tumelo/shared'
import { isAfter, add } from 'date-fns'
import { ServiceInjectedFetch } from '../ServiceInjectedFetch'
import { BallotWithPollAndOrg } from '../../types/PollWithOrganization/PollWithOrganization'
import { mapYupPollToAPI, pollSchema, mapAPIPollToDomain } from './API/Poll'
import { PollService } from './PollService'
import { findInvestorOpenVotesCloseToExpiration } from './utils'

export class PollServiceInjectedFetch extends ServiceInjectedFetch implements PollService {
  private injectedData?: {
    polls: Map<string, Poll>
    organizations: Map<string, Organization>
  }

  async listAllPolls() {
    if (this.injectedData === undefined) {
      this.injectedData = await this.fetchData()
    }
    return { polls: Array.from(this.injectedData.polls.values()) }
  }

  async fetchRecentPolls() {
    if (this.injectedData === undefined) {
      this.injectedData = await this.fetchData()
    }
    const polls = Array.from(this.injectedData.polls.values()).filter((poll) =>
      isAfter(timestampToDate(poll.endDate), add(new Date(), { days: -10 }))
    )

    return { polls }
  }

  async listOpenPollsForOrganizations(organizationIds: string[]): Promise<Map<string, Poll[]>> {
    if (this.injectedData === undefined) {
      this.injectedData = await this.fetchData()
    }
    const orgToPollsMap = organizationIds.reduce((map, id) => {
      map.set(
        id,
        Array.from(this.injectedData?.polls.values() ?? []).filter(
          (p) => p.relationships.generalMeeting.organizationId === id
        )
      )
      return map
    }, new Map<string, Poll[]>())

    return orgToPollsMap
  }

  async getPoll(pollId: PollId) {
    if (this.injectedData === undefined) {
      this.injectedData = await this.fetchData()
    }
    const poll = this.injectedData.polls.get(pollId)
    if (!poll) {
      return undefined
    }
    const organization = this.injectedData.organizations.get(poll.relationships.generalMeeting.organizationId)
    if (!organization) {
      return undefined
    }
    return { poll, organization }
  }

  async getInvestorOpenVotesCloseToExpiration() {
    if (this.injectedData === undefined) {
      this.injectedData = await this.fetchData()
    }

    const polls = findInvestorOpenVotesCloseToExpiration(Array.from(this.injectedData.polls.values()))
    if (!polls) {
      return undefined
    }

    const organizations = polls.map(
      (p) => this.injectedData?.organizations.get(p.relationships.generalMeeting.organizationId)
    )
    if (!organizations) {
      return undefined
    }

    return polls.map((poll, index) => ({ poll, organization: organizations[index] })) as BallotWithPollAndOrg[]
  }

  // eslint-disable-next-line class-methods-use-this
  async getInvestorStatistics(): Promise<InvestorStatistics> {
    return { pollTagsVoteCount: [] }
  }

  async getHabitatStatistics(): Promise<HabitatStatistics> {
    if (this.injectedData === undefined) {
      this.injectedData = await this.fetchData()
    }
    return {
      mostVotedOnOpenPoll: { pollId: Array.from(this.injectedData.polls.values())[1].id as PollId, voteCount: 23370 },
    }
  }

  private async fetchData(): Promise<{
    polls: Map<string, Poll>
    organizations: Map<string, Organization>
  }> {
    const data = await this.awaitableJsonFetch()
    const importedData = pollsSchema.validateSync(data)
    if (importedData) {
      return importedData.polls.map(mapYupPollToAPI).reduce(
        (data, apiPoll) => {
          const poll = mapAPIPollToDomain(apiPoll)
          data.polls.set(poll.id, poll)
          const { organization } = apiPoll.relationships
          data.organizations.set(organization.id, organization)
          return data
        },
        { polls: new Map<string, Poll>(), organizations: new Map<string, Organization>() }
      )
    }
    return { polls: new Map<string, Poll>(), organizations: new Map<string, Organization>() }
  }
}
const pollsSchema = yup
  .object({
    polls: yup.array(pollSchema).required(),
  })
  .required()
