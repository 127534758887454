import React from 'react';
import styled, { useTheme } from 'styled-components';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FaceBookIcon } from './FaceBookIcon';
import { TwitterIcon } from './TwitterIcon';
export const SocialShare = ({ faceBookShare, twitterShare }) => {
    const { colors } = useTheme();
    return (React.createElement(Container, null,
        React.createElement(FacebookButton, { onClick: faceBookShare.onClick, url: faceBookShare.url, quote: faceBookShare.text },
            React.createElement(FaceBookIcon, { fill: colors.primary.medium })),
        React.createElement(TwitterButton, { onClick: twitterShare.onClick, url: twitterShare.url, title: twitterShare.text },
            React.createElement(TwitterIcon, { fill: colors.primary.medium }))));
};
const Container = styled.div `
  display: flex;
  align-items: center;
  display: flex;
`;
const FacebookButton = styled(FacebookShareButton) `
  margin-right: 0.7rem;
  display: flex;
`;
const TwitterButton = styled(TwitterShareButton) `
  display: flex;
`;
