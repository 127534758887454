/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled, { css } from 'styled-components'
import { GridCard, Breakpoint } from '@tumelo/shared'
import { H2 } from '@tumelo/designsystem'
import { useBreakpoints } from '@tumelo/shared/utils'
import { useAppSelector, useAppDispatch } from '../../application/store'
import { Payload } from '../../application/payload'
import { compose } from '../../utils/functional/common'
import { selectInvestorFirstName } from '../../application/features/investor/investorSelector'
import { AppNotification } from '../../application/types/AppNotification/AppNotification'
import { PayloadUnwrapperM } from '../Error/PayloadUnwrapper'
import { updateNotificationResolution } from '../../application/features/notifications/asyncActions'
import { useNotifications } from '../../application/features/notifications/hooks'
import { NotificationsEmpty } from './NotificationsEmpty'
import { NotificationsList } from './NotificationsList'
import { MarkAllAsReadButton } from './MarkAllAsReadButton'

interface Props {
  flipOpenClose: () => void
}

export const NotificationsConnected: React.FC<Props> = ({ flipOpenClose }) => {
  const dispatch = useAppDispatch()
  const { isTabletOrSmaller } = useBreakpoints()
  const { notifications } = useNotifications()

  const name = useAppSelector(compose(selectInvestorFirstName, Payload.toMaybe))
  const recordNotificationOpened = (notification: AppNotification) => {
    dispatch(updateNotificationResolution(notification, 'opened'))
  }
  const onClick = (notification: AppNotification) => {
    flipOpenClose()
    recordNotificationOpened(notification)
  }

  return (
    <PayloadUnwrapperM items={[notifications]}>
      {([notifications]) => {
        return (
          <GridCard columnSpan={3} stretchY customStyles={CustomStyles}>
            <StyledHeader isTabletOrSmaller={isTabletOrSmaller}>New for you, {name}</StyledHeader>
            {notifications.length === 0 ? (
              <NotificationsEmpty />
            ) : (
              <NotificationsList notifications={notifications} onClick={onClick} />
            )}
            {notifications.length > 0 && (
              <ButtonSection>
                <MarkAllAsReadButton />
              </ButtonSection>
            )}
            <CardFooter />
          </GridCard>
        )
      }}
    </PayloadUnwrapperM>
  )
}

interface StyledHeaderProps {
  isTabletOrSmaller: boolean
}

const StyledHeader = styled(H2)<StyledHeaderProps>`
  ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? `margin: 1.5rem 2.2rem 0.55rem 2.5rem` : `margin: 3rem 4rem 1rem 4rem`};
`
const CardFooter = styled.div`
  position: relative;
  height: 2.5rem;
  @media (max-width: ${Breakpoint.mobile}) {
    height: 1.5rem;
  }
`
const CustomStyles = css`
  margin-left: 1.6rem;
  @media (max-width: ${Breakpoint.mobile}) {
    box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.16);
  }
`
const ButtonSection = styled.div`
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
`
