import { dateToTimestamp, VotingPolicy as DomainVotingPolicy, VotingPolicyId } from '@tumelo/shared'
import { UserPreferences as DomainUserPreferences } from '../../types/UserPreferences/UserPreferences'
import {
  UserPreferences,
  UserPreferences_SubscribedToPushNotifications as pbSubscribedToPushNotifications,
} from '../../../utils/proto/tumelo/platform/v1/user_preferences'
import { VotingPolicy } from '../../../utils/proto/tumelo/dashboardbff/v1/voting_policy'

export const mapUserPreferencesToDomain = (up: UserPreferences): DomainUserPreferences => {
  return {
    ...up,
    subscribedToPushNotifications: mapSubscribedToPushNotificationsToDomain(up.subscribedToPushNotificationsState),
  }
}

const mapSubscribedToPushNotificationsToDomain = (
  subscribedToPushNotifications: pbSubscribedToPushNotifications
): boolean | undefined => {
  switch (subscribedToPushNotifications) {
    case pbSubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_TRUE: {
      return true
    }
    case pbSubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_FALSE: {
      return false
    }
    default: {
      return undefined
    }
  }
}

export const mapSubscribedToPushNotificationsToPB = (
  subscribedToPushNotifications: boolean | undefined
): pbSubscribedToPushNotifications => {
  switch (subscribedToPushNotifications) {
    case true: {
      return pbSubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_TRUE
    }
    case false: {
      return pbSubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_FALSE
    }
    default: {
      return pbSubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_INVALID
    }
  }
}

export const mapVotingPolicyToDomain = ({
  votingPolicyId,
  title,
  structuredDescription,
  policyProvider,
  currentDocument,
  updateTime,
  imageUrl,
}: VotingPolicy): DomainVotingPolicy => {
  return {
    votingPolicyId: votingPolicyId as VotingPolicyId,
    title,
    structuredDescription,
    policyProvider,
    currentDocument: currentDocument && {
      ...currentDocument,
      issueTime: currentDocument?.issueTime && dateToTimestamp(currentDocument.issueTime),
    },
    updateTime: updateTime && dateToTimestamp(updateTime),
    imageUrl: imageUrl || '/images/policy_card.png',
  }
}
