import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgIndustrialssmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M8 15C7.45 15 7 15.45 7 16V17C7 17.55 7.45 18 8 18C8.55 18 9 17.55 9 17V16C9 15.45 8.55 15 8 15ZM12 15C11.45 15 11 15.45 11 16V17C11 17.55 11.45 18 12 18C12.55 18 13 17.55 13 17V16C13 15.45 12.55 15 12 15ZM15 17C15 17.55 15.45 18 16 18C16.55 18 17 17.55 17 17V16C17 15.45 16.55 15 16 15C15.45 15 15 15.45 15 16V17ZM21.98 11.92C21.98 11.92 21.98 11.87 21.98 11.85L20.65 2.85C20.58 2.36 20.16 2 19.66 2H16.33C15.83 2 15.41 2.36 15.34 2.85L14.13 11H12.99V8C12.99 7.61 12.76 7.25 12.4 7.09C12.04 6.93 11.62 6.99 11.33 7.26L8.49 9.78V8.01C8.49 7.62 8.26 7.26 7.9 7.1C7.54 6.94 7.12 7 6.83 7.27L2.34 11.25C2.13 11.44 2 11.71 2 12V21C2 21.55 2.45 22 3 22H21C21.55 22 22 21.55 22 21V12C22 12 21.99 11.95 21.98 11.92ZM17.19 4H18.8L19.84 11H16.16L17.2 4H17.19ZM19.99 20H4V12.45L6.5 10.23V12C6.5 12.39 6.73 12.75 7.09 12.91C7.45 13.07 7.87 13.01 8.16 12.74L11 10.22V11.99C11 12.54 11.45 12.99 12 12.99H20V19.99L19.99 20Z" })));
};
