/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import { Modal as MatModal } from '@mui/material'
import { Breakpoint, FontWeight, LinkButton, SmallBody, SubHeading } from '@tumelo/shared'
import { useLogout } from '../../application/hooks'

export const EmptyAccountModalConnected: React.FC = () => {
  const { logout } = useLogout()

  return (
    <CenteredModal open hideBackdrop>
      <Container>
        <ModalContent>
          <ModalHeader>
            <SubHeading mb="0">Looks like your pensions pot is empty.</SubHeading>
          </ModalHeader>
          <div>
            <p>
              Once you start contributing to your pension, we can show you where your money is going and let you vote on
              issues that matter to you.
            </p>
            <p>
              If you have made pension contributions recently and are confused by this message, don&apos;t worry - the
              platform should update soon!
            </p>
          </div>
          <ModalFooter>
            <LogOut onClick={() => logout()}>
              <ButtonText fontWeight={FontWeight.bold}>Log out for now</ButtonText>
            </LogOut>
          </ModalFooter>
        </ModalContent>
      </Container>
    </CenteredModal>
  )
}

const CenteredModal = styled(MatModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.light};
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2rem;
  padding: 6rem;
  outline: none;
  overflow-y: auto;
  @media only screen and (max-device-width: ${Breakpoint.mobile}) {
    padding: 2.6rem 2.4rem 2.9rem 2.4rem;
    max-width: 80%;
    max-height: 44.4rem;
  }
`

const ModalContent = styled.div`
  max-width: 50rem;
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`

const LogOut = styled(LinkButton)`
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: ${Breakpoint.mobile}) {
    margin-right: 2.2rem;
  }
`

const ButtonText = styled(SmallBody)`
  ${({ theme }) => `color: ${theme.colors.warning.medium}`};
`
