/* eslint-disable deprecation/deprecation */
/* eslint-disable no-console */
import * as React from 'react';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import { Breakpoint } from '../../styles';
import { groupOrganizationsByIndustry, calculateIndustryOther, } from '../../application';
import { NoResultsMessage } from '../../buildingBlocks';
import { OtherGroup } from '../OtherGroup';
import { IndustryGroup } from '.';
export const ListOfIndustryGroups = ({ breakdown, onItemClick, companyFilters, organizationIndustriesFilters, clearFiltersAction, }) => {
    const industryGroups = groupOrganizationsByIndustry(breakdown);
    const othersNoneIndustry = calculateIndustryOther(breakdown);
    const filteredIndustryGroups = applyFilters(industryGroups, companyFilters, organizationIndustriesFilters);
    const groups = filteredIndustryGroups.industries.map((industry) => (React.createElement(IndustryGroup, { onItemClick: onItemClick, industry: industry, key: `industry-grp-${industry.industryCategory}` })));
    if (companyFilters === undefined && breakdown.others !== undefined && breakdown.others !== 0.0) {
        groups.push(React.createElement(OtherGroup, { totalPercentage: othersNoneIndustry * 100, key: "industry-grp-others-none" }));
    }
    const companiesCount = filteredIndustryGroups.industries.map((x) => x.organizations.length).reduce((x, y) => x + y, 0);
    if (companiesCount === 0 && clearFiltersAction) {
        return (React.createElement(NoResultsMessage, { heading: "No results", body: "Sorry, there aren't any companies that match the criteria you've selected from the filters. To see all companies,\n      clear all the filters.", button: { onClick: clearFiltersAction, text: 'Clear all filters' } }));
    }
    return React.createElement(Container, null, groups);
};
const applyFilters = (industryGroups, companyFilters, organizationIndustriesFilters) => {
    const filteredIndustryGroups = Object.assign({}, industryGroups);
    if (companyFilters) {
        if (companyFilters.withVotes) {
            filteredIndustryGroups.industries = filteredIndustryGroups.industries.filter((industry) => industry.organizations.some((org) => org.hasOpenVotes));
        }
        if (companyFilters.name) {
            filteredIndustryGroups.industries = filteredIndustryGroups.industries.map((industry) => {
                const updatedIndustry = Object.assign({}, industry);
                const fuse = new Fuse(updatedIndustry.organizations, {
                    keys: ['organization.title'],
                    threshold: 0.2,
                });
                updatedIndustry.organizations = fuse.search(companyFilters.name).map((x) => x.item);
                return updatedIndustry;
            });
        }
    }
    if (organizationIndustriesFilters) {
        if (Object.values(organizationIndustriesFilters).includes(true)) {
            const industries = Object.entries(organizationIndustriesFilters).reduce((prev, [industryCategory, enabled]) => (enabled ? prev.add(industryCategory) : prev), new Set());
            filteredIndustryGroups.industries = filteredIndustryGroups.industries.map((industry) => {
                const updatedIndustry = Object.assign({}, industry);
                updatedIndustry.organizations = industry.organizations.filter((org) => industries.has(org.organization.industryCategory));
                return updatedIndustry;
            });
        }
    }
    return filteredIndustryGroups;
};
const Container = styled.div `
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${Breakpoint.tablet}) {
    justify-content: space-evenly;
  }
`;
