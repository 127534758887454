import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgInformation = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36627 6.91131 3.68508 8.55585C3.0039 10.2004 2.82567 12.01 3.17293 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89471 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32386 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3ZM1 12C1 9.8244 1.64514 7.69767 2.85383 5.88873C4.06253 4.07979 5.78049 2.66989 7.79048 1.83732C9.80047 1.00476 12.0122 0.786924 14.146 1.21136C16.2798 1.6358 18.2398 2.68345 19.7782 4.22182C21.3166 5.7602 22.3642 7.72021 22.7886 9.85401C23.2131 11.9878 22.9952 14.1995 22.1627 16.2095C21.3301 18.2195 19.9202 19.9375 18.1113 21.1462C16.3023 22.3549 14.1756 23 12 23C9.08262 23 6.28473 21.8411 4.22183 19.7782C2.15893 17.7153 1 14.9174 1 12Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11 7C11 6.44772 11.4477 6 12 6H12.01C12.5623 6 13.01 6.44772 13.01 7C13.01 7.55228 12.5623 8 12.01 8H12C11.4477 8 11 7.55228 11 7Z" })));
};
