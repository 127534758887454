/* eslint-disable deprecation/deprecation */
import {
  Ballot,
  VoteMethod,
  BallotId,
  dateToTimestamp,
  InvestorVoteResponse,
  PollId,
  ProposalId,
  VotingPolicyId,
} from '@tumelo/shared'
import { APIServiceBaseClass } from '../../../utils/api'
import {
  Ballot as ApiBallot,
  VotingRecommendation as ApiVotingRecommendation,
  InvestorBallotComment,
  VoteOptions,
  InvestorVoteVoteMethodEnum,
} from '../../../utils/api/gen'
import { head } from '../../../utils/functional/arr'
import { VotingRecommendation, VotingRecommendationId } from '../../features/votingPolicies/types'
import { getAndExpandPagedItemsViaPageToken } from '../utils'
import { convertToVoteOptions, convertVoteOptionToResponse } from './utils'
import { VotingService } from './VotingService'

export const fromDto = (b: ApiBallot): Ballot => {
  const convertToResponse = (voteOption: VoteOptions) => {
    const response = convertVoteOptionToResponse(voteOption)
    if (response === undefined) {
      throw new Error(`invalid vote option on ballot ${b.id}`)
    }
    return response
  }
  const toVoteMethod = (method: InvestorVoteVoteMethodEnum): VoteMethod => {
    if (method === InvestorVoteVoteMethodEnum.Automatic) return VoteMethod.Automatic
    return VoteMethod.Manual
  }
  return {
    id: b.id as BallotId,
    pollId: b.pollId as PollId,
    createTime: dateToTimestamp(b.createTime),
    expirationTime: dateToTimestamp(b.expirationTime),
    investorVote: b.investorVote && {
      response: convertToResponse(b.investorVote.response),
      responseTime: dateToTimestamp(b.investorVote.responseTime),
      voteMethod: toVoteMethod(b.investorVote.voteMethod),
    },
    comment: b.investorComment
      ? {
          ...b.investorComment,
          createTime: dateToTimestamp(b.createTime),
        }
      : undefined,
  }
}

export const mapApiVotingRecommendationToVotingRecommendation = (vR: ApiVotingRecommendation): VotingRecommendation => {
  return {
    id: vR.id as VotingRecommendationId,
    votingPolicyId: vR.votingPolicyId as VotingPolicyId,
    organizationId: vR.organizationId,
    generalMeetingId: vR.generalMeetingId,
    proposalId: vR.proposalId as ProposalId,
    recommendation: {
      instruction: vR.recommendation.instruction as InvestorVoteResponse,
      rationale: vR.recommendation.rationale,
    },
    createTime: dateToTimestamp(vR.createTime),
    updateTime: dateToTimestamp(vR.updateTime),
  }
}

export class VotingServiceAPI extends APIServiceBaseClass implements VotingService {
  async castVote(ballotId: BallotId, response: InvestorVoteResponse) {
    const api = await this.getVotingApi()
    const { habitatId, investorId } = this

    const voteOption = convertToVoteOptions(response)
    if (voteOption === undefined) {
      throw new Error(`invalid vote response on ballot ${ballotId}`)
    }
    const ballot = await api.castVote({
      ballotId,
      habitatId,
      investorId,
      castVoteBody: { response: voteOption },
    })
    return fromDto(ballot)
  }

  async listInvestorBallots(hasVoted = false, ballotExpireAfterDate: Date | undefined = undefined) {
    const api = await this.getVotingApi()
    const { habitatId, investorId } = this
    const getPageFn = (token: string | undefined) =>
      api.listInvestorBallots({
        habitatId,
        investorId,
        nextPageToken: token,
        pageSize: 100,
        hasVoted,
        expireAfter: ballotExpireAfterDate,
      })
    const ballots = await getAndExpandPagedItemsViaPageToken(getPageFn, (x) => x.ballots)
    return ballots.map(fromDto)
  }

  async getInvestorBallotByBallotId(ballotId: BallotId) {
    const api = await this.getVotingApi()
    const { habitatId, investorId } = this
    const { ballot } = await api.getInvestorBallot({ habitatId, investorId, ballotId })
    return fromDto(ballot)
  }

  async getInvestorBallotByPollId(pollId: PollId) {
    const api = await this.getVotingApi()
    const { habitatId, investorId } = this
    const { ballots } = await api.listInvestorBallots({ habitatId, investorId, pollIds: [pollId], pageSize: 1 })
    if (ballots.length === 0) {
      return undefined
    }
    return fromDto(ballots[0])
  }

  async sendComment(ballotId: BallotId, comment: string): Promise<Ballot> {
    const api = await this.getVotingApi()
    const { habitatId, investorId } = this
    const ballot = await api.addBallotComment({
      habitatId,
      investorId,
      ballotId,
      investorBallotComment: {
        content: comment,
      } as InvestorBallotComment,
    })
    return fromDto(ballot)
  }

  async listVotingRecommendationForProposalId(
    votingPolicyId: VotingPolicyId,
    proposalId: ProposalId
  ): Promise<VotingRecommendation | 'not-configured'> {
    const api = await this.getVotingApi()
    const { habitatId } = this
    const { votingRecommendations } = await api.listHabitatVotingRecommendations({
      habitatId,
      votingPolicyId,
      proposalIds: [proposalId],
    })
    const votingRecommendation = head(votingRecommendations)
    if (votingRecommendation === undefined) return 'not-configured'
    return mapApiVotingRecommendationToVotingRecommendation(votingRecommendation)
  }
}
