/* eslint-disable deprecation/deprecation */
import React from 'react';
import styled from 'styled-components';
import { SmallHeading } from '../../../../buildingBlocks';
export const DesktopSearchMenu = ({ heading, children }) => (React.createElement(Container, null,
    React.createElement(Sidebar, null,
        React.createElement(SmallHeading, null, heading),
        React.createElement(Body, null, children))));
const Container = styled.div `
  box-shadow: 8px 0 12px 0 rgba(0, 0, 0, 0.05);
  margin-right: 2rem;
`;
const Sidebar = styled.div `
  align-items: center;
  width: 30rem;
  transition: width 300ms;
  padding: 3.5rem 2rem 2rem;
  box-sizing: border-box;
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  margin: 3rem 0 6rem 0rem;
`;
