import mixpanel from 'mixpanel-browser'
import { Analytics } from './Analytics'

const PAGE_VIEW_EVENT = 'Page View'

export const createAnalyticsMixpanel = (token: string, apiHost: string): Analytics => {
  mixpanel.init(token, { api_host: apiHost }, '')

  return {
    createIdentity: (userId) => mixpanel.alias(userId),
    signOutUser: () => mixpanel.reset(),
    registerExternalLinkClickListener: () =>
      mixpanel.track_links('.mixpanel-link', 'click_external_cta', (el: HTMLAnchorElement) => {
        return { title: el.textContent }
      }),
    pageView: (page) => mixpanel.track(PAGE_VIEW_EVENT, { page }),
    setUserProperties: (userId, { investorId, habitat }) => {
      if (userId !== '') mixpanel.identify(userId)
      if (investorId) mixpanel.people.set({ investorId })
      mixpanel.people.set({ habitat })
    },
    trackEvent: ({ name, props }) => mixpanel.track(name, props),
  }
}
