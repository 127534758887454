/**
 * ServiceInjectedFetch is a base class for injected fetch service implementations.
 *
 * Classes extending this class should take care to call their super constructor in order
 * to ensure the path property is properly initialised. The TS compiler should ensure this
 * is the case anyway.
 *
 * An `InjectedFetch` service implementation parses a local JSON file in order to surface
 * the contents through the service's public API. The location of the data file itself
 * must be parsed from the JSON configuration which is passed as the `data` property to the
 * constructor. This simple base class takes care of parsing the `path` property of the config
 * and initialising the `dataFilePath` property that extending classes can use to locate thier
 * data file.
 */
export class ServiceInjectedFetch {
  /**
   * path to file to fetch
   */
  protected readonly dataFilePath: string

  constructor(path: string, pathPrefix: string) {
    this.dataFilePath = `${pathPrefix}${path}`
  }

  protected awaitableJsonFetch = async (): Promise<any> => {
    const response = await fetch(this.dataFilePath)
    return response.json()
  }
}
