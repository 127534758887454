/* eslint-disable deprecation/deprecation */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import { useAutocomplete } from '@mui/material';
import { TagButton } from '../../buildingBlocks/TagButton';
import { FontWeight, Span } from '../..';
import { SearchIcon } from './SearchIcon';
export const AutoComplete = ({ options, selected, selectOption, deleteOption, name, placeholder: pPlaceholder, }) => {
    const { getRootProps, getInputProps, getTagProps, getListboxProps, getOptionProps, groupedOptions, focused, setAnchorEl, } = useAutocomplete({
        id: name,
        multiple: true,
        options,
        getOptionLabel: (option) => option.label,
    });
    const [fieldValue, setFieldValue] = React.useState('');
    const onChange = (e) => {
        setFieldValue(e.currentTarget.value);
    };
    const optionsToShow = options.filter((o) => !selected.includes(o) && o.label.toLocaleLowerCase().includes(fieldValue.toLocaleLowerCase()));
    const placeholder = selected.length > 0 ? '' : pPlaceholder;
    const onKeyDown = (e) => {
        if (e.key === 'Enter' && optionsToShow.length === 1) {
            onSelectOption(optionsToShow[0]);
        }
    };
    const onSelectOption = (option) => {
        setFieldValue('');
        selectOption(option);
    };
    return (React.createElement(Container, null,
        React.createElement("div", Object.assign({}, getRootProps()),
            React.createElement(InputWrapper, { ref: setAnchorEl },
                React.createElement(StyledSearchIcon, null),
                selected.map((option, index) => (React.createElement(Selected, { key: getTagProps({ index }).key },
                    React.createElement(TagButton, { title: option.label, onClick: () => deleteOption(option) })))),
                React.createElement("input", Object.assign({}, getInputProps(), { value: fieldValue, onChange: onChange, placeholder: placeholder, onKeyDown: onKeyDown })))),
        groupedOptions.length > 0 || focused ? (React.createElement(Listbox, Object.assign({}, getListboxProps()), optionsToShow.map((option, index) => {
            return (React.createElement(Option, Object.assign({ key: option.value }, getOptionProps({ option, index }), { tabIndex: 0, onClick: () => onSelectOption(option) }),
                React.createElement(StyledSubstringHighlight, null,
                    React.createElement(SubstringHighlight, { string: option.label, substring: fieldValue }))));
        }))) : null));
};
export const SubstringHighlight = ({ string, substring }) => {
    const startOfSubstring = string.toLocaleLowerCase().indexOf(substring.toLocaleLowerCase());
    const prefix = string.substring(0, startOfSubstring);
    const middle = string.substring(startOfSubstring, startOfSubstring + substring.length);
    const suffix = string.substring(startOfSubstring + substring.length, string.length);
    if (!substring) {
        return React.createElement("span", null, string);
    }
    return (React.createElement(React.Fragment, null,
        prefix,
        React.createElement(Span, { fontWeight: FontWeight.semiBold }, middle),
        suffix));
};
const Selected = styled.div `
  padding: 0.2rem;
`;
const Container = styled.div `
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  position: relative;
`;
const StyledSearchIcon = styled(SearchIcon) `
  margin: 1rem;
  width: auto;
  height: 2rem;
  fill: ${({ theme }) => theme.colors.grey.medium};
  stroke: ${({ theme }) => theme.colors.grey.medium};
`;
const InputWrapper = styled.div `
  width: 100%;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  box-shadow: 0 2px 6px 0 ${({ theme }) => theme.colors.boxShadow};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;

  & input {
    height: 4.6rem;

    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;

    color: ${({ theme }) => theme.colors.dark};
    border-radius: 1rem;
    font-size: 1.5rem;

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey.medium};
    }
  }
`;
const Listbox = styled.ul `
  width: 100%;
  max-width: 500px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 ${({ theme }) => theme.colors.boxShadow};
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white};
`;
const Option = styled.li `
  cursor: pointer;
  margin: 0;
  padding: 0.1rem 0 0.1rem 2rem;
  width: 100%;
  list-style: none;
  display: flex;
  width: 100%;
  font-size: 1.5rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.light};
  }
`;
const StyledSubstringHighlight = styled.div `
  color: ${({ theme }) => theme.colors.black};
`;
