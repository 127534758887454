/* eslint-disable deprecation/deprecation */
import React, { FC } from 'react'
import styled from 'styled-components'
import { Investor, Small, Caption, Breakpoint } from '@tumelo/shared'
import { Link } from '../../../../application/router/Link'
import { ProfileImage } from '../../../ProfileImage'
import { glowing } from '../../../../styles/animation'

interface Props {
  investor?: Investor
  isActive: boolean
  loading?: boolean
  path: string
}

export const ProfileTab: FC<Props> = ({ path, investor, isActive, loading = false }) => {
  return (
    <Link to={path}>
      <Container>
        <Profile>
          {investor ? (
            <ProfileImage email={investor.email} size={PROFILE_IMAGE_SIZE} loading={loading} />
          ) : (
            <ProfileImage size={PROFILE_IMAGE_SIZE} loading={loading} />
          )}
        </Profile>

        <Column isActive={isActive}>
          {loading ? (
            <LoadingText />
          ) : (
            <>
              {investor && (
                <Name data-test-id="profile-tab-name" data-hj-suppress>
                  {displayName(investor)}
                </Name>
              )}
              <ProfileText isActive={isActive}>Profile</ProfileText>
            </>
          )}
        </Column>
      </Container>
    </Link>
  )
}

const displayName = (investor: Investor): string => investor.nickname ?? `${investor.firstName} ${investor.lastName}`

const PROFILE_IMAGE_SIZE = 40

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Profile = styled.div`
  height: ${PROFILE_IMAGE_SIZE}px;
  width: ${PROFILE_IMAGE_SIZE}px;
`

interface ActiveProps {
  isActive: boolean
}

const Column = styled.div<ActiveProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2rem;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.info.light : theme.colors.info.medium)};
  @media (max-width: ${Breakpoint.widescreen}) {
    display: none;
  }
`

const LoadingText = styled.div`
  height: 2.5rem;
  width: 10rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light};
  animation: ${glowing} 2s infinite;
`

const Name = styled(Caption)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  @media (max-width: ${Breakpoint.desktop}) {
    font-size: 1.4rem;
  }
`

const ProfileText = styled(Small)<ActiveProps>`
  color: inherit;
  @media (max-width: ${Breakpoint.desktop}) {
    font-size: 1.2rem;
  }
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
`
