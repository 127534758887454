import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgFinancesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM14.58 14.04L11.26 14.06C11.34 13.86 11.37 13.65 11.37 13.44C11.36 13.34 11.35 13.24 11.36 13.14H12.94C13.37 13.12 13.72 12.78 13.72 12.35C13.72 11.92 13.38 11.58 12.95 11.58H11.37V11.44L11.41 10.74C11.46 10.38 11.7 10.08 12.03 9.95C12.4 9.83 12.79 9.83 13.15 9.95C13.45 10.07 13.64 10.37 13.64 10.69C13.64 11.11 13.97 11.45 14.39 11.46C14.39 11.46 14.4 11.46 14.41 11.46C14.84 11.46 15.19 11.11 15.19 10.68C15.18 9.7 14.56 8.84 13.64 8.51C12.97 8.29 12.24 8.28 11.56 8.5C10.61 8.82 9.93 9.67 9.82 10.66L9.78 11.42V11.66H9.43C9 11.66 8.66 12 8.65 12.42C8.65 12.42 8.65 12.43 8.65 12.44C8.65 12.86 8.98 13.2 9.4 13.21C9.4 13.21 9.41 13.21 9.42 13.21H9.75L9.76 13.53C9.65 13.81 9.47 14.06 9.24 14.25C8.96 14.45 8.85 14.8 8.94 15.13C8.98 15.24 9.05 15.35 9.13 15.43C9.28 15.58 9.47 15.66 9.68 15.66L14.51 15.64C14.94 15.64 15.29 15.29 15.29 14.86C15.3 14.43 14.97 14.07 14.54 14.05L14.58 14.04Z" })));
};
