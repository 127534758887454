import React, { useEffect } from 'react'
import { isValueState } from '../../application/payload'
import { selectInvestorSub } from '../../application/features/investor/investorSelector'
import { LoggerServiceSentry } from '../../application/services/Logger/LoggerServiceSentry'
import { useAppSelector } from '../../application/store'

interface Props {
  logger: LoggerServiceSentry
}

export const SentrySetUser: React.FC<React.PropsWithChildren<Props>> = ({ logger, children }) => {
  const sub = useAppSelector(selectInvestorSub)

  useEffect(() => {
    if (isValueState(sub)) {
      logger.setUser(sub ?? undefined)
    }
  }, [logger, sub])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
