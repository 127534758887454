/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import {
  Poll,
  isPollOpen,
  Card,
  CardImage,
  CardContent,
  CardSegment,
  TagIcon,
  SmallHeading,
  TypographyCaption,
} from '@tumelo/shared'
import { Link } from '../../application/router/Link'
import { useAnalytics } from '../../application/analytics'
import { VoteNow } from '../Cardlet/CardletBottoms/VoteNow'
import { buildEvent } from '../../application/analytics/eventBuilders'

export interface NewPollCardProps {
  poll: Poll
  orgName: string
  teaser: string
  image: string // base64encoded / url
}

export const NewPollCard: React.FC<NewPollCardProps> = ({ poll, orgName, teaser, image }) => {
  const { trackEvent } = useAnalytics()
  const pollPath = `/votes/open/${poll.id}`

  const onClick = () => {
    const status = isPollOpen(poll) ? 'open' : 'closed'
    trackEvent(buildEvent.openPoll(poll.id, 'New Poll', status))
  }

  return (
    <StyledLink to={pollPath} onClick={onClick} title={poll.title}>
      <StyledCard width="32rem">
        <CardImage alt={`${orgName} logo`} height={160} src={image} />
        <NewPollCardContent>
          <SmallHeading mt={0} as="h3">
            {poll.title}
          </SmallHeading>
          <TypographyCaption>{teaser}</TypographyCaption>
        </NewPollCardContent>
        <CardSegment>
          <Container>
            <TagIcon tagId={poll.tags[0].id} style={{ width: '4.8rem', height: '4.8rem' }} />
            <Container>
              <VoteNow poll={poll} showVoteCount={false} />
            </Container>
          </Container>
        </CardSegment>
      </StyledCard>
    </StyledLink>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const NewPollCardContent = styled(CardContent)`
  margin: 2rem 2rem 0;
  flex: 1;
`

const StyledCard = styled(Card)``

const StyledLink = styled(Link)`
  display: flex;
  &:hover ${CardSegment} {
    background-color: ${({ theme }) => theme.colors.background.medium};
  }
  &:hover ${StyledCard} {
    background-color: ${({ theme }) => theme.colors.background.medium};
  }
`
