import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgHealthsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#FFF8DD" }),
        React.createElement("path", { d: "M21 8H16V3C16 2.45 15.55 2 15 2H9C8.45 2 8 2.45 8 3V8H3C2.45 8 2 8.45 2 9V15C2 15.55 2.45 16 3 16H8V21C8 21.55 8.45 22 9 22H15C15.55 22 16 21.55 16 21V16H21C21.55 16 22 15.55 22 15V9C22 8.45 21.55 8 21 8ZM20 14H15C14.45 14 14 14.45 14 15V20H10V15C10 14.45 9.55 14 9 14H4V10H9C9.55 10 10 9.55 10 9V4H14V9C14 9.55 14.45 10 15 10H20V14Z", fill: "#B08B1E" })));
};
