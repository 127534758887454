import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgAlert = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#clip0_317_832)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 15.03C11.73 15.03 11.48 15.14 11.29 15.32C11.1 15.5 11 15.76 11 16.03C11 16.3 11.11 16.55 11.29 16.74C11.47 16.93 11.73 17.03 12 17.03C12.27 17.03 12.52 16.92 12.71 16.74C12.9 16.56 13 16.3 13 16.03C13 15.76 12.89 15.51 12.71 15.32C12.53 15.13 12.27 15.03 12 15.03ZM12 6.98C11.45 6.98 11 7.43 11 7.98V12.03C11 12.58 11.45 13.03 12 13.03C12.55 13.03 13 12.58 13 12.03V7.97C13 7.42 12.55 6.97 12 6.97V6.98ZM12 1.01C5.92 1 1 5.92 1 12C1 18.08 5.92 23 12 23C18.08 23 23 18.08 23 12C23 5.92 18.08 1 12 1V1.01ZM12 21.01C7.03 21.01 3 16.98 3 12.01C3 7.04 7.03 3 12 3C16.97 3 21 7.03 21 12C21 16.97 16.97 21 12 21V21.01Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_317_832" },
                React.createElement("rect", { width: 22, height: 22, fill: "white", transform: "translate(1 1)" })))));
};
