import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgLoad = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 25, viewBox: "0 0 24 25", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.48 19.0056H22C22.55 19.0056 23 19.4562 23 20.007C23 20.5577 22.55 21.0083 22 21.0083H17.91C17.36 21.0083 16.91 20.5577 16.91 20.007V15.9114C16.91 15.3607 17.36 14.9101 17.91 14.9101C18.46 14.9101 18.91 15.3607 18.91 15.9114V17.7639C20.25 16.1618 21 14.1391 21 12.0162C21 7.0495 16.96 3.00405 12 3.00405C11.45 3.00405 11 2.55344 11 2.0027C11 1.45196 11.45 1.00135 12 1.00135C18.07 1.00135 23 5.938 23 12.0162C23 14.5897 22.09 17.043 20.48 19.0056ZM3 12.0162C3 16.9829 7.04 21.0283 12 21.0283C12.55 21.0283 13 21.4789 13 22.0297C13 22.5804 12.55 23.031 12 23.031C5.93 23.031 1 18.0944 1 12.0162C1 9.44271 1.91 6.97939 3.52 5.02676H2C1.45 5.02676 1 4.57616 1 4.02541C1 3.47467 1.45 3.02406 2 3.02406H6.09C6.64 3.02406 7.09 3.47467 7.09 4.02541V8.12093C7.09 8.67167 6.64 9.12228 6.09 9.12228C5.54 9.12228 5.09 8.67167 5.09 8.12093V6.26844C3.75 7.87059 3 9.89332 3 12.0162Z" })));
};
