/* eslint-disable deprecation/deprecation */
import { Breakpoint, Caption } from '@tumelo/shared'
import React from 'react'
import styled from 'styled-components'

interface Props {
  error: string | undefined
}

/**
 * ErrorContainer is an error container that can be used and that will be scrolled to if there is an error.
 */
export const ErrorContainer: React.FC<Props> = ({ error }) => {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (error) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [error])

  return (
    <Container hasError={!!error} ref={ref}>
      <Caption>{error || 'placeholder to set empty height'} </Caption>
    </Container>
  )
}

interface ErrorProps {
  hasError: boolean
}

const Container = styled.div<ErrorProps>`
  background: ${({ theme }) => theme.colors.danger.light};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
  visibility: ${(p) => (p.hasError ? 'visible' : 'hidden')};
  border: 1px solid ${({ theme }) => theme.colors.danger.medium};
  @media (max-width: ${Breakpoint.mobile}) {
    display: ${(p) => (p.hasError ? 'block' : 'none')};
  }
`
