/* eslint-disable deprecation/deprecation */
import * as React from 'react'
import { Body, FontWeight, ToggleSwitch } from '@tumelo/shared'
import { useAppDispatch } from '../../application/store'
import { CreatePushSubscription } from '../../application/features/pushSubscriptions/asyncActions'
import { PayloadUnwrapperM } from '../Error/PayloadUnwrapper'
import { useRegistration, useSubscription, useVapidKey } from '../../application/features/pushSubscriptions/hooks'
import { setSubscription } from '../../application/features/pushSubscriptions'

export const ManagePushNotifications: React.FC = () => {
  const applicationServerKey = useVapidKey()
  const registration = useRegistration()
  const subscription = useSubscription()
  const isSubscribed = subscription !== undefined
  const caption = isSubscribed ? 'Subscribed to vote results updates.' : 'Unsubscribed to vote results updates.'

  const dispatch = useAppDispatch()

  const subscribe = async (registration: ServiceWorkerRegistration, applicationServerKey: Uint8Array) => {
    const sub = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey,
    })

    dispatch(CreatePushSubscription(sub))
  }

  const unsubscribe = async (subscription: PushSubscription) => {
    await subscription.unsubscribe()
    dispatch(setSubscription(undefined))
  }

  const onClick = async (
    applicationServerKey: Uint8Array,
    subscription: PushSubscription | undefined,
    registration: ServiceWorkerRegistration
  ) => {
    if (subscription) {
      await unsubscribe(subscription)
    } else {
      await subscribe(registration, applicationServerKey)
    }
  }

  return (
    <>
      <Body mt={0} mb={10} fontWeight={FontWeight.bold}>
        In-browser push notifications
      </Body>
      <PayloadUnwrapperM
        items={[applicationServerKey, subscription, registration]}
        customSpinner={<TogglePending />}
        customError={<ToggleError />}
      >
        {([applicationServerKey, subscription, registration]) => (
          <ToggleSwitch
            onClick={() => onClick(applicationServerKey, subscription, registration)}
            caption={caption}
            active={isSubscribed}
            toggleName="push notifications"
          />
        )}
      </PayloadUnwrapperM>
    </>
  )
}

const TogglePending: React.FC = () => (
  <ToggleSwitch
    disabled
    onClick={() => ({})}
    caption="Fetching your notification preferences"
    active
    toggleName="push notifications"
  />
)

const ToggleError: React.FC = () => (
  <ToggleSwitch
    disabled
    onClick={() => ({})}
    caption="Oh no, there has been an error. Please come back later to update this preference."
    active
    toggleName="push notifications"
  />
)
