/* eslint-disable deprecation/deprecation */
import {
  AccountId,
  Composition,
  CompositionComponents,
  dateToTimestamp,
  ModelPortfolioId,
  OrganizationBreakdown,
  TumeloAccount,
  BreakdownBasedOnInstrument,
  BreakdownBasedOnInvestorAccount,
  BreakdownBasedOnModelPortfolio,
  notEmpty,
  VotingPolicySelection,
  VotingPolicyId,
} from '@tumelo/shared'
import { APIServiceBaseClass } from '../../../utils/api'
import { ApiError } from '../../../utils/api/ApiError'
import {
  OrganizationBreakdown as OrganizationBreakdownApi,
  InvestorAccountCompositionComponents,
  ModelPortfolioCompositionComponents3,
  InvestorAccount,
  BreakdownBasedOnInstrument as BreakdownBasedOnInstrumentApi,
  BreakdownBasedOnModelPortfolio as BreakdownBasedOnModelPortfolioApi,
  BreakdownBasedOnInvestorAccount as BreakdownBasedOnInvestorAccountApi,
  VotingPolicySelection as VotingPolicySelectionApi,
} from '../../../utils/api/gen/models'
import { head } from '../../../utils/functional/arr'
import { OrganizationServiceAPI } from '../Organization/OrganizationServiceAPI'
import { AccountService } from './AccountService'

export class AccountServiceApi extends APIServiceBaseClass implements AccountService {
  async getAccountComposition({
    id,
  }: TumeloAccount): Promise<{ composition: Composition; noReferenceAndNoIsinInstrumentWeight: number }> {
    const accountApi = await this.getAccountsApi()
    const { habitatId, investorId } = this
    const { compositions } = await accountApi.listInvestorAccountCompositions({
      habitatId,
      investorId,
      accountId: id,
      pageSize: 1,
    })
    const composition = head(compositions)
    if (!composition) {
      throw new Error('composition not found')
    }
    const { components, noReferenceAndNoIsinInstrumentWeight } = mapInvestorAccountCompositionComponentsToDomain(
      composition.components
    )
    return {
      composition: {
        id: composition.id ?? '',
        createdAt: dateToTimestamp(composition.createTime ?? new Date()),
        validAt: dateToTimestamp(composition.validTime),
        components,
      },
      noReferenceAndNoIsinInstrumentWeight,
    }
  }

  async getAccountOrganizationEquityBreakdown({ id }: TumeloAccount): Promise<OrganizationBreakdown> {
    const api = await this.getAccountsApi()
    const { habitatId, investorId } = this
    try {
      const breakdown = await api.getInvestorAccountEquityBreakdown({
        habitatId,
        investorId,
        accountId: id,
      })
      return AccountServiceApi.breakdownApiToBreakdown(breakdown)
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 400) {
          return {
            organizationEntries: [],
            others: 1,
            breakdownBasedOn: {},
          }
        }
      }
      throw e
    }
  }

  async createAccountWithPortfolioId(modelPortfolioId: string): Promise<TumeloAccount> {
    const [accountsApi, grpcClient] = await Promise.all([this.getAccountsApi(), this.getDashboardBffService()])
    const { habitatId, investorId } = this

    // TODO: move this call to the backend
    await accountsApi.createInvestorAccount({
      habitatId,
      investorId,
      investorAccount: { modelPortfolioId },
    })

    const { latestInvestorAccountComposition, investorAccount } = await grpcClient.CreateCompositionWithModelPortfolio({
      modelPortfolioId,
    })

    if (!latestInvestorAccountComposition) {
      throw new Error('Latest Investor Account Composition is undefined')
    }
    if (!investorAccount) {
      throw new Error('Investor Account is undefined')
    }

    return {
      id: investorAccount.id as AccountId,
      modelPortfolioId: investorAccount.modelPortfolioId as ModelPortfolioId,
    }
  }

  async getAccount(): Promise<TumeloAccount | undefined> {
    const { investorId, habitatId } = this
    const api = await this.getAccountsApi()
    const accounts = await api.listInvestorAccounts({ investorId, habitatId })
    const mainAccount = head(accounts.accounts)
    if (mainAccount) {
      return mapAccount(mainAccount)
    }
    return undefined
  }

  async deleteVotingPolicySelection({ id: accountId }: TumeloAccount, votingPolicySelectionId: string): Promise<void> {
    const { investorId, habitatId } = this
    const api = await this.getAccountsApi()
    await api.deleteVotingPolicySelection({
      habitatId,
      investorId,
      accountId,
      votingPolicySelectionId,
    })
  }

  async createVotingPolicySelection(
    { id: accountId }: TumeloAccount,
    votingPolicyId: string,
    existingVotingPolicyId?: string
  ): Promise<VotingPolicySelection | undefined> {
    const { investorId, habitatId } = this
    const api = await this.getAccountsApi()
    if (existingVotingPolicyId !== undefined) {
      await this.deleteVotingPolicySelection({ id: accountId }, existingVotingPolicyId).catch((error) => {
        return error
      })
    }
    const votingPolicySelection = api
      .createVotingPolicySelection({
        habitatId,
        investorId,
        accountId,
        votingPolicySelection: {
          id: '',
          votingPolicyId,
          createTime: new Date(),
          updateTime: new Date(),
          automaticVoting: true,
        },
      })
      .then((resp: VotingPolicySelectionApi) => {
        if (resp) {
          return mapVotingPolicySelectionToDomain(resp)
        }
        throw new Error('VotingPolicySelection not set')
      })
      .catch((error) => {
        return error
      })

    return votingPolicySelection
  }

  async getVotingPolicySelection({ id: accountId }: TumeloAccount): Promise<VotingPolicySelection | undefined> {
    const { investorId, habitatId } = this
    const api = await this.getAccountsApi()
    const { votingPolicySelections } = await api.listVotingPolicySelections({ investorId, habitatId, accountId })
    const firstSelection = head(votingPolicySelections)

    if (firstSelection === undefined) {
      return undefined
    }

    return mapVotingPolicySelectionToDomain(firstSelection)
  }

  static breakdownApiToBreakdown({ components, basedOn }: OrganizationBreakdownApi): OrganizationBreakdown {
    const organizationEntries = components.organizations
      ?.map(({ weight, organization }) => ({
        organization: OrganizationServiceAPI.organizationApiToOrganization(organization),
        percentageWeight: weight * 100,
      }))
      .sort((a, b) => b.percentageWeight - a.percentageWeight)
    return {
      organizationEntries: organizationEntries === undefined ? [] : organizationEntries,
      others: components.others,
      cash: components.cash,
      breakdownBasedOn: {
        instruments: basedOn.instruments?.map(mapInstrument),
        modelPortfolios: basedOn.modelPortfolios?.map(mapModelPortfolio),
        investorAccounts: basedOn.investorAccounts?.map(mapInvestorAccount),
      },
    }
  }
}

export const mapInvestorAccountCompositionComponentsToDomain = (
  c?: InvestorAccountCompositionComponents
): { components: CompositionComponents; noReferenceAndNoIsinInstrumentWeight: number } => {
  let noReferenceAndNoIsinInstrumentWeight = 0
  const instruments =
    c?.instruments
      ?.map(({ instrumentReference, isin, weight }) => {
        if (instrumentReference) {
          return {
            instrumentReference,
            isin: isin || instrumentReference.idType === 'isin' ? instrumentReference.idValue : undefined,
            weight,
          }
        }
        if (isin) {
          return {
            instrumentReference: instrumentReference || {
              idType: 'isin',
              idValue: isin,
            },
            isin,
            weight,
          }
        }
        noReferenceAndNoIsinInstrumentWeight += weight
        return undefined
      })
      .filter(notEmpty) || []
  return {
    components: {
      instruments,
      cash: c?.cash || [],
      others: (c?.others || 0) + noReferenceAndNoIsinInstrumentWeight,
    },
    noReferenceAndNoIsinInstrumentWeight,
  }
}

export const mapModelPortfolioCompositionComponentsToDomain = (
  c?: ModelPortfolioCompositionComponents3
): CompositionComponents => ({
  instruments:
    c?.instruments?.map(({ weight, instrument }) => {
      return {
        instrumentReference: instrument,
        weight,
      }
    }) || [],
  cash: c?.cash || [],
  others: c?.others || 0,
})

const mapAccount = (account: InvestorAccount): TumeloAccount => {
  return {
    id: account.id as AccountId,
    title: account.title,
    modelPortfolioId: account.modelPortfolioId as ModelPortfolioId,
  }
}

const mapInstrument = (i: BreakdownBasedOnInstrumentApi): BreakdownBasedOnInstrument => {
  return {
    isin: i.instrument.isin,
    validAt: dateToTimestamp(i.validAt),
  }
}

const mapModelPortfolio = (mp: BreakdownBasedOnModelPortfolioApi): BreakdownBasedOnModelPortfolio => {
  return {
    id: mp.modelPortfolio.id,
    validAt: dateToTimestamp(mp.validAt),
  }
}

const mapInvestorAccount = (ia: BreakdownBasedOnInvestorAccountApi): BreakdownBasedOnInvestorAccount => {
  return {
    id: ia.account.id,
    validAt: dateToTimestamp(ia.validAt),
  }
}

const mapVotingPolicySelectionToDomain = ({
  createTime,
  updateTime,
  votingPolicyId,
  ...rest
}: VotingPolicySelectionApi): VotingPolicySelection => ({
  ...rest,
  votingPolicyId: votingPolicyId as VotingPolicyId,
  createTime: dateToTimestamp(createTime),
  updateTime: dateToTimestamp(updateTime),
})
