/* eslint-disable deprecation/deprecation */
import * as React from 'react'
import styled from 'styled-components'
import { Caption, OrganizationLogoWithFallback } from '@tumelo/shared'

interface Props {
  logoUrl?: string
  title: string
}

export const OrganizationGroup: React.FC<Props> = ({ logoUrl, title }) => {
  return (
    <Container>
      <OrganizationLogoWithFallback src={logoUrl} alt={title} style={IMAGE_STYLE} />
      <Caption weight="bold">{TruncateOrgTitle(title)}</Caption>
    </Container>
  )
}

const TruncateOrgTitle = (title: string): string => {
  if (!title || title.length < 38) {
    return title
  }

  return `${title.substring(0, 35)}...`
}

const IMAGE_STYLE: React.CSSProperties = {
  width: '20px',
  height: '20px',
  borderRadius: '20%',
  objectFit: 'contain',
  margin: '0px 5px 0 0',
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.8rem;
`
