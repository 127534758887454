/* eslint-disable deprecation/deprecation */
import styled from 'styled-components';
import { Breakpoint } from '../../styles';
export const Box = styled.div `
  border-radius: 2rem;
  padding: 2rem;
  // 66 at end is hex code alpha transparency (40%)
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : `${props.theme.colors.light}66`)};
  border: ${(props) => (props.border ? `0.1rem solid ${props.theme.colors.grey.light}` : 'none')};
  @media (min-width: ${Breakpoint.tablet}) {
    padding: 3rem;
  }
`;
