import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgMenu = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M3 6.5H17.78C18.0452 6.5 18.2996 6.39464 18.4871 6.20711C18.6746 6.01957 18.78 5.76522 18.78 5.5C18.78 5.23478 18.6746 4.98043 18.4871 4.79289C18.2996 4.60536 18.0452 4.5 17.78 4.5H3C2.73478 4.5 2.48043 4.60536 2.29289 4.79289C2.10536 4.98043 2 5.23478 2 5.5C2 5.76522 2.10536 6.01957 2.29289 6.20711C2.48043 6.39464 2.73478 6.5 3 6.5Z" }),
        React.createElement("path", { d: "M21 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z" }),
        React.createElement("path", { d: "M17.78 17.5H3C2.73478 17.5 2.48043 17.6054 2.29289 17.7929C2.10536 17.9804 2 18.2348 2 18.5C2 18.7652 2.10536 19.0196 2.29289 19.2071C2.48043 19.3946 2.73478 19.5 3 19.5H17.78C18.0452 19.5 18.2996 19.3946 18.4871 19.2071C18.6746 19.0196 18.78 18.7652 18.78 18.5C18.78 18.2348 18.6746 17.9804 18.4871 17.7929C18.2996 17.6054 18.0452 17.5 17.78 17.5Z" })));
};
