import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgOnepersonsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E9EEE8" }),
        React.createElement("path", { d: "M16.95 15.05C15.63 13.73 13.87 13 12 13C10.13 13 8.37 13.73 7.05 15.05C5.73 16.37 5 18.13 5 20V21C5 21.55 5.45 22 6 22H18C18.55 22 19 21.55 19 21V20C19 18.13 18.27 16.37 16.95 15.05ZM7 20C7 18.66 7.52 17.41 8.46 16.46C9.4 15.51 10.66 15 12 15C13.34 15 14.59 15.52 15.54 16.46C16.49 17.4 17 18.66 17 20H7ZM12 12C13.34 12 14.59 11.48 15.54 10.54C16.48 9.59 17 8.34 17 7C17 5.66 16.48 4.41 15.54 3.46C14.6 2.52 13.34 2 12 2C10.66 2 9.41 2.52 8.46 3.46C7.52 4.41 7 5.66 7 7C7 8.34 7.52 9.59 8.46 10.54C9.4 11.48 10.66 12 12 12ZM9.88 4.88C10.44 4.32 11.21 4 12 4C12.79 4 13.56 4.32 14.12 4.88C14.69 5.45 15 6.2 15 7C15 7.8 14.69 8.55 14.12 9.12C13 10.24 11 10.24 9.88 9.12C9.31 8.55 9 7.8 9 7C9 6.2 9.31 5.45 9.88 4.88Z", fill: "#2E5E22" })));
};
