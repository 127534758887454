/* eslint-disable deprecation/deprecation */
/* eslint-disable react/jsx-key */
import React from 'react'
import styled from 'styled-components'
import { Heading, MiniGridCard } from '@tumelo/shared'
import { useAppSelector } from '../../application/store'
import { selectSoftConfig } from '../../application/features/config/selectors'
import { matchCardExhaustive } from '../../config/SoftConfigSettingsReturningCards'
import { compose } from '../../utils/functional/common'
import { CustomContentCardConnected } from './CustomContentCardConnected'
import { YourColleaguesVotedCardContainer } from './YourColleaguesVotedCardContainer'
import { YourVotingInterestsCardContainer } from './YourVotingInterestsCardContainer'
import { SeeWhereYourVoteGoesCard } from './SeeWhereYourVoteGoesCard'

const TestCard: React.FC = () => <MiniGridCard title="Test card">Test card body</MiniGridCard>

export const ReturningCardsContainer: React.FC = () => {
  const cards = useAppSelector(compose(selectSoftConfig, (c) => c.settings.returningCards.cards))

  if (cards.length === 0) {
    return null
  }

  const cardsWithContent = cards.map(
    matchCardExhaustive({
      testCard: () => <TestCard />,
      customContentCard: ({ title, body, backgroundColor, callToAction }) => (
        <CustomContentCardConnected
          key="custom-card-content"
          title={title}
          body={body}
          backgroundColor={backgroundColor}
          callToAction={callToAction}
        />
      ),
      yourVotingInterestsCard: ({ minVotesThreshold }) => (
        <YourVotingInterestsCardContainer key="voting-interests-card-content" minVotesThreshold={minVotesThreshold} />
      ),
      seeWhereYourVoteGoesCard: () => <SeeWhereYourVoteGoesCard key="see-where-your-vote-goes-card-content" />,
      yourColleaguesVotedCard: () => <YourColleaguesVotedCardContainer key="your-colleagues-voted-card-content" />,
      // TODO: handle this properly
      // removed as we can't return poll results from API
      // either, remove configuration if we do not want to support this
      // or, reinstate using new API implementation
      recentMostPopularVoteCard: () => null,
    })
  )

  return (
    <>
      <CardSectionHeader>
        <Heading mb={0} mt="2.4rem">
          Keep updated with:
        </Heading>
      </CardSectionHeader>
      {cardsWithContent}
    </>
  )
}

const CardSectionHeader = styled.div`
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
