import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgInstagram = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 20, height: 20, viewBox: "0 0 20 20", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M5.88 0.06C5.05 0.08 4.23 0.23 3.45 0.52C2.11 1.04 1.05 2.1 0.53 3.44C0.23 4.22 0.08 5.04 0.06 5.86C0.01 6.92 0 7.26 0 9.97C0 12.68 0.01 13.02 0.06 14.08C0.08 14.91 0.23 15.73 0.52 16.5C1.04 17.84 2.1 18.9 3.45 19.42C4.23 19.71 5.05 19.87 5.88 19.88C6.95 19.93 7.29 19.94 10 19.94C12.71 19.94 13.06 19.93 14.12 19.88C14.95 19.86 15.77 19.71 16.55 19.42C17.89 18.9 18.96 17.84 19.48 16.5C19.77 15.73 19.93 14.91 19.94 14.08C19.99 13.01 20 12.68 20 9.97C20 7.26 19.99 6.92 19.94 5.86C19.92 5.03 19.77 4.21 19.48 3.44C18.96 2.1 17.9 1.04 16.55 0.52C15.77 0.23 14.95 0.07 14.12 0.06C13.05 0.01 12.71 0 10 0C7.29 0 6.94 0.01 5.87 0.06H5.88ZM5.95 18.1C5.32 18.1 4.69 17.98 4.09 17.76C3.66 17.6 3.26 17.34 2.94 17.01C2.61 16.69 2.35 16.3 2.19 15.86C1.97 15.27 1.85 14.64 1.85 14.01C1.8 12.96 1.79 12.64 1.79 9.98C1.79 7.32 1.8 7 1.85 5.95C1.85 5.32 1.97 4.69 2.19 4.1C2.53 3.23 3.22 2.54 4.09 2.21C4.69 1.99 5.31 1.88 5.95 1.87C7 1.82 7.32 1.81 9.99 1.81C12.66 1.81 12.98 1.82 14.03 1.87C14.66 1.87 15.29 1.99 15.89 2.21C16.76 2.54 17.45 3.23 17.79 4.1C18.01 4.69 18.13 5.32 18.13 5.95C18.18 7 18.19 7.32 18.19 9.98C18.19 12.64 18.19 12.96 18.13 14.01C18.13 14.64 18.01 15.27 17.79 15.86C17.45 16.73 16.76 17.42 15.89 17.75C15.29 17.97 14.67 18.08 14.03 18.09C12.98 18.14 12.66 18.15 9.99 18.15C7.32 18.15 7 18.14 5.95 18.09V18.1ZM14.14 4.65C14.14 5.31 14.67 5.85 15.34 5.85C16 5.85 16.54 5.32 16.54 4.65C16.54 3.99 16.01 3.45 15.34 3.45C14.68 3.45 14.14 3.99 14.14 4.65ZM4.87 9.98C4.87 12.82 7.16 15.12 9.99 15.13C12.82 15.14 15.13 12.84 15.14 10.01C15.14 7.17 12.85 4.87 10.02 4.86H10.01C7.18 4.86 4.88 7.15 4.87 9.98ZM6.67 9.98C6.67 8.14 8.15 6.64 10 6.64C11.84 6.64 13.34 8.12 13.34 9.97C13.34 11.81 11.86 13.31 10.01 13.31C8.17 13.31 6.68 11.82 6.68 9.98" })));
};
