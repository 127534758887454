import React from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import { CookieBanner as CookieBannerUI, CookieConsentValue, cookieConsent } from '@tumelo/shared'
import { ExternalLinkWithTracking } from '../../buildingBlocks/ExternalLinkWithTracking'
import { cookiesPolicyUrl } from '../../config/ExternalLinks'

const WarningText = () => (
  <>
    We use cookies to enhance your Tumelo experience. They help us remember you when you come back and give valuable
    feedback on how we can do better (plus, cookies!). Find out more about how we use cookies{' '}
    <StyledLink to={cookiesPolicyUrl}>here.</StyledLink>
  </>
)

export const CookieBanner: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [cookies, setCookie] = useCookies([cookieConsent])

  if (cookies[cookieConsent]) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <>
      {children}
      <CookieBannerUI
        warningText={<WarningText />}
        onClickAccept={() => {
          setCookie(cookieConsent, CookieConsentValue.Accepted)
        }}
        onClickReject={() => {
          setCookie(cookieConsent, CookieConsentValue.Rejected)
        }}
      />
    </>
  )
}

const StyledLink = styled(ExternalLinkWithTracking)`
  color: inherit;
  text-decoration: underline;
`
