/* eslint-disable no-restricted-imports */
import { useMemo } from 'react'
import { createSearchParams, useLocation, useNavigate, NavigateOptions } from 'react-router-dom'

type To = { pathname: string; query?: Record<string, string | string[]> } | string

// Hook
export function useRouter() {
  const location = useLocation()
  const navigate = useNavigate()
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      navigate: (to: To, options?: NavigateOptions) => {
        if (typeof to !== 'string' && to.query) {
          const search = createSearchParams(to.query)
          return navigate({ pathname: to.pathname, search: `?${search}` }, options)
        }
        return navigate(to, options)
      },
      back: () => navigate(-1),
      replace: (pathname: string) => navigate(pathname, { replace: true }),
      pathname: location.pathname,
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.

      location,
    }
  }, [navigate, location])
}
