import React from 'react';
import styled from 'styled-components';
import MarkdownIT from 'markdown-it';
import DOMPurify from 'isomorphic-dompurify';
export const Markdown = ({ markdown, internalLinkHandler, style, externalLinks = true, options, className, }) => {
    const sanitizedHTML = ((markdown, options) => {
        const renderer = new MarkdownIT('commonmark', options);
        if (externalLinks) {
            DOMPurify.addHook('afterSanitizeAttributes', (node) => {
                // set all elements owning target to target=_blank
                if ('target' in node) {
                    node.setAttribute('target', '_blank');
                    node.setAttribute('rel', 'noopener noreferrer');
                }
            });
        }
        const dirtyHtml = renderer.render(markdown);
        return DOMPurify.sanitize(dirtyHtml);
    })(markdown, options);
    return (React.createElement(Container, { className: className },
        React.createElement(RenderHtml, { internalLinks: !externalLinks, internalLinkHandler: internalLinkHandler, sanitizedHTML: sanitizedHTML, style: style })));
};
const RenderHtml = ({ internalLinks, internalLinkHandler, style, sanitizedHTML }) => {
    if (internalLinks)
        return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        React.createElement("span", { className: "markdown", onClick: internalLinkHandler, style: style, 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: sanitizedHTML } }));
    return (React.createElement("span", { className: "markdown", style: style, 
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML: { __html: sanitizedHTML } }));
};
const Container = styled.div `
  white-space: normal;

  > * > * {
    margin: 0;
    padding: 0;
  }

  > * > ul {
    margin-left: 2rem;
    list-style: disc;
    display: flex;
    flex-direction: column;
  }

  > * {
    color: ${({ theme }) => theme.colors.dark};
  }

  strong {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-end: 1.6rem;
  }

  p,
  ul {
    margin-block-end: 1.6rem;
    line-height: 3.2rem;
  }

  * > li {
    line-height: 3.2rem;
    margin-block-end: 0.5rem;
  }

  * > li > * {
    margin-block-end: 0rem;
  }

  * > a {
    color: ${({ theme }) => theme.colors.secondary.medium};
    &:hover {
      text-decoration: underline;
    }
  }
`;
