/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import styled from 'styled-components'
import { Field, FieldProps } from '@tumelo/shared'
import { EyeIcon } from '@tumelo/designsystem'

interface Props extends FieldProps {
  autoComplete: 'new-password' | 'current-password'
}

export const PasswordField: React.FC<Props> = ({ autoComplete, ...rest }) => {
  const [show, setShow] = React.useState(false)

  return (
    <Container>
      <Field autoComplete={autoComplete} {...rest} type={show ? 'text' : 'password'} warningIconRightIndentRem={4.5} />
      <ShowButton aria-hidden role="button" onClick={() => setShow(!show)}>
        <EyeIcon />
      </ShowButton>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const ShowButton = styled.div`
  position: absolute;
  right: 0;
  top: 1.45rem;
  margin-right: 1.5rem;
  cursor: pointer;
  padding: 1rem;
  stroke: ${({ theme }) => theme.colors.primary.medium};
  fill: ${({ theme }) => theme.colors.primary.medium};
`
