import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgUtilitiessmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M18.08 7.49001L12.71 2.12001C12.32 1.73001 11.69 1.73001 11.3 2.12001L5.91997 7.49001C2.56997 10.84 2.56997 16.3 5.91997 19.65C7.59997 21.33 9.79997 22.16 12 22.16C14.2 22.16 16.4 21.32 18.08 19.65C21.43 16.3 21.43 10.84 18.08 7.49001ZM16.67 18.24C14.1 20.81 9.90997 20.81 7.33997 18.24C4.76997 15.67 4.76997 11.48 7.33997 8.91001L12.01 4.24001L16.68 8.91001C19.25 11.48 19.25 15.67 16.68 18.24H16.67Z" })));
};
