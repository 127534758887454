import { LoggerService } from './LoggerService'

export class LoggerServiceCompound implements LoggerService {
  private readonly loggers: LoggerService[]

  constructor(...loggers: LoggerService[]) {
    this.loggers = loggers
  }

  logError(e: any): void {
    this.loggers.map((l) => l.logError(e))
  }
}
