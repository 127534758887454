import {
  AccountId,
  Composition,
  dateToTimestamp,
  InstrumentReferenceString,
  ModelPortfolioId,
  TumeloAccount,
  VotingPolicy,
} from '@tumelo/shared'
import { grpc } from '@improbable-eng/grpc-web'
import { UserPreferences } from '../../types/UserPreferences/UserPreferences'
import { GetWebPushVAPIDPublicKeyResponse } from '../../../utils/proto/tumelo/dashboardbff/v1/service'
import { Composition as ProtoComposition } from '../../../utils/proto/tumelo/dashboardbff/v1/composition'
import {
  Notification,
  NotificationResolution,
  NotificationType,
} from '../../../utils/proto/tumelo/platform/v1/notification'
import { InvestorAccount } from '../../../utils/proto/tumelo/dashboardbff/v1/investor_account'
import { PushSubscription as PushSubscriptionProto } from '../../../utils/proto/tumelo/platform/v1/push_subscription'
import { isGRPCError } from '../../../utils/grpc/GrpcErrorCodes'
import { APIServiceBaseClass } from '../../../utils/api'
import {
  AppNotification,
  AppNotificationSource,
  AppNotificationType,
  ResolutionType,
} from '../../types/AppNotification/AppNotification'
import { VoteResult } from '../../../utils/proto/tumelo/dashboardbff/v1/vote_result'
import { DashboardBffService } from './DashboardBffService'
import { mapSubscribedToPushNotificationsToPB, mapUserPreferencesToDomain, mapVotingPolicyToDomain } from './map'

export class DashboardBffServiceGRPC extends APIServiceBaseClass implements DashboardBffService {
  async GetUserPreferences(): Promise<UserPreferences> {
    const grpcClient = await this.getDashboardBffService()
    const userPreferences = await grpcClient.GetIfNotSetDefaultUserPreferences({})
    return mapUserPreferencesToDomain(userPreferences)
  }

  async SetSubscribedToPushNotification(isSubscribed: boolean): Promise<UserPreferences> {
    const grpcClient = await this.getDashboardBffService()
    const up = await grpcClient.GetUserPreferences({})
    const subscribedToPushNotificationsState = mapSubscribedToPushNotificationsToPB(isSubscribed)
    const userPreferences = await grpcClient.UpsertUserPreferences({ ...up, subscribedToPushNotificationsState })
    return mapUserPreferencesToDomain(userPreferences)
  }

  async SetSubscribedToEmails(subscribedEmail: boolean): Promise<UserPreferences> {
    const grpcClient = await this.getDashboardBffService()
    const up = await grpcClient.GetUserPreferences({})
    const userPreferences = await grpcClient.UpsertUserPreferences({ ...up, subscribedEmail })
    return mapUserPreferencesToDomain(userPreferences)
  }

  async GetWebPushVAPIDPublicKey(): Promise<GetWebPushVAPIDPublicKeyResponse> {
    const grpcClient = await this.getDashboardBffService()
    return grpcClient.GetWebPushVAPIDPublicKey({})
  }

  async CreatePushSubscription(sub: PushSubscription): Promise<PushSubscriptionProto> {
    const grpcClient = await this.getDashboardBffService()
    return grpcClient.CreatePushSubscription(sub)
  }

  async ListNotifications(pageToken: string): Promise<{ notifications: AppNotification[]; nextPageToken: string }> {
    const grpcClient = await this.getDashboardBffService()
    const { notifications, nextPageToken } = await grpcClient.ListNotifications({ pageSize: 10, pageToken })

    return {
      notifications: notifications
        .map((n) => mapNotificationToDomain(n, 'platform-internal'))
        .filter(isAppNotification),
      nextPageToken,
    }
  }

  async UpdateNotificationResolution(id: string, resolution: ResolutionType): Promise<ResolutionType> {
    const grpcClient = await this.getDashboardBffService()
    const notification = await grpcClient.UpdateNotificationResolution({
      id,
      resolution: mapResolutionTypeToNotificationResolution(resolution),
    })

    const returnedResolution = notification.resolution

    if (returnedResolution === undefined) {
      return 'unresolved'
    }

    return mapNotificationResolutionToResolutionType(returnedResolution)
  }

  async FetchOrganizationBreakdown(
    instrumentReferences: InstrumentReferenceString[]
  ): Promise<{ organizationIds: string[] }> {
    const api = await this.getDashboardBffService()
    const { organizationIds } = await api.FetchOrganizationBreakdown({ instrumentReferences })
    return { organizationIds }
  }

  async GetTermsAndConditionsConsent(): Promise<{ accepted: boolean }> {
    const api = await this.getDashboardBffService()

    let accepted = false
    try {
      const consent = await api.GetTermsAndConditionsConsent({})
      accepted = consent.accepted
    } catch (err) {
      if (isGRPCError(err)) {
        if (err.code !== grpc.Code.NotFound) {
          throw err
        }
      }
    }

    return { accepted }
  }

  async UpdateTermsAndConditionsConsent(accepted: boolean): Promise<{ accepted: boolean }> {
    const api = await this.getDashboardBffService()

    const resp = await api.UpdateTermsAndConditionsConsent({ accepted })
    return { accepted: resp.accepted }
  }

  // Pagination not implemented in service layer as current designs don't account for many voting policies
  // this simplifies the method
  async FetchHabitatVotingPolicies(): Promise<VotingPolicy[]> {
    const api = await this.getDashboardBffService()
    const { votingPolicies } = await api.FetchVotingPolicies({})
    return votingPolicies.map((policy) => mapVotingPolicyToDomain(policy))
  }

  async CreateCompositionWithModelPortfolio(
    modelPortfolioId: string
  ): Promise<{ account: TumeloAccount; composition: Composition }> {
    const grpcClient = await this.getDashboardBffService()

    const { latestInvestorAccountComposition, investorAccount } = await grpcClient.CreateCompositionWithModelPortfolio({
      modelPortfolioId,
    })

    if (!latestInvestorAccountComposition) {
      throw new Error('Latest Investor Account Composition is undefined')
    }
    if (!investorAccount) {
      throw new Error('Investor Account is undefined')
    }

    return {
      account: mapInvestorAccountProtoToDomain(investorAccount),
      composition: mapInvestorAccountCompositionProtoToDomain(latestInvestorAccountComposition),
    }
  }

  async FetchVoteResults(nextPageToken: string): Promise<{ voteResults: VoteResult[]; nextPageToken: string }> {
    const grpcClient = await this.getDashboardBffService()
    const { voteResults, nextPageToken: nextToken } = await grpcClient.FetchInvestorVoteResults({
      pageToken: nextPageToken,
    })
    return { voteResults, nextPageToken: nextToken }
  }
}

const mapInvestorAccountCompositionProtoToDomain = ({
  createTime,
  validTime,
  id,
  components,
}: ProtoComposition): Composition => {
  if (!createTime) {
    throw new Error('createTime is not set in InvestorAccountComposition')
  }
  if (!validTime) {
    throw new Error('validTime is not set in InvestorAccountComposition')
  }
  if (!components) {
    throw new Error('components is not defined in InvestorAccountComposition')
  }
  const createdAt = dateToTimestamp(createTime)
  const validAt = dateToTimestamp(validTime)

  return {
    id,
    createdAt,
    validAt,
    components: {
      instruments: components.instruments.map((i) => {
        if (!i.instrumentReference) throw new Error(`no instrument reference set for InvestorAccountComposition ${id}`)
        return {
          instrumentReference: i.instrumentReference,
          weight: i.weight,
        }
      }),
      cash: components.cash,
      others: components.other,
    },
  }
}

const mapInvestorAccountProtoToDomain = ({ id, title, modelPortfolioId }: InvestorAccount): TumeloAccount => {
  return {
    id: id as AccountId,
    title,
    modelPortfolioId: modelPortfolioId as ModelPortfolioId,
  }
}

const isAppNotification = (n: AppNotification | undefined): n is AppNotification => {
  return !!n
}

const mapNotificationToDomain = (
  notification: Notification,
  source: AppNotificationSource
): AppNotification | undefined => {
  const uuid = notification.name.split('/').pop()

  if (uuid === undefined) {
    return undefined
  }

  if (notification.createTime === undefined) {
    return undefined
  }

  return {
    title: notification.title,
    link: notification.url,
    logo: notification.logoUrl,
    id: uuid,
    createTime: dateToTimestamp(notification.createTime),
    type: mapNotificationTypeToAppNotificationType(notification.type),
    resolution: mapNotificationResolutionToResolutionType(notification.resolution),
    source,
  }
}

const mapResolutionTypeToNotificationResolution = (resolution: ResolutionType): NotificationResolution => {
  switch (resolution) {
    case 'dismissed': {
      return NotificationResolution.RESOLUTION_DISMISSED
    }
    case 'opened': {
      return NotificationResolution.RESOLUTION_OPENED
    }
    case 'unrecognized': {
      return NotificationResolution.UNRECOGNIZED
    }
    default: {
      return NotificationResolution.RESOLUTION_UNRESOLVED
    }
  }
}

const mapNotificationResolutionToResolutionType = (resolution: NotificationResolution): ResolutionType => {
  switch (resolution) {
    case NotificationResolution.RESOLUTION_DISMISSED: {
      return 'dismissed'
    }
    case NotificationResolution.RESOLUTION_OPENED: {
      return 'opened'
    }
    case NotificationResolution.RESOLUTION_UNRESOLVED: {
      return 'unresolved'
    }
    default: {
      return 'unrecognized'
    }
  }
}

const mapNotificationTypeToAppNotificationType = (type: NotificationType): AppNotificationType => {
  switch (type) {
    case NotificationType.TYPE_OPEN_VOTE: {
      return 'openVote'
    }
    case NotificationType.TYPE_VOTE_RESULT: {
      return 'voteResult'
    }
    case NotificationType.TYPE_CASE_STUDIES: {
      return 'caseStudies'
    }
    case NotificationType.TYPE_IMPACT: {
      return 'impact'
    }
    case NotificationType.TYPE_NEWS: {
      return 'news'
    }
    case NotificationType.TYPE_NEW_FEATURE: {
      return 'newFeatures'
    }
    case NotificationType.TYPE_USER_RESEARCH: {
      return 'userResearch'
    }
    default: {
      return 'openVotes'
    }
  }
}
