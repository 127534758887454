/* eslint-disable deprecation/deprecation */
import React from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { Breakpoint, LinkButton, TypographyCaption, SubmitButton, Field } from '@tumelo/shared'
import { PasswordField } from '../../Form/PasswordField'
import { AuthSplitContainer } from '../Components/AuthSplitContainer'
import { ErrorContainer } from '../Components/ErrorContainer'
import { AuthBottomButton } from '../Components/AuthBottomButton'
import { AuthHeader } from '../Components/AuthHeader'

interface Props {
  submit: (details: SignInFormValues) => Promise<void>
  clickSignUp: (email: string, password: string) => void
  clickResetPassword: (email: string) => void
  error: string | undefined
  pending: boolean
}

const SignInSchema = yup.object({
  email: yup.string().required('Email is required').email('Invalid email'),
  password: yup.string().required('Password is required'),
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignInFormValues extends yup.Asserts<typeof SignInSchema> {}

export const SignInForm: React.FC<Props> = ({ submit, clickSignUp, clickResetPassword, error, pending }) => {
  const initialValues: SignInFormValues = {
    email: '',
    password: '',
  }
  return (
    <AuthSplitContainer>
      <ErrorContainer error={error} />
      <AuthHeader heading="Your shares, your say." subHeading="Create your account and get your voice heard." />

      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await submit(values)
        }}
        validationSchema={SignInSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values }) => (
          <Form>
            <Field name="email" type="email" label="Email address" required showValidationErrorMessages />
            <PasswordField
              name="password"
              autoComplete="current-password"
              type="password"
              label="Password"
              required
              showValidationErrorMessages
            />
            <TypographyCaption>
              Forgotten your password?&nbsp;
              <LinkButton type="button" onClick={() => clickResetPassword(values.email)}>
                Reset Password.
              </LinkButton>
            </TypographyCaption>
            <BottomContainer>
              <SignInButtonContainer>
                <SubmitButton disabled={pending} label="Sign In" />
              </SignInButtonContainer>
              <AuthBottomButton
                onClick={() => clickSignUp(values.email, values.password)}
                firstPart={"I'm new here, "}
                secondPart="Create my account."
              />
            </BottomContainer>
          </Form>
        )}
      </Formik>
    </AuthSplitContainer>
  )
}

const SignInButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  @media (max-width: ${Breakpoint.mobile}) {
    flex-direction: column;
  }
`

const BottomContainer = styled.div`
  margin-top: 4rem;
`
