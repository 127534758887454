import React, { useContext } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import styled, { ThemeContext } from 'styled-components'
import { SubmitButton, Field } from '@tumelo/shared'
import { Body1, List } from '@tumelo/designsystem'
import { PasswordField } from '../../Form/PasswordField'
import { ErrorContainer } from '../Components/ErrorContainer'
import { AuthSplitContainer } from '../Components/AuthSplitContainer'
import { AuthBottomButton } from '../Components/AuthBottomButton'
import { AuthHeader } from '../Components/AuthHeader'

interface Props {
  sendCode: (details: ForgotPasswordFormValues) => Promise<void>
  submit: (details: ResetPasswordFormValues) => Promise<void>
  goToSignIn: () => void
  email: string
  submitView: boolean
  error: string | undefined
  pending: boolean
}

export const ForgotPasswordForm: React.FC<Props> = ({
  sendCode,
  submit,
  goToSignIn,
  email,
  submitView,
  error,
  pending,
}) => {
  return submitView ? (
    <ResetPasswordFields
      email={email}
      submit={submit}
      goToSignIn={goToSignIn}
      resendCode={async (e) => sendCode({ email: e })}
      error={error}
      pending={pending}
    />
  ) : (
    <ForgotPasswordFields email={email} sendCode={sendCode} goToSignIn={goToSignIn} error={error} pending={pending} />
  )
}

interface ForgotPasswordProps {
  sendCode: (details: ForgotPasswordFormValues) => void
  goToSignIn: () => void
  email: string
  error: string | undefined
  pending: boolean
}

const ForgotPasswordSchema = yup.object({
  email: yup.string().email('Invalid email').required('Required'),
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ForgotPasswordFormValues extends yup.Asserts<typeof ForgotPasswordSchema> {}

const ForgotPasswordFields: React.FC<ForgotPasswordProps> = ({ sendCode, goToSignIn, email, error, pending }) => {
  const initialValues: ForgotPasswordFormValues = { email }
  return (
    <AuthSplitContainer>
      <ErrorContainer error={error} />
      <AuthHeader
        heading="Reset Password"
        subHeading="Please provide your email address, so we can send you a code to reset your password."
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => sendCode(values)}
        validationSchema={ForgotPasswordSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form>
          <Field name="email" type="email" label="Email address" required showValidationErrorMessages />
          <BottomContainer>
            <SubmitButtonNoWrap disabled={pending} label="Send me my code" />
            <AuthBottomButton onClick={goToSignIn} firstPart="Go to " secondPart="Sign in" />
          </BottomContainer>
        </Form>
      </Formik>
    </AuthSplitContainer>
  )
}

interface ResetPasswordProps {
  submit: (details: ResetPasswordFormValues) => void
  goToSignIn: () => void
  resendCode: (email: string) => Promise<void>
  email: string
  error: string | undefined
  pending: boolean
}

const ResetPasswordSchema = yup.object({
  code: yup
    .string()
    .required('Code is required')
    .test('len', 'Password Reset Code Must Contain 6 Digits', (val) => val?.length === 6),
  password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  confirmPassword: yup
    .string()
    .required('Password Confirm is required')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ResetPasswordFormValues extends yup.Asserts<typeof ResetPasswordSchema> {}

const ResetPasswordFields: React.FC<ResetPasswordProps> = ({
  submit,
  goToSignIn,
  resendCode,
  email,
  error,
  pending,
}) => {
  const { colors } = useContext(ThemeContext)
  const [requestedResend, setRequestedResend] = React.useState<boolean | 'submitting'>(false)

  const initialValues: ResetPasswordFormValues = {
    code: '',
    password: '',
    confirmPassword: '',
  }

  const onResend = async () => {
    setRequestedResend(true)
    await resendCode(email)
  }

  const thingsToTry = [
    "Please check you've given us the correct email address.",
    'Check your spam/junk box.',
    'If you request another code, make sure to use the latest one. The others will now be invalid.',
  ]

  return (
    <AuthSplitContainer>
      <ErrorContainer error={error} />
      <AuthHeader
        heading="Reset Password"
        subHeading="Please enter your password reset code that we sent to your email, and create a new password"
      />
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={ResetPasswordSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {() => (
          <Form>
            <Field
              name="code"
              label="Password Reset Code"
              required
              type="text"
              autoComplete="one-time-code"
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
              showValidationErrorMessages
            />
            <PasswordField
              name="password"
              label="New Password"
              autoComplete="new-password"
              required
              showValidationErrorMessages
            />
            <PasswordField
              name="confirmPassword"
              label="Confirm Password"
              autoComplete="new-password"
              required
              showValidationErrorMessages
            />
            <BottomContainer>
              <SubmitButtonNoWrap disabled={pending} label="Reset my password" />
              <Body1>
                Having trouble with your code? It can take up to 20 minutes to arrive so please hang on. In the
                meantime:
              </Body1>
              <List listArray={thingsToTry} />
              {requestedResend === false ? (
                <Body1>
                  Still no joy?{' '}
                  <InlineButton role="button" onClick={() => onResend()} onKeyDown={() => onResend()} tabIndex={0}>
                    Resend Code.
                  </InlineButton>{' '}
                </Body1>
              ) : (
                <Body1 color={colors.success.dark}>Sent! The code is on its way to {email}</Body1>
              )}

              <ResetPasswordSection onClick={() => goToSignIn()}>
                <Body1>Go to </Body1>
                <Body1 role="button" color={colors.secondary.light}>
                  Sign In
                </Body1>
              </ResetPasswordSection>
            </BottomContainer>
          </Form>
        )}
      </Formik>
    </AuthSplitContainer>
  )
}

const SubmitButtonNoWrap = styled(SubmitButton)`
  white-space: nowrap;
  margin-bottom: 2rem;
`

const InlineButton = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary.light};
  &:hover {
    text-decoration: underline;
  }
`

const BottomContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`

const ResetPasswordSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  text-align: left;
  cursor: pointer;
  width: 100%;
  margin-top: 2rem;
  padding-top: 2rem;
`
