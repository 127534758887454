import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgLinkedin = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M17 7.30999C15.9065 7.29755 14.8329 7.60329 13.91 8.18999C13.8255 8.02695 13.6978 7.89031 13.5408 7.79505C13.3838 7.6998 13.2036 7.64961 13.02 7.64999H9.01999C8.75477 7.64999 8.50042 7.75535 8.31288 7.94289C8.12534 8.13042 8.01999 8.38478 8.01999 8.64999V22C8.01999 22.2652 8.12534 22.5196 8.31288 22.7071C8.50042 22.8946 8.75477 23 9.01999 23H13.17C13.4352 23 13.6896 22.8946 13.8771 22.7071C14.0646 22.5196 14.17 22.2652 14.17 22V15.39C14.17 13.14 14.81 12.96 15.65 12.96C16.49 12.96 16.81 13.33 16.81 15.5V22C16.8092 22.1316 16.8345 22.2621 16.8842 22.3839C16.934 22.5057 17.0073 22.6166 17.1 22.71C17.1934 22.8027 17.3042 22.876 17.4261 22.9258C17.5479 22.9755 17.6784 23.0008 17.81 23H22C22.2652 23 22.5196 22.8946 22.7071 22.7071C22.8946 22.5196 23 22.2652 23 22V14.66C23 11.24 22.32 7.30999 17 7.30999ZM21 21H18.86V15.5C18.86 14.14 18.86 10.96 15.7 10.96C13.39 10.96 12.22 12.45 12.22 15.39V21H10.07V9.64999H12.07V10.47C12.0853 10.7406 12.2037 10.995 12.4009 11.181C12.598 11.367 12.859 11.4704 13.13 11.47C13.3136 11.4704 13.4938 11.4202 13.6508 11.3249C13.8078 11.2297 13.9355 11.093 14.02 10.93C14.3292 10.4162 14.7708 9.99476 15.2985 9.7098C15.8262 9.42484 16.4207 9.28677 17.02 9.30999C19.95 9.30999 21.02 10.71 21.02 14.66L21 21Z" }),
        React.createElement("path", { d: "M7.79999 4.39999C7.79818 3.77245 7.62274 3.15766 7.29308 2.62368C6.96342 2.0897 6.49241 1.65738 5.9322 1.37458C5.37199 1.09178 4.74444 0.969539 4.11905 1.0214C3.49365 1.07325 2.89481 1.29718 2.38883 1.66838C1.88285 2.03959 1.48947 2.54358 1.25226 3.12457C1.01505 3.70555 0.94326 4.34085 1.04484 4.96011C1.14642 5.57938 1.4174 6.15845 1.82778 6.63321C2.23817 7.10796 2.77193 7.45988 3.36999 7.64999H2.36999C2.10477 7.64999 1.85042 7.75535 1.66288 7.94289C1.47534 8.13042 1.36999 8.38478 1.36999 8.64999V22C1.36999 22.2652 1.47534 22.5196 1.66288 22.7071C1.85042 22.8946 2.10477 23 2.36999 23H6.47999C6.7452 23 6.99956 22.8946 7.18709 22.7071C7.37463 22.5196 7.47999 22.2652 7.47999 22V8.64999C7.47999 8.38478 7.37463 8.13042 7.18709 7.94289C6.99956 7.75535 6.7452 7.64999 6.47999 7.64999H5.42999C6.11855 7.42949 6.71921 6.99583 7.14521 6.41165C7.57121 5.82747 7.80051 5.123 7.79999 4.39999ZM5.47999 21H3.32999V9.64999H5.47999V21ZM4.39999 5.82999C4.11875 5.83395 3.84267 5.75429 3.60676 5.60113C3.37085 5.44796 3.18575 5.22819 3.07491 4.96968C2.96408 4.71117 2.93252 4.42557 2.98422 4.14909C3.03593 3.87262 3.16858 3.61773 3.36534 3.41674C3.56211 3.21576 3.81413 3.07774 4.08945 3.02018C4.36477 2.96263 4.65097 2.98813 4.91178 3.09346C5.17258 3.19878 5.39623 3.37919 5.55436 3.6118C5.71249 3.8444 5.79798 4.11873 5.79999 4.39999C5.80266 4.77489 5.65698 5.13563 5.3947 5.40353C5.13243 5.67142 4.77486 5.82472 4.39999 5.82999Z" })));
};
