/* eslint-disable deprecation/deprecation */
import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../buildingBlocks';
import { Caption } from '../../styles';
import { TagIcon } from './TagIcon';
import { getTagColor } from './TagConfig';
export const TagSelector = ({ selected, onClick, tag, onMouseEnter, onMouseLeave }) => {
    return (React.createElement(Outer, { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        React.createElement(TagSelectorContainer, { highlightColor: getTagColor(tag.id) },
            React.createElement(OptionContainer, null,
                React.createElement(Checkbox, { checked: selected, onChange: () => onClick() }),
                React.createElement(TagIcon, { tagId: tag.id, style: TagIconStyles }),
                React.createElement("div", null,
                    React.createElement(Caption, null, tag.title))))));
};
const Outer = styled.div `
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey.light};
  }
`;
const OptionContainer = styled.div `
  display: flex;
`;
const TagSelectorContainer = styled.label `
  transition: all 0.3s;
  user-select: none;
  display: block;
  padding: 1rem;
  &:hover {
    background: ${({ theme }) => theme.colors.grey.light};
    border-right: 0.7rem solid ${(p) => (p.highlightColor ? p.highlightColor : 'transparent')};
  }
  border-right: 0.7rem solid transparent;
`;
const TagIconStyles = {
    width: '2.5rem',
    height: '2.5rem',
    objectFit: 'contain',
    borderRadius: '4px',
    verticalAlign: 'middle',
    margin: '0 2rem',
};
