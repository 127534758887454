/* eslint-disable deprecation/deprecation */
import * as React from 'react';
import { roundDownNumberOfCompanies } from '../../utils/formatting/number';
import { Text } from '../../styles';
export const OrganizationsHeaderText = ({ companiesCount, industriesCount, }) => (React.createElement(Text, null,
    'This pension fund is invested in at least ',
    React.createElement("b", null,
        roundDownNumberOfCompanies(companiesCount),
        " companies"),
    ", split across ",
    industriesCount,
    " different industries:"));
