/* eslint-disable deprecation/deprecation */
import * as React from 'react'
import styled from 'styled-components'
import { MaterialIcon, MenuItemIconSwitch, TypographyCaption } from '@tumelo/shared'
import { Link } from '../../application/router/Link'

interface Props {
  name: string
  icon: MaterialIcon
  path: string
  onClick: () => void
  isActive: boolean
  isExternal?: boolean
}

export const BurgerMenuItem: React.FC<Props> = ({ name, icon, path, onClick, isActive, isExternal }) => {
  return (
    <BurgerLink isExternal={isExternal} to={path} onClick={onClick}>
      <Container isActive={isActive}>
        <Content>
          <MenuItemIconSwitch icon={icon} style={iconStyles} />
          <Name mt="1.6rem" isActive={isActive}>
            {name}
          </Name>
        </Content>
        <ActiveHighlight visible={isActive} />
      </Container>
    </BurgerLink>
  )
}

interface LinkProps {
  to: string
  isExternal?: boolean
  onClick: () => void
}

const BurgerLink: React.FC<React.PropsWithChildren<LinkProps>> = ({ to, isExternal, onClick, children }) => {
  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noreferrer" onClick={onClick}>
        {children}
      </a>
    )
  }

  return (
    <StyledLink to={to} onClick={onClick}>
      {children}
    </StyledLink>
  )
}

interface ContainerProps {
  isActive: boolean
}

const iconStyles: React.CSSProperties = {
  fontSize: '3rem',
  margin: '1.4rem',
}

const StyledLink = styled(Link)`
  cursor: pointer;
`

const Container = styled.div<ContainerProps>`
  cursor: pointer;
  box-shadow: ${(p) => (p.isActive ? '0 0 10px 0 rgba(0, 0, 0, 0.16)' : 'none')};
  color: ${(p) => (p.isActive ? p.theme.colors.info.light : p.theme.colors.info.medium)};
  path {
    fill: ${({ isActive, theme }) => (isActive ? theme.colors.info.light : theme.colors.info.medium)};
  }
  display: flex;
  padding: 10px 0 10px 30px;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  font-weight: bold;
`

const Content = styled.div`
  display: flex;
  align-items: center;
`

interface HighlightProps {
  visible: boolean
}
const ActiveHighlight = styled.div<HighlightProps>`
  display: flex;
  width: 0.5rem;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.info.light};
  visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};
`

interface TitleProps {
  isActive: boolean
}

const Name = styled(TypographyCaption)<TitleProps>`
  color: ${(p) => (p.isActive ? p.theme.colors.info.light : p.theme.colors.info.medium)};
  font-weight: ${(p) => (p.isActive ? 'bold' : 'normal')};
  overflow: hidden;
  white-space: nowrap;
`
