import * as z from 'myzod'

export const SoftConfigFeatureSwitchesSchema = z
  .object(
    {
      enableAccountPercentages: z.boolean().default(false),
      enablePushNotifications: z.boolean().default(false),
      enableEmails: z.boolean().default(true),
      enableAccountDetails: z.boolean().default(true),
      enableCountriesFilter: z.boolean().default(false),
      enableVotingPolicies: z.boolean().default(false),
      skipTermsAndConditionsConsentSSO: z.boolean().default(false),
      idleTimeout: z
        .object({
          enabled: z.boolean().default(false),
          timeoutMinutes: z.number().min(2).default(15),
        })
        .default({
          enabled: false,
          timeoutMinutes: 15,
        }),
      fidelityLogout: z
        .object({
          enableSingleLogout: z.boolean().default(false),
          singleLogoutUrl: z.string().default(''),
        })
        .default({
          enableSingleLogout: false,
          singleLogoutUrl: '',
        }),
      dashboardArea: z
        .object({
          companiesCard: z
            .union([z.object({ type: z.literal('comprehensive') }), z.object({ type: z.literal('minimal') })])
            .default({ type: 'comprehensive' }),
        })
        .default({ companiesCard: { type: 'comprehensive' } }),
      companiesArea: z
        .union([
          z.object({ type: z.literal('comprehensive') }),
          z.object({ type: z.literal('minimal') }),
          z.object({ type: z.literal('off') }),
        ])
        .default({ type: 'comprehensive' }),
    },
    { allowUnknown: true }
  )
  .default({
    enableAccountPercentages: false,
    enablePushNotifications: false,
    enableEmails: true,
    enableAccountDetails: true,
    enableCountriesFilter: false,
    enableVotingPolicies: false,
    skipTermsAndConditionsConsentSSO: false,
    idleTimeout: {
      enabled: false,
      timeoutMinutes: 15,
    },
    fidelityLogout: {
      enableSingleLogout: false,
      singleLogoutUrl: '',
    },
    dashboardArea: {
      companiesCard: {
        type: 'comprehensive',
      },
    },
    companiesArea: {
      type: 'comprehensive',
    },
  })

export type SoftConfigFeatureSwitches = z.Infer<typeof SoftConfigFeatureSwitchesSchema>
