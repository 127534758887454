/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import { TypographyCaption, FontWeight, EmptyNotificationsIcon } from '@tumelo/shared'

export const NotificationsEmpty: React.FC = () => {
  return (
    <CardletContainer>
      <NotificationsEmptyCardlet>
        <TypographyCaption mt="4.6rem" fontWeight={FontWeight.semiBold}>
          Nothing here yet!
        </TypographyCaption>
        <EmptyNotificationsIcon />
      </NotificationsEmptyCardlet>
    </CardletContainer>
  )
}

const NotificationsEmptyCardlet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  width: 32rem;
  border-radius: 20px;
  height: 27.8rem;
  width: 44rem;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.background.medium};
`
const CardletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
