import { AppThunk } from '../../store'
import * as pushSubscriptionsActions from '.'

export const getVAPIDKey =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService, loggerService } = services
    try {
      dispatch(pushSubscriptionsActions.setVapidKeyPending())
      const resp = await dashboardBffService.GetWebPushVAPIDPublicKey()
      dispatch(pushSubscriptionsActions.setVapidKey(resp))
    } catch (e) {
      dispatch(pushSubscriptionsActions.setVapidKeyError())
      loggerService.logError(e)
    }
  }

export const CreatePushSubscription =
  (sub: PushSubscription): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService, loggerService } = services
    try {
      dispatch(pushSubscriptionsActions.setSubscriptionPending())
      await dashboardBffService.CreatePushSubscription(sub.toJSON())
      dispatch(pushSubscriptionsActions.setSubscription(sub))
    } catch (e) {
      dispatch(pushSubscriptionsActions.setSubscriptionError())
      loggerService.logError(e)
    }
  }

export const getRegistration =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { loggerService } = services
    try {
      dispatch(pushSubscriptionsActions.setRegistrationPending())
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.workbox !== undefined) {
        await navigator.serviceWorker.ready.then((reg) => {
          dispatch(pushSubscriptionsActions.setRegistration(reg))
        })
      }
    } catch (e) {
      dispatch(pushSubscriptionsActions.setRegistrationError())
      loggerService.logError(e)
    }
  }

export const getSubscriptionFromRegistration =
  (reg: ServiceWorkerRegistration): AppThunk =>
  async (dispatch, _, { services }) => {
    const { loggerService } = services
    try {
      dispatch(pushSubscriptionsActions.setSubscriptionPending())
      await reg.pushManager.getSubscription().then((sub) => {
        const expirationTime = sub?.toJSON()?.expirationTime
        if (sub && !(expirationTime && Date.now() > expirationTime - 5 * 60 * 1000)) {
          return dispatch(pushSubscriptionsActions.setSubscription(sub))
        }
        return dispatch(pushSubscriptionsActions.setSubscription(undefined))
      })
    } catch (e) {
      dispatch(pushSubscriptionsActions.setSubscriptionError())
      loggerService.logError(e)
    }
  }
