/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import { Text, MiniGridCard, TagIcon, TagAndVoteCount } from '@tumelo/shared'
import { Payload } from '../../application/payload'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'
import { SkeletonRows } from '../../buildingBlocks/Skeleton'

export const YourVotingInterestsCard: React.FC<{
  tagAndCount: Payload<TagAndVoteCount>
}> = ({ tagAndCount }) => (
  <MiniGridCard title="Your voting interests">
    <PayloadUnwrapper
      key="voting-interest"
      item={tagAndCount}
      customNotInitialized={<SkeletonRows count={4} />}
      customSpinner={<SkeletonRows count={4} />}
    >
      {(tagAndCount) => (
        <>
          <TagIcon tagId={tagAndCount.tag.id} />
          <TextPadLeft>
            Your most popular vote category: <strong>{tagAndCount.tag.title}</strong>
          </TextPadLeft>
        </>
      )}
    </PayloadUnwrapper>
  </MiniGridCard>
)

const TextPadLeft = styled(Text)`
  margin-left: 1rem;
`
