/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { TypographyCaption, BreakpointAsMaxValue, FontWeight } from '@tumelo/shared'
import { useAppSelector } from '../../../../application/store'
import { selectBranding } from '../../../../application/features/config/selectors'

interface Props {
  showBranding?: boolean
  invertColours?: boolean
  smallerMobileLogo?: boolean
}

export const Branding: React.FC<Props> = ({
  showBranding = false,
  invertColours = false,
  smallerMobileLogo = false,
}) => {
  const { title, branding } = useAppSelector((state) => ({
    title: state.config.config.softConfig.settings.title,
    branding: selectBranding(state),
  }))
  const { colors } = useTheme()
  const logo = invertColours ? branding.signUpLogoUrl : branding.logoUrl

  return (
    <Container>
      <ImageCropper roundCorners={branding.logoUsesCircle} smaller={smallerMobileLogo}>
        <ProviderLogo alt="" src={logo} />
      </ImageCropper>
      <Column poweredBy={showBranding}>
        <StyledTypographyCaption
          as="span"
          mt="0.2rem"
          fontWeight={FontWeight.bold}
          color={invertColours ? colors.dark : colors.white}
        >
          {title}
        </StyledTypographyCaption>
        {showBranding && <PoweredBy src="/images/light_powered_by.png" alt="Powered by tumelo" />}
      </Column>
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const PoweredBy = styled.img`
  width: 17rem;
  height: 1.72rem;
  @media (max-width: ${BreakpointAsMaxValue.desktop}) {
    width: 13rem;
    height: 1.28rem;
  }
`

const Column = styled.div<{ poweredBy: boolean }>`
  ${({ poweredBy }) => (poweredBy ? `display: flex` : '')}
  flex-direction: column;
  margin-top: 0.1rem;
  width: 20rem;
  @media (max-width: ${BreakpointAsMaxValue.desktop}) {
    width: 15rem;
  }
  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    width: 22rem;
    max-width: 50vw;
  }
`

const ImageCropper = styled.div<{ roundCorners: boolean; smaller?: boolean }>`
  margin-right: 1rem;
  width: 7rem;
  height: 7rem;
  @media (max-width: ${BreakpointAsMaxValue.desktop}) {
    ${({ smaller }) => (smaller ? `width:4.5rem; height:4.5rem` : 'width: 5.5rem; height: 5.5rem')}
  }
  overflow: hidden;
  ${({ roundCorners }) => (roundCorners ? 'border-radius: 50%;' : '')}
`

const ProviderLogo = styled.img`
  display: inline;
  margin: auto;
  object-fit: contain;
  width: 100%;
`

const StyledTypographyCaption = styled(TypographyCaption)`
  display: block;
`
