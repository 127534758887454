import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState } from '../../payload'
import { getRegistration, getSubscriptionFromRegistration, getVAPIDKey } from './asyncActions'
import { selectPublicVAPIDKey, selectRegistration, selectSubscription } from './selector'

export const useVapidKey = () => {
  const dispatch = useAppDispatch()
  const applicationServerKey = useAppSelector(selectPublicVAPIDKey)

  useEffect(() => {
    if (applicationServerKey === 'not-initialised') {
      dispatch(getVAPIDKey())
    }
  }, [dispatch, applicationServerKey])

  return applicationServerKey
}

export const useRegistration = () => {
  const dispatch = useAppDispatch()
  const registration = useAppSelector(selectRegistration)

  useEffect(() => {
    if (registration === 'not-initialised') {
      dispatch(getRegistration())
    }
  }, [dispatch, registration])

  return registration
}

export const useSubscription = () => {
  const dispatch = useAppDispatch()
  const registration = useRegistration()

  const subscription = useAppSelector(selectSubscription)

  useEffect(() => {
    if (subscription === 'not-initialised' && isValueState(registration)) {
      dispatch(getSubscriptionFromRegistration(registration))
    }
  }, [dispatch, subscription, registration])

  return subscription
}
