/* eslint-disable deprecation/deprecation */
import React from 'react'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { Breakpoint, SubmitButton } from '@tumelo/shared'
import { AuthSplitContainer } from '../Components/AuthSplitContainer'
import { AuthHeader } from '../Components/AuthHeader'
import { ErrorContainer } from '../Components/ErrorContainer'
import { ExternalLinkWithTracking } from '../../../buildingBlocks/ExternalLinkWithTracking'

interface Props {
  providerName: string
  termsUrl: string
  privacyUrl: string
  error?: string
  signInWithSSO: () => Promise<void>
}

export const SignInSSOForm: React.FC<Props> = ({ providerName, termsUrl, privacyUrl, signInWithSSO, error }) => {
  const signInLabelText = `Sign in with ${providerName}`
  return (
    <AuthSplitContainer>
      <Formik initialValues={{}} onSubmit={signInWithSSO}>
        <Form>
          <ErrorContainer error={error} />

          <AuthHeader
            subHeading={
              <>
                {' '}
                {`By clicking '${signInLabelText}' you are accepting Tumelo's terms. Read more in our `}
                <StyledLink to={privacyUrl}>Privacy Policy</StyledLink>
                {' and '}
                <StyledLink to={termsUrl}>Terms &amp; Conditions</StyledLink>
              </>
            }
            heading="Hi there! Are you here to look under the hood of your investments?"
          />
          <SignInButtonContainer>
            <BottomContainer>
              <SubmitButton label={signInLabelText} />
            </BottomContainer>
          </SignInButtonContainer>
        </Form>
      </Formik>
    </AuthSplitContainer>
  )
}

const SignInButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  @media (max-width: ${Breakpoint.mobile}) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const BottomContainer = styled.div`
  margin-top: 4rem;
`

const StyledLink = styled(ExternalLinkWithTracking)`
  color: inherit;
  text-decoration: underline;
`
