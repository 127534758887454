import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgMessage = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M20.93 0.999997H6.54C6.27153 0.990595 6.00391 1.03538 5.75312 1.13167C5.50233 1.22796 5.27351 1.37379 5.08032 1.56045C4.88712 1.74711 4.73351 1.97078 4.62865 2.21811C4.52379 2.46544 4.46984 2.73136 4.47 3V5.85H3.07C2.80153 5.84059 2.53391 5.88538 2.28312 5.98167C2.03233 6.07796 1.80351 6.22379 1.61032 6.41045C1.41712 6.59711 1.26351 6.82078 1.15865 7.06811C1.05379 7.31544 0.999836 7.58136 1 7.85V17.75C0.999836 18.0186 1.05379 18.2846 1.15865 18.5319C1.26351 18.7792 1.41712 19.0029 1.61032 19.1895C1.80351 19.3762 2.03233 19.522 2.28312 19.6183C2.53391 19.7146 2.80153 19.7594 3.07 19.75H4.56V22C4.55962 22.1836 4.60981 22.3638 4.70506 22.5208C4.80031 22.6778 4.93695 22.8055 5.1 22.89C5.24321 22.9605 5.40038 22.9981 5.56 23C5.76841 22.9986 5.97119 22.9322 6.14 22.81L10.41 19.81H17.46C17.7285 19.8194 17.9961 19.7746 18.2469 19.6783C18.4977 19.582 18.7265 19.4362 18.9197 19.2495C19.1129 19.0629 19.2665 18.8392 19.3713 18.5919C19.4762 18.3446 19.5302 18.0786 19.53 17.81V14.91H20.93C21.1985 14.9194 21.4661 14.8746 21.7169 14.7783C21.9677 14.682 22.1965 14.5362 22.3897 14.3495C22.5829 14.1629 22.7365 13.9392 22.8413 13.6919C22.9462 13.4446 23.0002 13.1786 23 12.91V3C23.0002 2.73136 22.9462 2.46544 22.8413 2.21811C22.7365 1.97078 22.5829 1.74711 22.3897 1.56045C22.1965 1.37379 21.9677 1.22796 21.7169 1.13167C21.4661 1.03538 21.1985 0.990595 20.93 0.999997ZM17.46 17.76H10.09C9.88268 17.7584 9.68 17.8213 9.51 17.94L6.56 20.05V18.76C6.56 18.4948 6.45464 18.2404 6.26711 18.0529C6.07957 17.8654 5.82522 17.76 5.56 17.76H3L3.07 7.86H17.53L17.46 17.76ZM20.93 12.91H19.53V7.85C19.5302 7.58136 19.4762 7.31544 19.3713 7.06811C19.2665 6.82078 19.1129 6.59711 18.9197 6.41045C18.7265 6.22379 18.4977 6.07796 18.2469 5.98167C17.9961 5.88538 17.7285 5.84059 17.46 5.85H6.46L6.54 3H21L20.93 12.91Z" }),
        React.createElement("path", { d: "M6.12 11.82H13.73C13.9952 11.82 14.2496 11.7146 14.4371 11.5271C14.6246 11.3396 14.73 11.0852 14.73 10.82C14.73 10.5548 14.6246 10.3004 14.4371 10.1129C14.2496 9.92535 13.9952 9.82 13.73 9.82H6.12C5.85478 9.82 5.60043 9.92535 5.41289 10.1129C5.22536 10.3004 5.12 10.5548 5.12 10.82C5.12 11.0852 5.22536 11.3396 5.41289 11.5271C5.60043 11.7146 5.85478 11.82 6.12 11.82Z" }),
        React.createElement("path", { d: "M6.12 14.91H11.74C12.0052 14.91 12.2596 14.8046 12.4471 14.6171C12.6346 14.4296 12.74 14.1752 12.74 13.91C12.74 13.6448 12.6346 13.3904 12.4471 13.2029C12.2596 13.0154 12.0052 12.91 11.74 12.91H6.12C5.85478 12.91 5.60043 13.0154 5.41289 13.2029C5.22536 13.3904 5.12 13.6448 5.12 13.91C5.12 14.1752 5.22536 14.4296 5.41289 14.6171C5.60043 14.8046 5.85478 14.91 6.12 14.91Z" })));
};
