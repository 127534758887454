import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgCompanies = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M21.45 6.55H13.82V3C13.82 2.45 13.37 2 12.82 2H3C2.45 2 2 2.45 2 3V21.44C2 21.99 2.45 22.44 3 22.44H21.45C22 22.44 22.45 21.99 22.45 21.44V7.55C22.45 7 22 6.55 21.45 6.55ZM4 4H11.82V20.44H4V4ZM13.82 8.55H20.45V9.63H18.82C18.26 9.63 17.81 10.07 17.81 10.61C17.81 11.15 18.26 11.59 18.82 11.59H20.45V13.02H18.82C18.26 13.02 17.81 13.46 17.81 14C17.81 14.54 18.26 14.98 18.82 14.98H20.45V16.41H18.82C18.26 16.41 17.81 16.85 17.81 17.39C17.81 17.93 18.26 18.37 18.82 18.37H20.45V20.45H13.82V8.55Z" }),
        React.createElement("path", { d: "M9.72 12.83C9.18 12.83 8.74 13.28 8.74 13.83V14.6C8.74 15.15 9.18 15.6 9.72 15.6C10.26 15.6 10.7 15.15 10.7 14.6V13.83C10.7 13.28 10.26 12.83 9.72 12.83Z" }),
        React.createElement("path", { d: "M6.1 12.83C5.56 12.83 5.12 13.28 5.12 13.83V14.6C5.12 15.15 5.56 15.6 6.1 15.6C6.64 15.6 7.08 15.15 7.08 14.6V13.83C7.08 13.28 6.64 12.83 6.1 12.83Z" }),
        React.createElement("path", { d: "M9.72 16.83C9.18 16.83 8.74 17.28 8.74 17.83V18.6C8.74 19.15 9.18 19.6 9.72 19.6C10.26 19.6 10.7 19.15 10.7 18.6V17.83C10.7 17.28 10.26 16.83 9.72 16.83Z" }),
        React.createElement("path", { d: "M6.1 16.83C5.56 16.83 5.12 17.28 5.12 17.83V18.6C5.12 19.15 5.56 19.6 6.1 19.6C6.64 19.6 7.08 19.15 7.08 18.6V17.83C7.08 17.28 6.64 16.83 6.1 16.83Z" }),
        React.createElement("path", { d: "M9.72 8.83C9.18 8.83 8.74 9.28 8.74 9.83V10.6C8.74 11.15 9.18 11.6 9.72 11.6C10.26 11.6 10.7 11.15 10.7 10.6V9.83C10.7 9.28 10.26 8.83 9.72 8.83Z" }),
        React.createElement("path", { d: "M6.1 8.83C5.56 8.83 5.12 9.28 5.12 9.83V10.6C5.12 11.15 5.56 11.6 6.1 11.6C6.64 11.6 7.08 11.15 7.08 10.6V9.83C7.08 9.28 6.64 8.83 6.1 8.83Z" }),
        React.createElement("path", { d: "M9.72 4.83C9.18 4.83 8.74 5.28 8.74 5.83V6.6C8.74 7.15 9.18 7.6 9.72 7.6C10.26 7.6 10.7 7.15 10.7 6.6V5.83C10.7 5.28 10.26 4.83 9.72 4.83Z" }),
        React.createElement("path", { d: "M6.1 4.83C5.56 4.83 5.12 5.28 5.12 5.83V6.6C5.12 7.15 5.56 7.6 6.1 7.6C6.64 7.6 7.08 7.15 7.08 6.6V5.83C7.08 5.28 6.64 4.83 6.1 4.83Z" }),
        React.createElement("path", { d: "M16.13 13.02H15.36C14.81 13.02 14.36 13.46 14.36 14C14.36 14.54 14.81 14.98 15.36 14.98H16.13C16.68 14.98 17.13 14.54 17.13 14C17.13 13.46 16.68 13.02 16.13 13.02Z" }),
        React.createElement("path", { d: "M16.13 16.4H15.36C14.81 16.4 14.36 16.84 14.36 17.38C14.36 17.92 14.81 18.36 15.36 18.36H16.13C16.68 18.36 17.13 17.92 17.13 17.38C17.13 16.84 16.68 16.4 16.13 16.4Z" }),
        React.createElement("path", { d: "M16.13 9.63H15.36C14.81 9.63 14.36 10.07 14.36 10.61C14.36 11.15 14.81 11.59 15.36 11.59H16.13C16.68 11.59 17.13 11.15 17.13 10.61C17.13 10.07 16.68 9.63 16.13 9.63Z" })));
};
