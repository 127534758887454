/* eslint-disable deprecation/deprecation */
import React from 'react'
import { RadioGroup } from '@mui/material'
import { Body, RadioSelectButton } from '@tumelo/shared'
import { BinaryQuestion as BinaryQuestionType } from '../../../config/SoftConfigPortfolioSelection'

interface BinaryQuestionProps {
  question: BinaryQuestionType
  value: string
  onChange: (e: React.ChangeEvent<any>) => void
}

export const BinaryQuestion: React.FC<BinaryQuestionProps> = ({ question, value, onChange }) => {
  const { options, questionText, id } = question

  return (
    <div style={{ marginBottom: '4rem' }}>
      <Body id={id}>{questionText}</Body>
      <RadioGroup
        aria-labelledby={id}
        style={{ gap: '3rem', justifyContent: 'center' }}
        row
        name={id}
        value={value}
        onChange={onChange}
      >
        {options.map((o) => {
          const value = o.type === 'modelPortfolio' ? o.modelPortfolioId : o.nextQuestionId
          return <RadioSelectButton key={value} label={o.optionText} value={value} />
        })}
      </RadioGroup>
    </div>
  )
}
