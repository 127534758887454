/* eslint-disable deprecation/deprecation */
import React from 'react';
import styled from 'styled-components';
import { Caption } from '../../../../styles';
import { ArrowIcon } from '../images/MenuArrow';
export const FilterMenu = ({ active, toggleActive, title, children }) => {
    const contentId = `filtermenu-${title.replace(/\s/g, '')}`;
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { "aria-controls": contentId, "aria-expanded": active ? 'true' : 'false', onClick: () => toggleActive() },
            React.createElement(Caption, null, title),
            React.createElement(Arrow, { active: active })),
        React.createElement(Border, null),
        React.createElement("div", { "aria-hidden": !active, id: contentId }, active && React.createElement(FilterMenuItems, null, children))));
};
const Container = styled.button `
  border: 0;
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem;
  user-select: none;
  margin-top: 1rem;
`;
const FilterMenuItems = styled.div `
  display: flex;
  flex-direction: column;
`;
const Border = styled.div `
  border-bottom: 0.2rem solid ${({ theme }) => theme.colors.grey.light};
`;
const Arrow = styled(ArrowIcon) `
  transform: ${(p) => !p.active && 'rotate(180deg)'};
  width: 1.5rem;
  fill: ${({ theme }) => theme.colors.dark};
  height: 1.5rem;
`;
