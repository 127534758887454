/* eslint-disable deprecation/deprecation */
import React, { useContext } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import styled, { ThemeContext } from 'styled-components'
import { Body, SubmitButton, Field } from '@tumelo/shared'
import { List } from '@tumelo/designsystem'
import { ErrorContainer } from '../Components/ErrorContainer'
import { AuthSplitContainer } from '../Components/AuthSplitContainer'
import { AuthHeader } from '../Components/AuthHeader'
import { AuthBottomButton } from '../Components/AuthBottomButton'

interface Props {
  submit: (details: ConfirmCodeValues) => Promise<void>
  resendCode: (email: string) => Promise<void>
  goToSignIn: () => void
  email: string
  error: string | undefined
  pending: boolean
}

const signUpSchema = yup.object({
  email: yup.string().required('Required'),
  code: yup.string().required('Code required'),
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConfirmCodeValues extends yup.Asserts<typeof signUpSchema> {}

export const ConfirmCodeForm: React.FC<Props> = ({ submit, email, goToSignIn, resendCode, error, pending }) => {
  const [requestedResend, setRequestedResend] = React.useState<boolean | 'submitting'>(false)
  const initialValues: ConfirmCodeValues = {
    email,
    code: '',
  }

  const onSubmit = async (values: ConfirmCodeValues) => {
    const code = values.code.trim()
    await submit({ email: values.email, code })
  }

  const onResend = async () => {
    setRequestedResend(true)
    await resendCode(email)
  }

  const { colors } = useContext(ThemeContext)

  const thingsToTry = [
    "Please check you've given us the correct email address",
    'Check your spam/junk box.',
    'If you request another code, make sure to use the latest one. The others will now be invalid.',
  ]

  return (
    <AuthSplitContainer>
      <ErrorContainer error={error} />
      <AuthHeader
        subHeading="We have sent you a confirmation code to the following email address. Please copy and paste that code into the space below:"
        heading="Confirm your email address"
      />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => onSubmit(values)}
        validationSchema={signUpSchema}
      >
        {({ values }) => (
          <Form>
            <Field
              name="email"
              label="Email address"
              required
              type="email"
              autoComplete="email"
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
              disabled={email !== ''}
            />
            <Field
              name="code"
              label="Confirmation Code"
              required
              type="text"
              autoComplete="one-time-code"
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
            <BottomContainer>
              <SubmitButtonContainer>
                <SubmitButton disabled={pending} label="Confirm Account" />
              </SubmitButtonContainer>
              <Body>Having trouble with your code?</Body>
              <Body>It can take up to 20 minutes to arrive so please hang on. In the meantime:</Body>
              <List listArray={thingsToTry} />
              {requestedResend === false ? (
                <Body>
                  Still no joy?{' '}
                  <InlineButton role="button" onClick={() => onResend()} onKeyDown={() => onResend()} tabIndex={0}>
                    Resend Code.
                  </InlineButton>{' '}
                </Body>
              ) : (
                <Body as="p" color={colors.success.dark}>
                  Sent! The code is on its way to {values.email}
                </Body>
              )}

              <AuthBottomButton onClick={goToSignIn} firstPart="Have an account? " secondPart="Sign in" />
            </BottomContainer>
          </Form>
        )}
      </Formik>
    </AuthSplitContainer>
  )
}

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 1rem 1rem 0;
`

const BottomContainer = styled.div`
  margin-top: 2rem;
`

const InlineButton = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary.light};
  &:hover {
    text-decoration: underline;
  }
`
