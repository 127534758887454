import {
  Composition,
  sampleComposition,
  sampleTumeloAccount,
  sampleVotingPolicy,
  TumeloAccount,
  VotingPolicy,
} from '@tumelo/shared'
import { UserPreferences } from '../../types/UserPreferences/UserPreferences'
import { GetWebPushVAPIDPublicKeyResponse } from '../../../utils/proto/tumelo/dashboardbff/v1/service'
import { VoteResult } from '../../../utils/proto/tumelo/dashboardbff/v1/vote_result'
import { SampleNotification } from '../../types/AppNotification/Sample'
import { sampleVoteResult } from '../../types/VoteResult'
import { AppNotification, ResolutionType } from '../../types/AppNotification/AppNotification'
import { PushSubscription as PushSubscriptionProto } from '../../../utils/proto/tumelo/platform/v1/push_subscription'
import { DashboardBffService } from './DashboardBffService'

const SampleUserPreferences: UserPreferences = { subscribedEmail: true, subscribedToPushNotifications: true }

export const DashboardBffServiceMock: DashboardBffService = {
  GetUserPreferences: async (): Promise<UserPreferences> => SampleUserPreferences,
  SetSubscribedToEmails: async (): Promise<UserPreferences> => SampleUserPreferences,
  SetSubscribedToPushNotification: async (): Promise<UserPreferences> => SampleUserPreferences,
  ListNotifications: async (
    pageToken: string
  ): Promise<{ notifications: AppNotification[]; nextPageToken: string }> => {
    if (pageToken === '') {
      const n1 = SampleNotification()
      n1.title = 'Sample (page 1)'
      n1.id = '1'
      return {
        notifications: [n1],
        nextPageToken: 'next1',
      }
    }
    if (pageToken === 'next1') {
      const n2 = SampleNotification()
      n2.title = 'Sample (page 2)'
      n2.id = '2'
      return {
        notifications: [n2],
        nextPageToken: 'next2',
      }
    }
    const n3 = SampleNotification()
    n3.title = 'Sample (page 3)'
    n3.id = '3'
    return {
      notifications: [n3],
      nextPageToken: '',
    }
  },
  UpdateNotificationResolution: async (): Promise<ResolutionType> => 'opened',
  GetWebPushVAPIDPublicKey: async (): Promise<GetWebPushVAPIDPublicKeyResponse> => ({ vapidPublicKey: 'key' }),
  CreatePushSubscription: async (): Promise<PushSubscriptionProto> => PushSubscriptionProto.fromPartial({}),

  FetchOrganizationBreakdown: async (): Promise<{ organizationIds: string[] }> => ({ organizationIds: [] }),
  GetTermsAndConditionsConsent: async () => ({ accepted: true, createTime: new Date() }),
  UpdateTermsAndConditionsConsent: async (accepted) => ({ accepted, createTime: new Date() }),
  FetchHabitatVotingPolicies: async (): Promise<VotingPolicy[]> => {
    return [sampleVotingPolicy()]
  },
  CreateCompositionWithModelPortfolio: async (): Promise<{ account: TumeloAccount; composition: Composition }> => ({
    account: sampleTumeloAccount(),
    composition: sampleComposition(),
  }),
  FetchVoteResults: async (): Promise<{ voteResults: VoteResult[]; nextPageToken: string }> => {
    return {
      voteResults: [sampleVoteResult()],
      nextPageToken: '',
    }
  },
}
