import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgDefencesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E7EAF5" }),
        React.createElement("path", { d: "M12 22H11.5C11.41 22 11.33 21.99 11.25 21.97C5.51 20.46 1.5 15.18 1.5 9.12999C1.5 8.00999 1.64 6.88999 1.91 5.79999C1.98 5.53999 2.14 5.31999 2.37 5.17999C2.6 5.03999 2.88 5.00999 3.13 5.06999C6.41 5.92999 8.95 5.04999 10.66 2.43999C10.84 2.15999 11.16 1.98999 11.5 1.98999H12C12.34 1.98999 12.65 2.15999 12.84 2.43999C14.55 5.04999 17.08 5.92999 20.37 5.06999C20.62 4.99999 20.9 5.03999 21.13 5.17999C21.36 5.31999 21.53 5.53999 21.59 5.79999C21.86 6.87999 22 7.99999 22 9.12999C22 15.18 17.99 20.46 12.25 21.97C12.17 21.99 12.08 22 12 22ZM11.63 20H11.87C16.66 18.68 20 14.23 20 9.12999C20 8.49999 19.95 7.85999 19.85 7.23999C16.52 7.82999 13.74 6.83999 11.75 4.35999C9.76 6.84999 6.99 7.82999 3.65 7.23999C3.55 7.85999 3.5 8.48999 3.5 9.12999C3.5 14.23 6.84 18.68 11.63 20Z", fill: "#173178" })));
};
