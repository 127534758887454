/* eslint-disable deprecation/deprecation */
import React from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber'
import { Breakpoint, SubmitButton, Field, hexAlpha } from '@tumelo/shared'
import { useAppSelector } from '../../../application/store'
import { CheckboxField } from '../../Form/CheckboxField'
import { PasswordField } from '../../Form/PasswordField'
import { selectSoftConfig } from '../../../application/features/config/selectors'
import { compose } from '../../../utils/functional/common'
import { Markdown } from '../../Markdown'
import { PhoneField } from '../../Form/PhoneField'
import { AuthBottomButton } from '../Components/AuthBottomButton'
import { AuthHeader } from '../Components/AuthHeader'
import { ErrorContainer } from '../Components/ErrorContainer'
import { AuthSplitContainer } from '../Components/AuthSplitContainer'
import { ExternalLinkWithTracking } from '../../../buildingBlocks/ExternalLinkWithTracking'

interface Props {
  submit: (details: SignUpFormValues) => Promise<void>
  clickSignIn: () => void
  termsUrl: string
  privacyUrl: string
  email: string
  phoneNumber: string
  password: string
  error: string | undefined
  pending: boolean
}

export interface SignUpFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  acceptTermsAndPrivacy: boolean
  phoneNumber?: string
}

export const SignUpForm: React.FC<Props> = ({
  submit,
  termsUrl,
  privacyUrl,
  clickSignIn,
  email,
  password,
  phoneNumber,
  error,
  pending,
}) => {
  const collectPhoneOnSignup = useAppSelector(
    (state) => state.config.config.softConfig.settings.collectTelephoneNumberOnSignup
  )

  const SignUpSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().required('Password is required').min(10, 'Password requires 10 characters or more'),
    confirmPassword: yup
      .string()
      .required('Password Confirmation is required')
      .oneOf([yup.ref('password')], 'Passwords do not match'),
    acceptTermsAndPrivacy: yup.bool().oneOf([true]).required(),
    phoneNumber: yup.lazy(() => {
      if (collectPhoneOnSignup) {
        const internationalPhoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/
        return yup
          .string()
          .required('Phone number is required')
          .matches(internationalPhoneRegExp, 'Invalid phone number')
      }

      return yup.string().optional()
    }),
  })

  const createMyAccountWarningMessage = useAppSelector(
    compose(selectSoftConfig, (c) => c.settings.warningMessages.createMyAccount)
  )
  const initialValues: SignUpFormValues = {
    email,
    firstName: '',
    lastName: '',
    password,
    confirmPassword: '',
    acceptTermsAndPrivacy: false,
    phoneNumber,
  }

  const termsAndPrivacyText = (
    <>
      I agree to the <StyledLink to={termsUrl}>Terms &amp; Conditions</StyledLink> and the{' '}
      <StyledLink to={privacyUrl}>Privacy Policy</StyledLink>
    </>
  )

  return (
    <AuthSplitContainer>
      <ErrorContainer error={error} />
      <AuthHeader
        heading="It's your pension, it's your power"
        subHeading="Check out your companies & get your voice heard"
      />
      {createMyAccountWarningMessage ? (
        <WarningContainer>
          <PrimaryColouredMarkdown markdown={createMyAccountWarningMessage} />
        </WarningContainer>
      ) : null}
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (values.phoneNumber) {
            const phoneUtil = PhoneNumberUtil.getInstance()
            const number = phoneUtil.parse(values.phoneNumber)
            const formattedValues = {
              ...values,
              phoneNumber: phoneUtil.format(number, PNF.INTERNATIONAL).toString().replace(/\s/g, ''),
            }
            await submit(formattedValues)
          } else {
            await submit(values)
          }
        }}
        validationSchema={SignUpSchema}
      >
        <Form>
          <Field name="firstName" label="First Name" autoComplete="given-name" required showValidationErrorMessages />
          <Field name="lastName" label="Last Name" autoComplete="family-name" required showValidationErrorMessages />
          <Field
            name="email"
            label="Email Address"
            required
            type="email"
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            autoComplete="email"
            showValidationErrorMessages
          />
          {collectPhoneOnSignup && (
            <PhoneField
              showValidationErrorMessages
              required
              name="phoneNumber"
              label="Phone Number"
              helperText="We need this for two-step authentication"
            />
          )}
          <PasswordField
            name="password"
            label="Password"
            autoComplete="new-password"
            required
            showValidationErrorMessages
            helperText="Password requires 10 characters or more"
          />
          <PasswordField
            name="confirmPassword"
            label="Confirm Password"
            autoComplete="new-password"
            required
            showValidationErrorMessages
          />
          <CheckboxField
            name="acceptTermsAndPrivacy"
            label={termsAndPrivacyText}
            required
            showValidationErrorMessages
            helperText="Please accept Privacy Policy and T&Cs"
            warningIconRightIndentRem={-0.5}
          />

          <BottomContainer>
            <SignUpButtonContainer>
              <SubmitButton disabled={pending} label="Create my account" />
            </SignUpButtonContainer>
            <AuthBottomButton onClick={clickSignIn} firstPart="Have an account? " secondPart="Sign In" />
          </BottomContainer>
        </Form>
      </Formik>
    </AuthSplitContainer>
  )
}

const SignUpButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  @media (max-width: ${Breakpoint.mobile}) {
    width: auto;
    flex-direction: column;
  }
`

const StyledLink = styled(ExternalLinkWithTracking)`
  color: inherit;
  text-decoration: underline;
`

const BottomContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`

const PrimaryColouredMarkdown = styled(Markdown)`
  opacity: 100%;
`

const WarningContainer = styled.div`
  margin-top: 1.3rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-top: 1.5rem;
  width: calc(100% - 2.4rem - 2.4rem);
  border: 1px solid ${({ theme }) => theme.colors.light};
  border-radius: 5px;
  background-color: ${({ theme }) => hexAlpha(theme.colors.light, 0.6)};
`
