/* eslint-disable deprecation/deprecation */
import React from 'react'
import { Breakpoint, Button } from '@tumelo/shared'
import styled from 'styled-components'

interface Props {
  isOpen: boolean
  setIsOpen: () => void
}

export const ReadMoreButtonTextVariant: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <ButtonContainer>
      <Button onClick={setIsOpen} inverse>
        {isOpen ? 'Read less' : 'Read more'}
      </Button>
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: -6.5rem;

  @media (max-width: ${Breakpoint.mobile}) {
    margin-top: -4.5rem;
  }
`
