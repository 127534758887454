function pcompose<A, B, R>(f: (a: A) => B, f2: (b: B) => R): (a: A) => R
function pcompose<A, B, C, R>(f: (a: A) => B, f2: (b: B) => C, f3: (c: C) => R): (x: A) => R
function pcompose<A, B, C, D, R>(f: (a: A) => B, f2: (b: B) => C, f3: (c: C) => D, f4: (d: D) => R): (a: A) => R
function pcompose<A, B, C, D, E, R>(
  f: (a: A) => B,
  f2: (b: B) => C,
  f3: (c: C) => D,
  f4: (d: D) => E,
  f5: (d: E) => R
): (a: A) => R
function pcompose(...fns: ((t: any) => any)[]) {
  return (ipt: any) => fns.reduce((acc, item) => item(acc), ipt)
}

export const compose = pcompose

function ppipe<A, R>(ipt: A, f: (a: A) => R): R
function ppipe<A, B, R>(ipt: A, f: (a: A) => B, f2: (b: B) => R): R
function ppipe<A, B, C, R>(ipt: A, f: (a: A) => B, f2: (b: B) => C, f3: (c: C) => R): R
function ppipe<A, B, C, D, R>(ipt: A, f: (a: A) => B, f2: (b: B) => C, f3: (c: C) => D, f4: (d: D) => R): R
function ppipe<A, B, C, D, E, R>(
  ipt: A,
  f: (a: A) => B,
  f2: (b: B) => C,
  f3: (c: C) => D,
  f4: (c: D) => E,
  f5: (d: E) => R
): R
function ppipe<A, B, C, D, E, F, R>(
  ipt: A,
  f: (a: A) => B,
  f2: (b: B) => C,
  f3: (c: C) => D,
  f4: (c: D) => E,
  f5: (c: E) => F,
  f6: (d: F) => R
): R
function ppipe(ipt: any, ...fns: ((t: any) => any)[]) {
  return fns.reduce((acc, item) => item(acc), ipt)
}

export const pipe = ppipe

export const identity = <T>(x: T) => x

function pcombineOutputs<I, A, B>(f: (a: I) => A, f2: (b: I) => B): (input: I) => [a: A, b: B]
function pcombineOutputs<I, A, B, C>(f: (a: I) => A, f2: (b: I) => B, f3: (c: I) => C): (input: I) => [a: A, b: B, c: C]
function pcombineOutputs<I, A, B, C, D>(
  f: (a: I) => A,
  f2: (b: I) => B,
  f3: (c: I) => C,
  f4: (c: I) => D
): (input: I) => [a: A, b: B, c: C, d: D]
function pcombineOutputs(...fns: ((t: any) => any)[]) {
  return (ipt: any) => fns.map((item) => item(ipt))
}

// Takes a bunch of functions with the same input but different outputs, and produces a single function with all of the outputs combined into a tuple/array
// This is useful for selector composition:
// combineOuptuts(
///  selectorA,
//  selectorB,
//  selectorC
// )
// which is equivalent to doing (state) => [selectorA(state), selectorB(state), selectorC(state)...]
export const combineOutputs = pcombineOutputs
