import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgTick = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.71 5.79C20.8037 5.88296 20.8781 5.99356 20.9289 6.11542C20.9797 6.23728 21.0058 6.36799 21.0058 6.5C21.0058 6.63201 20.9797 6.76272 20.9289 6.88458C20.8781 7.00644 20.8037 7.11704 20.71 7.21L9.71 18.21C9.61704 18.3037 9.50644 18.3781 9.38458 18.4289C9.26272 18.4797 9.13201 18.5058 9 18.5058C8.86799 18.5058 8.73728 18.4797 8.61543 18.4289C8.49357 18.3781 8.38297 18.3037 8.29 18.21L3.29 13.21C3.1017 13.0217 2.99591 12.7663 2.99591 12.5C2.99591 12.2337 3.1017 11.9783 3.29 11.79C3.47831 11.6017 3.7337 11.4959 4 11.4959C4.2663 11.4959 4.5217 11.6017 4.71 11.79L9 16.09L19.29 5.79C19.383 5.69627 19.4936 5.62188 19.6154 5.57111C19.7373 5.52034 19.868 5.4942 20 5.4942C20.132 5.4942 20.2627 5.52034 20.3846 5.57111C20.5064 5.62188 20.617 5.69627 20.71 5.79Z" })));
};
