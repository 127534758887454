/* eslint-disable deprecation/deprecation */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Breakpoint } from '@tumelo/shared'
import {
  TwitterFillIcon,
  LinkedinFillIcon,
  MessageIcon,
  Caption,
  ButtonText,
  InstagramIcon,
  ContentMaxWidth,
} from '@tumelo/designsystem'
import { ExternalLinkWithTracking } from '../../../../buildingBlocks/ExternalLinkWithTracking'
import { Branding } from '../Branding'
import { cookiesPolicyUrl, privacyPolicyUrl, termsAndConditionsUrl } from '../../../../config/ExternalLinks'

export const PlatformFooter: React.FC = () => {
  const { colors } = useTheme()
  return (
    <StyledFooter>
      <StyledWrapper>
        <Top>
          <PlatformBranding>
            <Branding showBranding />
          </PlatformBranding>
          <TopLinks>
            <div>
              <ButtonText color={colors.white}>Product improvements</ButtonText>
              <IconLinkList>
                <li>
                  <StyledAnchor href="https://tumelo.zendesk.com/hc/en-us/requests/new">
                    <MessageIcon width="2rem" fill={colors.info.medium} style={{ marginRight: '0.5rem' }} />
                    <Caption semibold color={colors.info.medium}>
                      Feedback
                    </Caption>
                  </StyledAnchor>
                </li>
              </IconLinkList>
            </div>
            <SocialLinks>
              <ButtonText color={colors.white}>Follow us</ButtonText>
              <SocialLink>
                <ExternalLinkWithTracking to="https://www.instagram.com/tumelohq/">
                  <InstagramIcon fill={colors.info.medium} />
                </ExternalLinkWithTracking>
              </SocialLink>
              <SocialLink>
                <ExternalLinkWithTracking to="https://twitter.com/tumelohq">
                  <TwitterFillIcon fill={colors.info.medium} />
                </ExternalLinkWithTracking>
              </SocialLink>
              <SocialLink>
                <ExternalLinkWithTracking to="https://www.linkedin.com/company/tumelo/">
                  <LinkedinFillIcon fill={colors.info.medium} />
                </ExternalLinkWithTracking>
              </SocialLink>
            </SocialLinks>
          </TopLinks>
        </Top>

        <SmallPrint>
          <LinkList>
            <li>
              <Caption semibold color={colors.info.medium}>
                &copy; Tumelo
              </Caption>
            </li>
            <li>
              <Caption semibold color={colors.info.medium}>
                Runway East, Bristol, BS1 1BS
              </Caption>
            </li>
            <li>
              <Caption semibold color={colors.info.medium}>
                <StyledExternalLink to={termsAndConditionsUrl}>Terms & conditions</StyledExternalLink>
              </Caption>
            </li>
            <li>
              <Caption semibold color={colors.info.medium}>
                <StyledExternalLink to={privacyPolicyUrl}>Privacy policy</StyledExternalLink>
              </Caption>
            </li>
            <li>
              <Caption semibold color={colors.info.medium}>
                <StyledExternalLink to={cookiesPolicyUrl}>Cookies Policy</StyledExternalLink>
              </Caption>
            </li>
          </LinkList>
        </SmallPrint>
      </StyledWrapper>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background-color: ${(p) => p.theme.colors.background.dark};
  padding: 1.6rem 1.6rem 0.8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
`

const StyledWrapper = styled.div`
  max-width: ${ContentMaxWidth}px;
  width: 100%;
`

const PlatformBranding = styled.div`
  margin-bottom: 2.5rem;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${Breakpoint.mobile}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const SocialLinks = styled.div`
  @media (min-width: ${Breakpoint.mobile}) {
    margin-left: 4rem;
  }
`

const StyledExternalLink = styled(ExternalLinkWithTracking)`
  display: flex;
  align-items: center;
`

const StyledAnchor = styled.a`
  display: flex;
  align-items: center;
`

const SmallPrint = styled.section`
  border-top: 2px solid;
  border-color: ${(p) => p.theme.colors.primary.light};
  padding-top: 1.6rem;
  margin-top: 3.2rem;
  @media (min-width: ${Breakpoint.mobile}) {
    margin-top: 1rem;
  }
  max-width: 85rem;
`

const TopLinks = styled.div`
  flex-direction: column;
  @media (min-width: ${Breakpoint.mobile}) {
    flex-direction: row;
  }
  display: flex;
`

const SocialLink = styled.li`
  display: inline-block;
  margin-right: 2rem;
`

const LinkList = styled.ul`
  li p {
    margin-bottom: 1.6rem;
    display: flex;
  }
  li p a {
    color: inherit;
  }
  margin-bottom: 0.8rem;
  flex-direction: column;
  @media (min-width: ${Breakpoint.tablet}) {
    flex-direction: row;
    justify-content: space-between;
  }
  display: flex;
`

const IconLinkList = styled.ul`
  li p {
    margin-bottom: 0;
  }
  li {
    margin-bottom: 0.8rem;
  }
  margin-bottom: 2.4rem;
`
