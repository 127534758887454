import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgAuditsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#F3ECE8" }),
        React.createElement("path", { d: "M19.66 4.34C18.15 2.83 16.14 2 14 2C11.86 2 9.85 2.83 8.34 4.34C6.83 5.85 6 7.86 6 10C6 11.06 6.21 12.09 6.61 13.06C6.88 13.72 7.25 14.34 7.69 14.9L3.3 19.29C3.1 19.49 3.01 19.74 3.01 20C3.01 20.26 3.11 20.51 3.3 20.71C3.49 20.91 3.75 21 4.01 21C4.27 21 4.52 20.9 4.72 20.71L9.11 16.32C9.67 16.76 10.28 17.12 10.95 17.4C11.92 17.8 12.95 18.01 14.01 18.01C15.07 18.01 16.1 17.8 17.07 17.4C18.04 17 18.92 16.41 19.67 15.67C20.42 14.92 21 14.05 21.4 13.08C21.8 12.11 22.01 11.08 22.01 10.02C22.01 7.88 21.18 5.87 19.67 4.36L19.66 4.34ZM19.55 12.29C19.25 13.02 18.81 13.68 18.25 14.24C17.69 14.8 17.03 15.24 16.3 15.54C14.84 16.15 13.17 16.15 11.71 15.54C10.98 15.24 10.32 14.8 9.76 14.24C9.2 13.68 8.76 13.03 8.46 12.29C8.16 11.56 8 10.79 8 9.99C8 8.39 8.62 6.88 9.76 5.75C10.89 4.62 12.4 3.99 14 3.99C15.6 3.99 17.11 4.61 18.24 5.75C19.37 6.88 20 8.39 20 9.99C20 10.78 19.85 11.56 19.54 12.29H19.55Z", fill: "#894A24" })));
};
