/* eslint-disable deprecation/deprecation */
import * as React from 'react';
import styled from 'styled-components';
import { Breakpoint } from '../../styles';
import { OtherListItem } from '../OtherListItem';
import { OtherGroupHeader } from './OtherGroupHeader';
const MAX_WIDTH = '450';
const MIN_WIDTH = '200';
const MOB_WIDTH = '350';
export const OtherGroup = ({ totalPercentage }) => {
    return (React.createElement(OtherGroupContainer, null,
        React.createElement("div", { className: "dropdown-container" },
            React.createElement(OtherGroupHeader, { totalPercentage: totalPercentage }),
            React.createElement(CompanyListContainer, null,
                React.createElement(OtherListItem, { text: "This is the portion of your fund that's invested in things that aren't companies, like cash or items we haven't got full data on yet. As we get more information, expect this percentage to adjust." })))));
};
const OtherGroupContainer = styled.div `
  flex: 0 0 30%;
  max-width: ${MAX_WIDTH}px;
  min-width: ${MIN_WIDTH}px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 7rem 1rem;

  @media (max-width: ${Breakpoint.tablet}) {
    width: ${MOB_WIDTH} !important;
    flex: 100% !important;
  }

  @media (max-width: ${Breakpoint.mobile}) {
    margin: 1rem 1rem 3rem 1rem;
  }
`;
const CompanyListContainer = styled.div `
  flex: 0 0 30%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  max-width: ${MAX_WIDTH}px;
  min-width: ${MIN_WIDTH}px;

  @media (max-width: ${Breakpoint.tablet}) {
    width: ${MOB_WIDTH} !important;
    flex: 100% !important;
  }
`;
