import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgThermometersmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#EBF8EF" }),
        React.createElement("path", { d: "M8 5.50001H6.5V4.00001C6.5 3.45001 6.05 3.00001 5.5 3.00001C4.95 3.00001 4.5 3.45001 4.5 4.00001V5.50001H3C2.45 5.50001 2 5.95001 2 6.50001C2 7.05001 2.45 7.50001 3 7.50001H4.5V9.00001C4.5 9.55001 4.95 10 5.5 10C6.05 10 6.5 9.55001 6.5 9.00001V7.50001H8C8.55 7.50001 9 7.05001 9 6.50001C9 5.95001 8.55 5.50001 8 5.50001ZM19.68 14.65C19.35 13.72 18.76 12.89 18 12.26V5.50001C18 4.57001 17.64 3.69001 16.97 3.03001C15.65 1.71001 13.34 1.71001 12.02 3.03001C11.36 3.69001 10.99 4.57001 10.99 5.50001V12.26C10.23 12.89 9.64 13.72 9.31 14.65C8.92 15.75 8.89 16.98 9.23 18.1C9.57 19.22 10.28 20.22 11.22 20.92C12.16 21.62 13.32 22 14.5 22C15.68 22 16.83 21.62 17.78 20.92C18.73 20.22 19.43 19.22 19.77 18.1C20.11 16.98 20.08 15.75 19.69 14.65H19.68ZM17.85 17.52C17.63 18.23 17.18 18.87 16.59 19.31C15.38 20.21 13.63 20.21 12.42 19.31C11.82 18.86 11.37 18.23 11.16 17.52C10.94 16.81 10.96 16.03 11.21 15.32C11.46 14.61 11.94 14 12.56 13.59C12.84 13.4 13 13.09 13 12.76V5.50001C13 5.10001 13.16 4.72001 13.44 4.44001C13.72 4.16001 14.1 4.00001 14.5 4.00001C14.9 4.00001 15.28 4.16001 15.56 4.44001C15.84 4.72001 16 5.10001 16 5.50001V12.76C16 13.09 16.17 13.41 16.44 13.59C17.06 14.01 17.54 14.62 17.79 15.32C18.04 16.02 18.06 16.8 17.84 17.52H17.85Z", fill: "#37A155" })));
};
