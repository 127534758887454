/* eslint-disable deprecation/deprecation */
import React from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { Text, SubmitButton, Field } from '@tumelo/shared'
import { List, Body1 } from '@tumelo/designsystem'
import { ErrorContainer } from '../Components/ErrorContainer'
import { AuthSplitContainer } from '../Components/AuthSplitContainer'
import { AuthHeader } from '../Components/AuthHeader'

interface Props {
  submit: (details: ConfirmSmsOtpCodeValues) => Promise<void>
  goToSignIn?: () => void
  phoneNumber: string
  error: string | undefined
  pending: boolean
}

const signInSchema = yup.object({
  code: yup.string().required('Code required'),
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConfirmSmsOtpCodeValues extends yup.Asserts<typeof signInSchema> {}

export const ConfirmSmsOtpCodeForm: React.FC<Props> = ({ submit, goToSignIn, phoneNumber, error, pending }) => {
  const initialValues: ConfirmSmsOtpCodeValues = {
    code: '',
  }

  const onSubmit = async (values: ConfirmSmsOtpCodeValues) => {
    const code = values.code.trim()
    await submit({ code })
  }

  const thingsToCheck = [
    "Please check you've given us the correct phone number.",
    'If you request another code, make sure to use the latest one. The others will now be invalid.',
  ]
  return (
    <AuthSplitContainer>
      <ErrorContainer error={error} />
      <AuthHeader
        subHeading="Check your phone and enter the code below."
        heading={`We've sent you a text to ${phoneNumber}`}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => onSubmit(values)}
        validationSchema={signInSchema}
      >
        <Form>
          <Field
            name="code"
            label="Authentication code"
            required
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
          />
          <BottomContainer>
            <SubmitButtonContainer>
              <SubmitButton disabled={pending} label="Submit" />
            </SubmitButtonContainer>
            <hr />
            <Body1 as="h2">
              <strong>Having trouble with your code?</strong>
            </Body1>
            <Body1>It can take a few minutes to arrive so please hang on. In the meantime:</Body1>
            <List listArray={thingsToCheck} />
            {goToSignIn && (
              <SignInContainer>
                <Text>Didn&apos;t receive a code? </Text>
                <InlineButton role="button" onClick={() => goToSignIn()} onKeyDown={() => goToSignIn()} tabIndex={0}>
                  Try signing in again.
                </InlineButton>
              </SignInContainer>
            )}
          </BottomContainer>
        </Form>
      </Formik>
    </AuthSplitContainer>
  )
}

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 1rem 1rem 0;
`

const BottomContainer = styled.div`
  margin-top: 2rem;
`

const SignInContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  padding-top: 2rem;
`

const InlineButton = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary.light};
  &:hover {
    text-decoration: underline;
  }
`
