/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import { GridCard, CardHeader, CardContent, CardCTA, Body, SubHeading, SmallHeading, FontWeight } from '@tumelo/shared'
import { useRouter } from '../../application/router/useRouter'
import { SkeletonRows } from '../../buildingBlocks/Skeleton'

export interface Props {
  derivedAccountTitle: string
  otherFundsAvailable?: boolean
  isLoading?: boolean
}

export const AccountDetailsCard: React.FC<Props> = ({
  derivedAccountTitle,
  isLoading,
  otherFundsAvailable = false,
}) => {
  const { navigate } = useRouter()
  return (
    <GridCard columnSpan={3}>
      <CardHeader>
        <SubHeading mb={0}>Account details</SubHeading>
      </CardHeader>
      <CardContent>
        <Body m={0}>
          {isLoading ? (
            <SkeletonRows count={2} />
          ) : (
            <>
              We are currently showing you information on this platform about:{' '}
              <Body as="span" fontWeight={FontWeight.semiBold}>
                {derivedAccountTitle}
              </Body>
              .
            </>
          )}
        </Body>
        <Seperator />
        <SmallHeading as="h3">Is this still correct?</SmallHeading>
        <Body>
          If you have recently changed your pension fund(s) online, over the phone or in-person, you need to update this
          platform to see accurate data.
        </Body>
      </CardContent>
      {!isLoading && otherFundsAvailable && (
        <CardCTA
          callToAction="Update my fund information"
          onClick={() => navigate('/portfolio-selection/self-select/self-select-fund')}
        />
      )}
    </GridCard>
  )
}

const Seperator = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.grey.light};
  margin: 3rem 0 3rem 0;
`
