import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgPiechart = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M23 12C22.9976 10.1348 22.5209 8.30075 21.6148 6.6704C20.7086 5.04004 19.4028 3.66688 17.82 2.68L17.69 2.6C16.2384 1.71774 14.6022 1.18367 12.9096 1.03964C11.217 0.895606 9.51405 1.14553 7.93424 1.76983C6.35442 2.39413 4.94065 3.37583 3.80375 4.63798C2.66685 5.90014 1.83772 7.40845 1.38133 9.04469C0.92495 10.6809 0.853721 12.4006 1.17323 14.069C1.49274 15.7374 2.1943 17.3091 3.22293 18.661C4.25156 20.0128 5.5793 21.1081 7.10209 21.8609C8.62487 22.6137 10.3013 23.0036 12 23C14.41 23.0011 16.7523 22.2028 18.66 20.73H18.74C18.7442 20.7136 18.7442 20.6964 18.74 20.68C20.0643 19.6532 21.1365 18.3375 21.8748 16.8332C22.6131 15.3288 22.998 13.6757 23 12ZM18.28 18.48L17.52 17.48L19.93 16.28C19.4959 17.0935 18.9395 17.8355 18.28 18.48ZM13 3C13.651 3.06981 14.2919 3.21409 14.91 3.43L13 4.4V3ZM13 11L19.88 7.59C20.2109 8.16026 20.4727 8.76786 20.66 9.4L13.85 12.78L13 11.66V11ZM21 11.41C21 11.62 21 11.82 21 12.02C20.9965 12.5732 20.9463 13.1252 20.85 13.67L16.26 15.95L15.03 14.36L21 11.41ZM18.69 6L13 8.79V6.59L17.12 4.59C17.6996 4.99329 18.227 5.4669 18.69 6ZM3 12C2.98624 9.77661 3.79598 7.62683 5.27315 5.96502C6.75031 4.30321 8.79033 3.24701 11 3V12C11 12.2164 11.0702 12.4269 11.2 12.6L16.7 19.69C15.3348 20.526 13.7714 20.9827 12.1708 21.0131C10.5703 21.0435 8.99065 20.6464 7.59471 19.8629C6.19878 19.0793 5.03709 17.9376 4.22944 16.5555C3.42178 15.1734 2.99738 13.6008 3 12Z" })));
};
