/* eslint-disable deprecation/deprecation */
import React from 'react';
import styled from 'styled-components';
import { hexAlpha } from '../../utils';
import { Button } from '../Button';
import { Body, SubHeading } from '../Typography';
import { Box } from '../Box';
export const NoResultsMessage = ({ heading, body, button }) => {
    return (React.createElement(FlexCenter, null,
        React.createElement(BoxWithShadow, null,
            React.createElement(SubHeading, null, heading),
            React.createElement(Body, null, body),
            button && (React.createElement(ResetButtonWrapper, null,
                React.createElement(Button, { onClick: button.onClick }, button.text))))));
};
const BoxWithShadow = styled(Box) `
  box-shadow: 0 3px 12px 0 ${({ theme }) => hexAlpha(theme.colors.dark, 0.15)};
  max-width: 100%;
  text-align: center;
`;
const FlexCenter = styled.div `
  padding-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ResetButtonWrapper = styled.div `
  padding-top: 2rem;
`;
