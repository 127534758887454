import React, { ComponentProps } from 'react'
import { SingleQuestion } from '../SingleQuestion/SingleQuestion'
import { useAnalytics } from '../../application/analytics'
import { buildEvent } from '../../application/analytics/eventBuilders'

type Props = Omit<ComponentProps<typeof SingleQuestion>, 'onToggleReadMore'>

export const SingleQuestionWithAnalytics: React.FC<Props> = (props) => {
  const { readMoreVariant } = props
  const { trackEvent } = useAnalytics()

  const onToggleReadMore = (isReadMoreOpen: boolean) =>
    trackEvent(buildEvent.clickReadMoreButton(isReadMoreOpen, readMoreVariant))

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SingleQuestion {...props} onToggleReadMore={onToggleReadMore} />
}
