/* eslint-disable deprecation/deprecation */
import React, { useState, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
  Poll,
  Ballot,
  Breakpoint,
  Text,
  timestampToDate,
  Button,
  TypographyCaption,
  FontWeight,
  SocialShare,
} from '@tumelo/shared'
import { ChatIcon, Stack } from '@tumelo/designsystem'
import { useAppSelector } from '../../../application/store'
import { SingleQuestionStyles } from '../../SingleQuestion/SingleQuestion'
import { ResultsDue } from '../../ResultsDue/ResultsDue'
import { VoteCommentForm } from '../../VoteCommentForm'
import { useAnalytics } from '../../../application/analytics'
import { buildEvent } from '../../../application/analytics/eventBuilders'
import { VoteResultsNotificationModal } from '../../VoteResultsNotificationModal'
import { VoteResultsNotificationModalWithPushNotifications } from '../../VoteResultsNotificationModal/withPushNotifications'
import { selectSoftConfig } from '../../../application/features/config/selectors'

interface Props {
  poll: Poll
  ballot: Ballot
  onClick: () => void
}

export const PollCompleted: React.FC<Props> = ({ poll, onClick, ballot }) => {
  const { colors } = useContext(ThemeContext)
  const { trackEvent } = useAnalytics()
  const [showForm, setShowForm] = useState(false)
  const pushNotificationsEnabled = useAppSelector(
    (state) => selectSoftConfig(state).featureSwitches.enablePushNotifications
  )
  const toggleForm = () => {
    trackEvent(buildEvent.clickReasonToVote())
    setShowForm(!showForm)
  }
  const trackFacebook = () => {
    trackEvent(buildEvent.clickVoteSocialShare(poll.id, 'facebook'))
  }
  const trackTwitter = () => {
    trackEvent(buildEvent.clickVoteSocialShare(poll.id, 'twitter'))
  }

  return (
    <Container>
      <Title>Congratulations!</Title>
      {pushNotificationsEnabled ? (
        <VoteResultsNotificationModalWithPushNotifications />
      ) : (
        <VoteResultsNotificationModal />
      )}
      <Subtitle>Your vote preference has been submitted to your fund managers.</Subtitle>
      {(showForm || ballot.comment) && <VoteCommentForm ballot={ballot} />}
      <Stack flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}>
        {!showForm && !ballot.comment && (
          <VoteCommentFormButton>
            <Button
              icon={<ChatIcon fill={colors.secondary.medium} />}
              size="medium"
              inverse
              color={colors.dark}
              onClick={toggleForm}
            >
              Reason for voting?
            </Button>
          </VoteCommentFormButton>
        )}
        <SocialCTAs>
          <TypographyCaption m="0 1.2rem 0 0" fontWeight={FontWeight.bold}>
            Share that I&apos;ve voted:{' '}
          </TypographyCaption>
          <SocialShare
            faceBookShare={{
              url: 'https://www.tumelo.com/personal',
              text: `I just voted on "${poll.title}" using Tumelo. Find out how you can too at:`,
              onClick: () => trackFacebook(),
            }}
            twitterShare={{
              url: 'https://www.tumelo.com/personal#looking-to-sign-in',
              text: `I just voted on "${poll.title}" using @Tumelohq. Join in @tumelohq.`,
              onClick: () => trackTwitter(),
            }}
          />
        </SocialCTAs>
      </Stack>
      <Border />
      <Footer>
        <ResultsDue
          endDate={timestampToDate(poll.relationships.generalMeeting.date)}
          resultsText="The final result will be available after the company AGM."
        />
        <Column>
          <Button size="medium" color={colors.dark} inverse onClick={onClick}>
            See all &apos;Open votes&apos;
          </Button>
        </Column>
      </Footer>
    </Container>
  )
}

const { MARGIN, MAX_WIDTH } = SingleQuestionStyles

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${Breakpoint.mobile}) {
    margin: ${MARGIN}rem 0 0 0;
  }
`

const VoteCommentFormButton = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 2.5rem;
`

const SocialCTAs = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${Breakpoint.mobile}) {
    margin-top;
  }
`

const Title = styled(Text)`
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const Border = styled.div`
  margin: ${MARGIN}rem 0;
  margin-top: 3rem;
  border-bottom: 0.1rem ${({ theme }) => theme.colors.grey.light} solid;
`

const Footer = styled.div`
  display: flex;
  max-width: ${MAX_WIDTH}px;
  justify-content: space-between;

  @media (max-width: ${Breakpoint.tablet}) {
    flex-direction: column;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Subtitle = styled(Text)`
  margin-bottom: 3rem;
`
