/* eslint-disable camelcase */
import { samplePoll, timestampToDate } from '@tumelo/shared'
import {
  VoteResult as VoteResultProto,
  VoteResult_ProposalOutcome,
} from '../../../utils/proto/tumelo/dashboardbff/v1/vote_result'

export const mapVoteResultOutcome = (
  outcome: VoteResult_ProposalOutcome
): 'for' | 'against' | 'withdrawn' | undefined => {
  switch (outcome) {
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_FOR:
      return 'for'
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_AGAINST:
      return 'against'
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_WITHDRAWN:
      return 'withdrawn'
    default:
      return undefined
  }
}

export type VoteResult = Omit<VoteResultProto, 'pollCloseTime'>

export const sampleVoteResult = (): VoteResultProto => {
  const poll = samplePoll()

  return {
    pollId: poll.id,
    pollTitle: poll.title,
    pollTagIds: poll.tags.map((tag) => tag.id),
    pollCloseTime: timestampToDate(poll.endDate),
    proposalOutcome: VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_FOR,
    numberOfVotesPlaced:
      poll.tally.forCount +
      poll.tally.againstCount +
      poll.tally.withholdCount +
      poll.tally.noActionCount +
      poll.tally.abstainCount,
    investorVoted: true,
    organization: {
      displayName: poll.relationships.organization.title,
      logoUrl: poll.relationships.organization.logoUrl ?? '',
      naics2017Code: poll.relationships.organization.naicsCode ?? '',
    },
  }
}
