/* eslint-disable deprecation/deprecation */
import React, { FunctionComponent } from 'react'
import { Text, Title } from '@tumelo/shared'
import { VimeoModal } from '../../buildingBlocks/VimeoModal'

interface Props {
  open: boolean
  handleModalClose: () => void
}

export const SeeWhereYourVoteGoesModal: FunctionComponent<Props> = ({ open, handleModalClose }) => {
  return (
    <VimeoModal
      title="Fund manager explained in 60 seconds"
      video={{ vimeoID: 497666992, title: 'See where your vote goes' }}
      open={open}
      handleModalClose={handleModalClose}
      page="seeWhereYourVoteGoesModal"
      body={
        <>
          <Title>Watch this short video to understand:</Title>
          <div>
            <Text>- What happens to your vote preference now you&apos;ve placed it.</Text>
          </div>
          <div>
            <Text>- Who your fund manager is and why they&apos;re important.</Text>
          </div>
        </>
      }
    />
  )
}
