import { Analytics } from './Analytics'

export const AnalyticsConsoleLogger: Analytics = {
  createIdentity: (userId) => l('createIdentity', userId),
  setUserProperties: (userId, props) => l('setUserProperties', userId, props),
  pageView: (page) => l('pageView', page),
  registerExternalLinkClickListener: () => l('registerExternalLinkClickListener'),
  signOutUser: () => l('signOutUser'),
  trackEvent: ({ name, props }) => l('trackEvent', name, props),
}

// eslint-disable-next-line no-console
const l = (name: string, ...inputs: any[]) => console.log('analyticsConsoleLogger', name, inputs)
