import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { AlertIcon, Stack, VisuallyHidden, screen } from '..';
import { HintText, Label } from '../Form';
// TODO: Add InputGroup component to hold shared spacers and events for more than one input in the form
export const Input = ({ id, label, value, hintText, errorText, placeholder, autoComplete, type, mb, onChange, onFocus, onKeyDown, hideLabel = false, hasError = false, required = false, iconBefore, iconAfter, className, readOnly, }) => {
    const { colors } = useTheme();
    return (React.createElement(StyledContainer, { mb: mb },
        !hideLabel && React.createElement(Label, { label: label, id: id, hasError: hasError, required: required }),
        hideLabel && (React.createElement(VisuallyHidden, null,
            React.createElement("label", { htmlFor: id }, label))),
        (hasError || hintText) && React.createElement(HintText, { isError: hasError !== null && hasError !== void 0 ? hasError : false }, hasError ? errorText : hintText),
        iconBefore && React.createElement(StyledIconBeforeSlot, null, iconBefore),
        React.createElement(StyledInput, { id: id, onChange: onChange, onFocus: onFocus, onKeyDown: onKeyDown, value: value, hasError: hasError, type: type, autoComplete: autoComplete, placeholder: placeholder, "aria-required": required !== null && required !== void 0 ? required : false, iconBefore: iconBefore, className: className, readOnly: readOnly }),
        React.createElement(StyledIconSlot, { inputHasIconAfter: Boolean(iconAfter) },
            React.createElement(Stack, { flexDirection: "row" }, hasError && React.createElement(AlertIcon, { fill: colors.danger.dark }))),
        iconAfter && React.createElement(StyledIconAfterSlot, null, iconAfter)));
};
const StyledContainer = styled.div `
  position: relative;
  margin-top: 0.8rem; // to cover for label sticking out
  margin-bottom: ${(p) => p.mb || '0.8rem'};
  line-height: 1.15;
`;
const StyledInput = styled.input `
  width: 100%;
  height: 5.2rem;
  border-radius: 0.5rem;
  padding: ${({ iconBefore }) => (iconBefore ? '1.6rem 3.2rem 1.6rem 5.4rem' : '1.6rem 3.2rem 1.6rem 1.6rem')};
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSizes.body2.mobile};
  @media ${screen.mediumUp} {
    font-size: ${({ theme }) => theme.fontSizes.body2.default};
  }
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  border: 1px solid ${({ hasError, theme }) => (hasError ? theme.colors.secondary.dark : theme.colors.grey.medium)};
  background-color: #fff;
  &:hover {
    border: 1px solid ${({ hasError, theme }) => (hasError ? theme.colors.danger.dark : theme.colors.grey.dark)};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey.medium};
  }
  &:active,
  &:focus {
    outline: none;
    border: 1px solid #000;
    box-shadow: 0 0 0px 1px #000;
    transition: box-shadow 0.1s ease-in-out;
  }
`;
const StyledIconSlot = styled.div `
  position: absolute;
  right: ${({ inputHasIconAfter }) => (inputHasIconAfter ? '5rem' : '1.6rem')};
  top: 50%;
  transform: translate(0, -50%);
`;
const StyledIconBeforeSlot = styled.div `
  svg {
    position: absolute;
    left: 1.8rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    fill: ${({ theme }) => theme.colors.dark};
  }
`;
const StyledIconAfterSlot = styled.div `
  svg {
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    fill: ${({ theme }) => theme.colors.dark};
  }
`;
