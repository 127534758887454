/* eslint-disable deprecation/deprecation */
import React, { useState } from 'react'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'
import useResizeObserver from 'use-resize-observer/polyfilled'
import { isPollOpen, Poll, Card, CardContent, CardImage, Button, SubHeading, TypographyCaption } from '@tumelo/shared'
import { useBreakpoints } from '@tumelo/shared/utils'
import { Link } from '../../application/router/Link'
import { useAnalytics } from '../../application/analytics'
import { DaysRemaining } from '../Cardlet/CardletBottoms/DaysRemaining'
import { buildEvent } from '../../application/analytics/eventBuilders'

interface Props {
  poll: Poll
  teaser: string
  image: string // base64encoded / url
  altText: string
}

export const HotTopicCard: React.FC<Props> = ({ poll, teaser, image, altText }) => {
  const { trackEvent } = useAnalytics()
  const { isMobile } = useBreakpoints()
  const [width, setWidth] = useState<number>()

  const onResize = debounce(100, ({ width }: { width?: number }) => {
    setWidth(width)
  })

  const displaySmallCard: boolean = isMobile || (width && width < 600) || false

  const cardWidth = displaySmallCard ? '32rem' : '100%'
  const cardImage: React.ReactNode = displaySmallCard ? (
    <CardImage height={160} src={image} alt={altText} />
  ) : (
    <FeatureImage image={image} />
  )

  const { ref } = useResizeObserver<HTMLDivElement>({ onResize })

  const handleClick = () => {
    const status = isPollOpen(poll) ? 'open' : 'closed'
    trackEvent(buildEvent.openPoll(poll.id, 'Hot Topic', status))
  }

  return (
    <Parent ref={ref}>
      <StyledLink key={poll.id} to={`/votes/open/${poll.id}`} onClick={handleClick} title={poll.title}>
        <StyledCard width={cardWidth}>
          <Container displaySmallCard={displaySmallCard}>
            {cardImage}
            <Content>
              <HotTopicCardContent displaySmallCard={displaySmallCard}>
                <SubHeading as="h3">{poll.title}</SubHeading>
                <TypographyCaption as="p">{teaser}</TypographyCaption>
                <Footer displaySmallCard={displaySmallCard}>
                  <DaysRemaining poll={poll} showVoteCount={(width && width > 900) || false} />
                  <Button size="medium" type="pseudo">
                    Vote now
                  </Button>
                </Footer>
              </HotTopicCardContent>
            </Content>
          </Container>
        </StyledCard>
      </StyledLink>
    </Parent>
  )
}

interface StyledProps {
  displaySmallCard: boolean
}

const StyledLink = styled(Link)`
  flex: 1;
  max-width: 100rem;
  display: flex;
`
const Parent = styled.span`
  width: 100%;
`

const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.background.light};
`

const Container = styled.div<StyledProps>`
  flex-direction: ${({ displaySmallCard }) => (displaySmallCard ? 'column' : 'row')};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 28rem;
  &:hover {
    background: ${({ theme }) => theme.colors.background.medium};
  }
`
const Footer = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ displaySmallCard }) => (displaySmallCard ? 'center' : 'flex-end')};
  p {
    margin: 0;
  }
`

interface FeatureImageProps {
  image: string
}

const FeatureImage = styled.div<FeatureImageProps>`
  max-width: 46rem;
  flex: 46;
  background-image: ${(props) => `url('${props.image}')`}, ${(props) => `url('${props.image}')`};
  background-size: contain, 100000px;
  background-repeat: no-repeat, no-repeat;
  background-position: center, top;
  border-radius: 2rem 0 0 2rem;
`

const Content = styled.div`
  height: 100%;
  flex: 54;
`

const HotTopicCardContent = styled(CardContent)<StyledProps>`
  margin: ${({ displaySmallCard }) => (displaySmallCard ? '2rem 2rem 2.5rem !important' : '4rem')};
`
