import { Investor, TumeloAccount } from '@tumelo/shared'
import { useEffect } from 'react'
import { useNavigateWithQuery } from '../../../utils/useNavigateWithQuery'
import { isErrorStates, isIntermediaryState, isValueState, Payload } from '../../payload'
import { useAppSelector } from '../../store'
import { useAccount } from '../account/hooks'
import { selectPortfolioSelectionFlowType } from '../config/selectors'
import { useInvestor } from '../investor/useInvestor'

interface DashboardAccount {
  investor: Investor
  account: TumeloAccount | 'not-configured'
}

export const useDashboardAccount = (): Payload<DashboardAccount> => {
  const { investor } = useInvestor()
  const account = useAccount(investor)

  if (isIntermediaryState(investor) || isIntermediaryState(account)) {
    return 'pending'
  }
  if (isErrorStates(investor) || isErrorStates(account)) {
    return 'error'
  }

  return { investor, account }
}

// Checks if the users account has been configured with a fund
// If no account has yet been configured, user is navigated to portfolio selection screen.
// Returns "DashboardAccount" (investor + account) if account is setup
export const useRequireDashboardAccountSetup = (): Payload<DashboardAccount> => {
  const dashboardAccount = useDashboardAccount()
  const navigate = useNavigateWithQuery()
  const selectionFlowType = useAppSelector(selectPortfolioSelectionFlowType)
  const redirectUrl = {
    buck: '/portfolio-selection/error', // Fund is already selected unless something has gone wrong
    'self-select': '/portfolio-selection/self-select/has-fund-changed',
    'self-select-form': '/portfolio-selection/self-select/self-select-fund',
  }[selectionFlowType]

  useEffect(() => {
    if (isValueState(dashboardAccount)) {
      const { account } = dashboardAccount
      if (account === 'not-configured') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(redirectUrl)
      }
    }
  }, [dashboardAccount, navigate, redirectUrl])

  return dashboardAccount
}
