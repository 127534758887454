import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgLobbyingsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#FBE5EC" }),
        React.createElement("path", { d: "M21.001 11.4879C21.5504 11.4879 22 11.0317 22 10.4742V8.832C22 8.48734 21.8302 8.16295 21.5405 7.98049L12.6294 2.22265C12.6294 2.22265 12.5395 2.15169 12.4795 2.12128C12.3696 2.06046 12.2497 2.01991 12.1299 2.00977C11.9201 1.97936 11.7003 2.01991 11.5105 2.13142C11.4605 2.16183 11.4106 2.19224 11.3706 2.22265L2.45954 7.97035C2.16983 8.15282 2 8.4772 2 8.82186V10.4641C2 11.0216 2.44955 11.4778 2.999 11.4778H4.26773V16.0496C4.13786 16.1002 4.01798 16.1712 3.91808 16.2726L2.30969 17.9046C2.11988 18.0972 2.01998 18.3507 2.01998 18.6244V20.9863C2.01998 21.5438 2.46953 22 3.01898 22H21.001C21.5504 22 22 21.5438 22 20.9863V18.6244C22 18.3507 21.8901 18.0972 21.7103 17.9046L20.1019 16.2726C20.002 16.1712 19.8821 16.1002 19.7522 16.0496V11.4778H21.001V11.4879ZM3.998 9.38953L12 4.21965L20.002 9.38953V9.46049H3.998V9.38953ZM10.7612 15.9887V11.4879H13.2587V15.9887H10.7612ZM8.76324 11.4879V15.9887H6.26573V11.4879H8.76324ZM20.002 19.9827H4.01798V19.0603L5.04695 18.0263H18.983L20.002 19.0603V19.9827ZM17.7542 15.9887H15.2567V11.4879H17.7542V15.9887Z", fill: "#D30245" })));
};
