import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgLobbying = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#FBE5EC" }),
        React.createElement("path", { d: "M35 21.55H30V19.55C30 19.08 29.67 18.68 29.22 18.57L26 17.86V14.55C26 11.79 23.76 9.55002 21 9.55002V8.73002H25.75C26.3 8.73002 26.75 8.28002 26.75 7.73002V4.46002C26.75 3.91002 26.3 3.46002 25.75 3.46002H20C19.45 3.46002 19 3.91002 19 4.46002V9.56002C16.24 9.56002 14 11.8 14 14.56V17.87L10.78 18.58C10.32 18.68 10 19.09 10 19.56V21.56H5C4.45 21.56 4 22.01 4 22.56C4 23.11 4.45 23.56 5 23.56V35.56C5 36.11 5.45 36.56 6 36.56H34C34.55 36.56 35 36.11 35 35.56V23.56C35.55 23.56 36 23.11 36 22.56C36 22.01 35.55 21.56 35 21.56V21.55ZM21 5.45002H24.75V6.72002H21V5.45002ZM19 11.55H21C22.3 11.55 23.42 12.39 23.83 13.55H16.17C16.58 12.39 17.7 11.55 19 11.55ZM16 15.55H24V17.41L20.22 16.57C20.08 16.54 19.93 16.54 19.79 16.57L16.01 17.41V15.55H16ZM10 34.55H7V23.55H7.84V25.44C7.84 25.99 8.29 26.44 8.84 26.44H10V34.55ZM17 34.55H16V26.44H17V34.55ZM24 34.55H23V26.44H24V34.55ZM28 25.44V34.55H26V25.44C26 24.89 25.55 24.44 25 24.44H22C21.45 24.44 21 24.89 21 25.44V34.55H19V25.44C19 24.89 18.55 24.44 18 24.44H15C14.45 24.44 14 24.89 14 25.44V34.55H12V25.44C12 24.89 11.55 24.44 11 24.44H9.84V23.6H11C11.55 23.6 12 23.15 12 22.6V20.35L20 18.57L28 20.35V22.6C28 23.15 28.45 23.6 29 23.6H30.16V24.44H29C28.45 24.44 28 24.89 28 25.44ZM33 34.55H30V26.44H31.16C31.71 26.44 32.16 25.99 32.16 25.44V23.55H33V34.55Z", fill: "#D30245" })));
};
