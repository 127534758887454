import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgSearch = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 11C3.99361 9.85025 4.27054 8.71662 4.80631 7.6993C5.34207 6.68199 6.12018 5.81231 7.07187 5.16711C8.02355 4.52192 9.11951 4.12109 10.2629 4.00004C11.4063 3.87898 12.5619 4.04144 13.6276 4.47304C14.6933 4.90465 15.6362 5.59211 16.3731 6.47468C17.1101 7.35726 17.6182 8.40776 17.8527 9.53337C18.0873 10.659 18.0409 11.825 17.7178 12.9284C17.3946 14.0319 16.8047 15.0387 16 15.86L15.9 15.94L15.82 16.04C14.8294 16.9806 13.5853 17.6108 12.241 17.853C10.8967 18.0952 9.51083 17.9388 8.25431 17.403C6.99779 16.8672 5.92548 15.9755 5.16953 14.8378C4.41359 13.7 4.00704 12.366 4 11ZM16.62 18C14.8251 19.4419 12.5458 20.1411 10.2511 19.9537C7.95635 19.7663 5.8207 18.7066 4.28347 16.9926C2.74624 15.2786 1.92435 13.0407 1.98687 10.7392C2.0494 8.43764 2.99158 6.24761 4.61959 4.6196C6.24761 2.99158 8.43764 2.0494 10.7392 1.98688C13.0407 1.92435 15.2786 2.74625 16.9926 4.28347C18.7066 5.8207 19.7663 7.95635 19.9537 10.2511C20.1411 12.5458 19.4419 14.8251 18 16.62L21.68 20.29C21.8683 20.4783 21.9741 20.7337 21.9741 21C21.9741 21.2663 21.8683 21.5217 21.68 21.71C21.4917 21.8983 21.2363 22.0041 20.97 22.0041C20.7037 22.0041 20.4483 21.8983 20.26 21.71L16.62 18Z" })));
};
