/* eslint-disable deprecation/deprecation */
import * as React from 'react'
import { isPollOpen } from '@tumelo/shared'
import { BallotWithPollAndOrg } from '../../application/types/PollWithOrganization/PollWithOrganization'
import { useRouter } from '../../application/router/useRouter'
import { ResultsDue } from './CardletBottoms/ResultsDue'
import { SeeResults } from './CardletBottoms/SeeResults'
import { OpenPoll } from './CardletBottoms/OpenPoll'
import { BaseCardlet, CardletBottom, CardletColumn, CardletRow, CardletTop, PollTitle } from './BaseCardlet'
import { OrganizationGroup } from './OrganizationGroup'
import { Tags } from './Tags'

interface Props {
  poll: BallotWithPollAndOrg
  navigateTo: 'openVote' | 'voteResult'
}

export const PollCardlet: React.FC<Props> = ({ poll: { poll, organization }, navigateTo }) => {
  const href = navigateTo === 'voteResult' ? `/votes/results/${poll.id}` : `/votes/open/${poll.id}`
  const { navigate } = useRouter()
  const handleCardletClick = () => {
    navigate(href)
  }

  const pollIsOpen = isPollOpen(poll)

  const Bottom = (): React.ReactNode => {
    // Open votes
    if (pollIsOpen) {
      return (
        <CardletBottom data-testid="cardlet-bottom">
          <OpenPoll poll={poll} />
        </CardletBottom>
      )
    }

    // Vote with no result
    if (poll.relationships.proposal.outcome === undefined) {
      return (
        <CardletBottom displayShadow={false}>
          <ResultsDue poll={poll} />
        </CardletBottom>
      )
    }

    // Vote Results
    return (
      <CardletBottom>
        <SeeResults poll={poll} />
      </CardletBottom>
    )
  }

  const Top = (): React.ReactNode => {
    return (
      <CardletTop>
        <CardletRow>
          <OrganizationGroup title={organization.title} logoUrl={organization.logoUrl} />
          <Tags tagIds={poll.tags.map((t) => t.id)} />
        </CardletRow>
        <CardletColumn>
          <PollTitle to={href}>{poll.title}</PollTitle>
        </CardletColumn>
      </CardletTop>
    )
  }

  return (
    <BaseCardlet onClick={handleCardletClick}>
      {Top()}
      {Bottom()}
    </BaseCardlet>
  )
}
