/* eslint-disable deprecation/deprecation */
import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { SmallHeading } from '../Typography';
import { buildColSpans, GridCardContainer } from './GridCard';
export const MiniGridCard = ({ title, backgroundColor, onClick, children, }) => {
    const { colors } = React.useContext(ThemeContext);
    const background = backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : colors.white;
    return (React.createElement(MiniGridCardContainer, { columnSpans: buildColSpans(2), backgroundColor: background, clickable: onClick != null, stretchY: true },
        React.createElement(MiniGridCardContentContainer, { role: "button", onClick: onClick, clickable: onClick != null },
            title && React.createElement(MiniCardGridHeading, null, title),
            React.createElement(MiniCardGridBody, null, children))));
};
const MiniGridCardContainer = styled(GridCardContainer) `
  height: 24rem;
  overflow-y: hidden;
`;
const MiniGridCardContentContainer = styled.div `
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
`;
const MiniCardGridHeading = styled(SmallHeading) `
  font-size: 2rem;
  margin: 2rem 2rem 0 2rem;
  min-height: 3rem;
`;
const MiniCardGridBody = styled.div `
  height: 100%;
  margin: 2rem;
`;
