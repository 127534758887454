import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { Field, Modal } from '@tumelo/shared'
import { Button } from '@tumelo/designsystem'
import { useAppDispatch, useAppSelector } from '../../application/store'
import { updateInvestorNickname } from '../../application/features/investor/asyncActions'
import { ErrorContainer } from '../Auth/Components/ErrorContainer'

interface Props {
  open: boolean
  handleModalClose: () => void
}

const UpdateNicknameSchema = yup.object({
  nickname: yup.string().required('Nickname is required'),
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateNicknameFormValues extends yup.Asserts<typeof UpdateNicknameSchema> {}

export const UpdateNicknameFormModal: React.FC<Props> = ({ open, handleModalClose }) => {
  const initialValues: UpdateNicknameFormValues = {
    nickname: '',
  }

  const dispatch = useAppDispatch()
  const [hasSubmit, setHasSubmit] = useState<boolean>(false)

  const nicknameIsUpdating = useAppSelector((state) => state.investor.updateInvestor.nicknameUpdateState)

  useEffect(() => {
    if (hasSubmit && nicknameIsUpdating === 'not-updating') {
      handleModalClose()
      setHasSubmit(false)
    }
  }, [nicknameIsUpdating, hasSubmit, handleModalClose])

  return (
    <Modal closeIcon title="Preferred name or nickname" open={open} onClose={handleModalClose} width="medium">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          dispatch(updateInvestorNickname(values.nickname))
          setHasSubmit(true)
        }}
        validationSchema={UpdateNicknameSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form>
          {nicknameIsUpdating === 'error' && (
            <Margin>
              <ErrorContainer error="There was an error updating your preferred name or nickname, please try again later." />
            </Margin>
          )}
          <Field
            name="nickname"
            type="text"
            label="New preferred name or nickname"
            required
            showValidationErrorMessages
          />
          <ButtonContainer>
            <Button isDisabled={nicknameIsUpdating === 'pending'} type="submit" label="Submit" />
          </ButtonContainer>
        </Form>
      </Formik>
    </Modal>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`

const Margin = styled.div`
  margin-bottom: 2rem;
`
