import {
  sampleTumeloAccount,
  TumeloAccount,
  Composition,
  sampleComposition,
  OrganizationBreakdown,
  sampleOrganizationBreakdown,
  VotingPolicySelection,
  sampleVotingPolicySelection,
} from '@tumelo/shared'
import { sleep } from '@tumelo/shared/utils'
import { AccountService } from './AccountService'

export const AccountServiceMock: AccountService = {
  async getAccount(): Promise<TumeloAccount | undefined> {
    await sleep()
    return sampleTumeloAccount()
  },
  async getAccountComposition(): Promise<{ composition: Composition; noReferenceAndNoIsinInstrumentWeight: number }> {
    await sleep()
    return { composition: sampleComposition(), noReferenceAndNoIsinInstrumentWeight: 0 }
  },
  async createAccountWithPortfolioId(): Promise<TumeloAccount> {
    await sleep()
    return sampleTumeloAccount()
  },
  async getAccountOrganizationEquityBreakdown(): Promise<OrganizationBreakdown> {
    await sleep(500)
    return sampleOrganizationBreakdown()
  },
  async deleteVotingPolicySelection(account: TumeloAccount, votingPolicySelectionId: string): Promise<void> {
    // eslint-disable-next-line no-console
    console.log(`deleteVotingPolicySelection() called with votingPolicySelectionId ${votingPolicySelectionId}`)
  },
  async getVotingPolicySelection(): Promise<VotingPolicySelection> {
    await sleep(500)
    return sampleVotingPolicySelection
  },
  async createVotingPolicySelection(): Promise<VotingPolicySelection> {
    await sleep(500)
    return sampleVotingPolicySelection
  },
}
