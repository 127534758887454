/* eslint-disable deprecation/deprecation */
import { BreakpointAsNumbers } from '../../styles';
import { useWindowWidth } from '../useWindowWidth';
export const useBreakpoints = () => {
    const currentWindowSize = useWindowWidth();
    const isMobile = BreakpointAsNumbers.mobile > currentWindowSize;
    const isTabletOrSmaller = BreakpointAsNumbers.tablet > currentWindowSize;
    const isDesktopOrSmaller = BreakpointAsNumbers.desktop > currentWindowSize; // TODO: Change the name to reflect functionality (isSmallerThanDesktop or sth, attn: is used in other projects.)
    const isDesktopBreakpointOrSmaller = BreakpointAsNumbers.desktop >= currentWindowSize;
    const isDesktopOrHigher = BreakpointAsNumbers.desktop <= currentWindowSize;
    return { isMobile, isTabletOrSmaller, isDesktopOrSmaller, isDesktopBreakpointOrSmaller, isDesktopOrHigher };
};
