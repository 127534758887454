import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgSave = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V19.06L11.42 15.19C11.5894 15.0694 11.7921 15.0046 12 15.0046C12.2079 15.0046 12.4106 15.0694 12.58 15.19L18 19.06V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7ZM4.88 2.88C5.44207 2.31723 6.20462 2.0007 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21C19.9992 21.1832 19.948 21.3627 19.8521 21.5188C19.7562 21.6749 19.6193 21.8017 19.4562 21.8853C19.2932 21.9689 19.1103 22.006 18.9276 21.9928C18.7448 21.9795 18.5693 21.9163 18.42 21.81L12 17.23L5.58 21.81C5.43075 21.9163 5.25517 21.9795 5.07244 21.9928C4.8897 22.006 4.70683 21.9689 4.54379 21.8853C4.38075 21.8017 4.2438 21.6749 4.1479 21.5188C4.05201 21.3627 4.00084 21.1832 4 21V5C4.0007 4.20462 4.31723 3.44207 4.88 2.88Z" })));
};
