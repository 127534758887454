import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgNologo = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#EEEEEE" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 15.6C7 10.8504 10.8504 7 15.6 7C20.3496 7 24.2 10.8504 24.2 15.6C24.2 20.3496 20.3496 24.2 15.6 24.2C10.8504 24.2 7 20.3496 7 15.6ZM15.6 5C9.74578 5 5 9.74578 5 15.6C5 21.117 9.21475 25.6495 14.6 26.1534V35H35V14.6H26.1534C25.6495 9.21474 21.117 5 15.6 5ZM16.6 26.1534C21.6561 25.6803 25.6804 21.656 26.1535 16.6H33V33H16.6V26.1534Z" })));
};
