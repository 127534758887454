import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgCount3 = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.00001 12C3.00001 10.22 3.52785 8.47991 4.51678 6.99987C5.50571 5.51983 6.91132 4.36627 8.55585 3.68508C10.2004 3.0039 12.01 2.82567 13.7558 3.17293C15.5016 3.5202 17.1053 4.37737 18.364 5.63604C19.6226 6.89471 20.4798 8.49836 20.8271 10.2442C21.1743 11.99 20.9961 13.7996 20.3149 15.4442C19.6337 17.0887 18.4802 18.4943 17.0001 19.4832C15.5201 20.4722 13.78 21 12 21C9.61306 21 7.32387 20.0518 5.63604 18.364C3.94822 16.6761 3.00001 14.3869 3.00001 12ZM12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM15.1 12.16C14.5716 11.602 13.8635 11.248 13.1 11.16L15.32 8.64V7.2H8.48V9H12.65L10.65 11.28V12.78H11.65C12.87 12.78 13.48 13.18 13.48 13.98C13.488 14.1659 13.447 14.3506 13.3609 14.5157C13.2749 14.6807 13.147 14.8201 12.99 14.92C12.5853 15.1581 12.1186 15.2696 11.65 15.24C11.1442 15.2342 10.6421 15.1533 10.16 15C9.6962 14.8535 9.26018 14.6304 8.87 14.34L8 16.12C8.5127 16.478 9.08493 16.7421 9.69 16.9C10.3422 17.0759 11.0145 17.1667 11.69 17.17C12.4837 17.2038 13.2744 17.0532 14 16.73C14.56 16.4905 15.0391 16.0947 15.38 15.59C15.655 15.1061 15.7933 14.5565 15.78 14C15.8034 13.3212 15.5592 12.6605 15.1 12.16Z" })));
};
