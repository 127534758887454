import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgEdit = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.86 0.81L22.78 2.73H22.77C23.86 3.82 23.86 5.58 22.77 6.67L21.08 8.36L12.24 17.2C12.23 17.21 12.21 17.22 12.21 17.22C12.14 17.29 12.05 17.34 11.95 17.39C11.9445 17.3927 11.939 17.3963 11.9333 17.3999C11.9182 17.4095 11.9018 17.42 11.88 17.42C11.86 17.42 11.83 17.44 11.83 17.44L5.41 19.42C5.32 19.45 5.22 19.46 5.12 19.46C4.86 19.46 4.6 19.36 4.41 19.17C4.15 18.91 4.05 18.52 4.16 18.17L6.14 11.75C6.14 11.74 6.145 11.7325 6.15 11.725C6.155 11.7175 6.16 11.71 6.16 11.7C6.165 11.69 6.17 11.6775 6.175 11.665C6.18 11.6525 6.185 11.64 6.19 11.63C6.23 11.54 6.29 11.45 6.36 11.37C6.37 11.36 6.38 11.34 6.38 11.34L15.23 2.5L16.92 0.81C18.01 -0.27 19.77 -0.27 20.86 0.81ZM7.55 13.92L6.62 16.95L9.65 16.02L7.55 13.92ZM8.5 12.04L11.53 15.07L18.96 7.64L15.93 4.61L8.5 12.04ZM20.38 6.23L21.36 5.25C21.67 4.94 21.67 4.45 21.36 4.14L19.44 2.22C19.13 1.92 18.64 1.91 18.33 2.22L17.35 3.2L20.38 6.23ZM18.79 12.01C18.79 11.46 19.24 11.01 19.79 11.01C20.34 11.01 20.79 11.46 20.79 12.01V22.58C20.79 23.13 20.34 23.58 19.79 23.58H2C1.45 23.58 1 23.13 1 22.58V5.34C1 4.79 1.45 4.34 2 4.34H9.84C10.39 4.34 10.84 4.79 10.84 5.34C10.84 5.89 10.39 6.34 9.84 6.34H3V21.58H18.79V12.01Z" })));
};
