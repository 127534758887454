/* eslint-disable deprecation/deprecation */
import * as React from 'react'
import { Body, Breakpoint, Button, FontWeight, Modal, Span } from '@tumelo/shared'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../application/store'
import { selectUserPreferences } from '../../application/features/userPreferences/selector'
import {
  fetchUserPreferences,
  setSubscribedToPushNotifications,
} from '../../application/features/userPreferences/asyncActions'
import { PayloadUnwrapperM } from '../Error/PayloadUnwrapper'
import { buildEvent } from '../../application/analytics/eventBuilders'
import { useAnalytics } from '../../application/analytics'
import { useRegistration, useSubscription, useVapidKey } from '../../application/features/pushSubscriptions/hooks'
import { CreatePushSubscription } from '../../application/features/pushSubscriptions/asyncActions'

export const VoteResultsNotificationModalWithPushNotifications: React.FC = () => {
  const applicationServerKey = useVapidKey()
  const registration = useRegistration()
  const subscription = useSubscription()

  const userPreferences = useAppSelector(selectUserPreferences)

  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (userPreferences === 'not-initialised') {
      dispatch(fetchUserPreferences())
    }
  }, [dispatch, userPreferences])

  const { trackEvent } = useAnalytics()

  const handleAccept = async (key: Uint8Array, reg: ServiceWorkerRegistration) => {
    dispatch(setSubscribedToPushNotifications(true))
    const sub = await reg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: key,
    })

    dispatch(CreatePushSubscription(sub))
    trackEvent(buildEvent.respondToVoteResultNotificationsModal('accept'))
  }

  const handleReject = () => {
    dispatch(setSubscribedToPushNotifications(false))
    trackEvent(buildEvent.respondToVoteResultNotificationsModal('reject'))
  }

  const handleClose = () => {
    trackEvent(buildEvent.respondToVoteResultNotificationsModal('close'))
    setIsOpen(false)
  }

  const [isOpen, setIsOpen] = React.useState(true)

  return (
    <PayloadUnwrapperM
      items={[userPreferences, subscription, applicationServerKey, registration]}
      customSpinner={<ModalLoading />}
      customError={<ModalError />}
    >
      {([userPreferences, subscription, applicationServerKey, registration]) => {
        // We probably need to rename this boolean
        const { subscribedToPushNotifications } = userPreferences

        return (
          <Modal
            heroImageSrc="/images/notification_modal_image.png"
            title="Make sure you don't miss out"
            open={subscription === undefined && subscribedToPushNotifications === undefined && isOpen}
            onClose={handleClose}
          >
            <ModalBody>
              <Body mt="0">
                To receive an update on your <Span fontWeight={FontWeight.semiBold}>vote results</Span>, let us know if
                you&apos;d like to receive in-browser push notifications.
              </Body>

              <ButtonsContainer>
                <RejectWrapper>
                  <Button type="button" inverse onClick={handleReject}>
                    No thanks
                  </Button>
                </RejectWrapper>

                <AcceptWrapper>
                  <Button type="button" onClick={() => handleAccept(applicationServerKey, registration)}>
                    Yes please! I&apos;d like to be updated
                  </Button>
                </AcceptWrapper>
              </ButtonsContainer>
            </ModalBody>
          </Modal>
        )
      }}
    </PayloadUnwrapperM>
  )
}

// this part of the UI is not a critical path
// failing silently is the best option
const ModalError: React.FC = () => null

const ModalLoading: React.FC = () => null

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: ${Breakpoint.mobile}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`

const RejectWrapper = styled.div`
  padding-right: 2rem;

  @media (max-width: ${Breakpoint.mobile}) {
    padding-top: 1.6rem;
  }
`

const AcceptWrapper = styled.div`
  padding-right: 1.05rem;
`

const ModalBody = styled.div`
  color: ${({ theme }) => theme.colors.primary.medium};
`
