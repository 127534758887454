/* eslint-disable deprecation/deprecation */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { useFormikContext, useField } from 'formik'
import styled, { useTheme } from 'styled-components'
import PhoneInput from 'react-phone-number-input'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Text, SmallBody, FontWeight, Required } from '@tumelo/shared'
import { StyledWarningIcon as WarningIcon } from './WarningIcon'
import 'react-phone-number-input/style.css'

type InputProps = Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'as' | 'ref'>

interface PhoneFieldProps extends InputProps {
  name: string
  label: string
  helperText?: string
  showValidationErrorMessages?: boolean
  required?: boolean
  warningIconRightIndentRem?: 1.5 | 4.5
  autoComplete?: 'tel-national'
}

export const PhoneField: React.FC<PhoneFieldProps> = ({
  name,
  label,
  helperText,
  showValidationErrorMessages,
  required = false,
  autoComplete,
  warningIconRightIndentRem,
  ...rest
}) => {
  const { setFieldValue, getFieldMeta, setFieldTouched, submitCount } = useFormikContext()
  const [field] = useField(name)
  const { touched, error } = getFieldMeta(name)
  const hasError = touched && error != null && submitCount > 0

  return (
    <Container>
      <Label as="label" htmlFor={name}>
        {label}
        {required && <Required />}
      </Label>
      <FlagInput
        international
        flagUrl="/images/worldflags/{xx}.svg"
        defaultCountry="GB"
        placeholder="Enter phone number"
        value={field.value}
        countrySelectProps={{ arrowComponent: CustomArrow }}
        hasError={hasError}
        onChange={async (value: any) => {
          await setFieldValue(field.name, value)
        }}
        id={name}
        name={name}
        onFocus={() => setFieldTouched(name, true)}
        autoComplete={autoComplete}
        countryCallingCodeEditable={false}
        {...rest}
      />

      {(showValidationErrorMessages && hasError) || helperText != null ? (
        <HelperText isError={(hasError && showValidationErrorMessages) ?? false}>
          {hasError && error ? error : helperText}
        </HelperText>
      ) : null}
      {hasError && <WarningIcon top={0.5} indent={warningIconRightIndentRem} />}
    </Container>
  )
}

const CustomArrow: React.FC = () => {
  const { colors } = useTheme()
  return <KeyboardArrowDown style={{ color: colors.dark, marginLeft: '0.2rem', fontSize: '1.8rem' }} />
}

interface StyledInputProps {
  hasError: boolean
}

const Container = styled.div`
  position: relative;
  margin: 1rem 0;
`

const FlagInput = styled(PhoneInput)<StyledInputProps>`
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.8rem;
  border: 1px solid ${({ hasError, theme }) => (hasError ? theme.colors.danger.dark : theme.colors.grey.light)};
  padding: 1.5rem 1rem;
  margin: 2.2rem 0 0 0;

  &:active,
  &:focus {
    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.danger.dark : theme.colors.grey.light)};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey.light};
  }

  .PhoneInputCountry {
    margin: 0;
  }
  input {
    border: none;
    color: ${({ theme }) => theme.colors.primary.medium};
    font-size: 1.6rem;
    outline: none;
  }

  .PhoneInputCountry {
    margin-right: 1rem;
  }
`

const Label = styled(SmallBody)`
  margin: 0;
  line-height: 2.4rem;
  font-weight: ${FontWeight.semiBold};
  position: absolute;
  top: -1.2rem;
  left: 2.9rem;
  background: linear-gradient(${({ theme }) => theme.colors.white} 70%, transparent 70%);
  pointer-events: none;
  padding: 0 0.2rem;
`

const HelperText = styled(Text)<{ isError: boolean }>`
  font-size: 1.3rem;
  position: absolute;
  color: ${(p) => (p.isError ? p.theme.colors.danger.dark : p.theme.colors.dark)};
  left: 2.9rem;
  bottom: -0.4rem;
  line-height: 1.3rem;
  background-color: white;
`
