/* eslint-disable deprecation/deprecation */
import * as React from 'react';
import styled from 'styled-components';
import { Breakpoint } from '../../styles';
import { ListItem } from '../ListItem';
import { IndustryGroupHeader } from './IndustryGroupHeader';
const MAX_WIDTH = '450';
const MIN_WIDTH = '200';
const MOB_WIDTH = '350';
export const IndustryGroup = ({ industry, onItemClick }) => {
    const { organizations, totalPercentage, industryCategory } = industry;
    const organizationCount = organizations.length;
    if (organizationCount === 0) {
        return null;
    }
    const organizationsSliced = organizations.slice(0, Math.min(organizationCount, 10));
    return (React.createElement(IndustryGroupContainer, null,
        React.createElement("div", { className: "dropdown-container" },
            React.createElement(IndustryGroupHeader, { totalPercentage: totalPercentage, industryCategory: industryCategory }),
            React.createElement(CompanyListContainer, null, organizationsSliced.map(({ hasOpenVotes, organization, percentageWeight }) => {
                const key = `${organization.id}`;
                const onClick = onItemClick ? () => onItemClick(key) : undefined;
                return (React.createElement(ListItem, { text: organization.title, percentage: percentageWeight, logoUrl: organization.logoUrl, key: key, onClick: onClick, votesAvailable: hasOpenVotes }));
            })))));
};
const IndustryGroupContainer = styled.div `
  flex: 0 0 30%;
  max-width: ${MAX_WIDTH}px;
  min-width: ${MIN_WIDTH}px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 7rem 1rem;

  @media (max-width: ${Breakpoint.tablet}) {
    width: ${MOB_WIDTH} !important;
    flex: 100% !important;
  }

  @media (max-width: ${Breakpoint.mobile}) {
    margin: 1rem 1rem 3rem 1rem;
  }
`;
const CompanyListContainer = styled.div `
  flex: 0 0 30%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  max-width: ${MAX_WIDTH}px;
  min-width: ${MIN_WIDTH}px;

  @media (max-width: ${Breakpoint.tablet}) {
    width: ${MOB_WIDTH} !important;
    flex: 100% !important;
  }
`;
