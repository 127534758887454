/* eslint-disable deprecation/deprecation */
import React from 'react'
import styled from 'styled-components'
import { FontWeight } from '@tumelo/shared'

interface Props {
  count: number
}

export const NotificationsNumber: React.FC<Props> = ({ count }) => {
  if (count === 0) {
    return null
  }

  return <NumberCircle>{count}</NumberCircle>
}

const NumberCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  left: 2.2rem;
  bottom: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  font-size: 1.2rem;
  font-weight: ${FontWeight.bold};
`
