import { AppThunk } from '../../store'
import * as userPreferencesActions from './index'

export const fetchUserPreferences =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService, loggerService } = services
    dispatch(userPreferencesActions.setUserPreferences('pending'))
    try {
      const userPreferences = await dashboardBffService.GetUserPreferences()
      dispatch(userPreferencesActions.setUserPreferences(userPreferences))
    } catch (e) {
      loggerService.logError(e)
      dispatch(userPreferencesActions.setUserPreferences('error'))
    }
  }

export const setSubscribedToEmails =
  (isSubscribed: boolean): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService, loggerService } = services
    try {
      const userPreferences = await dashboardBffService.SetSubscribedToEmails(isSubscribed)
      dispatch(userPreferencesActions.setUserPreferences(userPreferences))
    } catch (e) {
      loggerService.logError(e)
      dispatch(userPreferencesActions.setUserPreferences('error'))
    }
  }

export const setSubscribedToPushNotifications =
  (isSubscribed: boolean): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService, loggerService } = services

    try {
      const userPreferences = await dashboardBffService.SetSubscribedToPushNotification(isSubscribed)

      dispatch(userPreferencesActions.setUserPreferences(userPreferences))
    } catch (e) {
      loggerService.logError(e)
      dispatch(userPreferencesActions.setUserPreferences('error'))
    }
  }
