import { Analytics } from './Analytics'

export const combineAnalytics = (...analytics: Analytics[]): Analytics => {
  return {
    createIdentity: (userId) => analytics.forEach((a) => a.createIdentity(userId)),
    setUserProperties: (userId, props) => analytics.forEach((a) => a.setUserProperties(userId, props)),
    registerExternalLinkClickListener: () => analytics.forEach((a) => a.registerExternalLinkClickListener()),
    pageView: (page) => analytics.forEach((a) => a.pageView(page)),
    signOutUser: () => analytics.forEach((a) => a.signOutUser()),
    trackEvent: (event) => analytics.forEach((a) => a.trackEvent(event)),
  }
}
