import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgPlay = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.52 2.12C4.67202 2.03223 4.84447 1.98602 5.02 1.98602C5.19554 1.98602 5.36798 2.03223 5.52 2.12L19.52 11.12C19.6604 11.2107 19.7757 11.335 19.8556 11.4818C19.9356 11.6285 19.9774 11.7929 19.9774 11.96C19.9774 12.1271 19.9356 12.2915 19.8556 12.4382C19.7757 12.585 19.6604 12.7093 19.52 12.8L5.52 21.8C5.37227 21.8903 5.20364 21.9406 5.0306 21.9462C4.85756 21.9517 4.68604 21.9123 4.53284 21.8316C4.37963 21.751 4.24999 21.632 4.15659 21.4862C4.06319 21.3404 4.00923 21.1729 4 21V3C3.99951 2.82011 4.04756 2.64341 4.13907 2.48854C4.23059 2.33367 4.36219 2.20635 4.52 2.12ZM6 4.83V19.17L17.15 12L6 4.83Z" })));
};
