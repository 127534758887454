/* eslint-disable deprecation/deprecation */
import styled, { css } from 'styled-components';
import { color, typography, margin } from 'styled-system';
import { Breakpoint } from '../../styles';
import { FontWeight } from './Text';
export const headingFontSize = {
    Heading: css `
    font-size: 2rem;
    @media (min-width: ${Breakpoint.mobile}) {
      font-size: 2.4rem;
    }
  `,
    SubHeading: css `
    font-size: 2rem;
    @media (min-width: ${Breakpoint.mobile}) {
      font-size: 2.4rem;
    }
  `,
    SmallHeading: css `
    font-size: 2rem;
  `,
};
/**
 * @deprecated use designsystem/Typography instead
 */
export const BaseTypography = styled.h2 `
  color: ${({ theme }) => theme.colors.black};
  ${color};
  ${typography};
  ${margin};
`;
/**
 * @deprecated use designsystem/Typography instead
 */
export const Heading = styled(BaseTypography) `
  line-height: 2.8rem;
  font-weight: ${FontWeight.bold};
  @media (min-width: ${Breakpoint.mobile}) {
    line-height: 3.2rem;
  }
  ${headingFontSize.Heading};
`;
/**
 * @deprecated use designsystem/Typography instead
 */
export const SubHeading = styled(BaseTypography) `
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: ${FontWeight.semiBold};
  @media (min-width: ${Breakpoint.mobile}) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
  ${headingFontSize.SubHeading};
`;
/**
 * @deprecated use designsystem/Typography instead
 */
export const SmallHeading = styled(BaseTypography) `
  line-height: 2.8rem;
  font-weight: ${FontWeight.semiBold};
  ${headingFontSize.SmallHeading}
`;
