/* eslint-disable deprecation/deprecation */
import * as React from 'react'
import { Body, FontWeight, ToggleSwitch } from '@tumelo/shared'
import { useAppDispatch, useAppSelector } from '../../application/store'
import { selectUserPreferences } from '../../application/features/userPreferences/selector'
import { fetchUserPreferences, setSubscribedToEmails } from '../../application/features/userPreferences/asyncActions'
import { PayloadUnwrapperM } from '../Error/PayloadUnwrapper'

export const ManageEmailsConnected: React.FC = () => {
  const userPreferences = useAppSelector(selectUserPreferences)
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (userPreferences === 'not-initialised') {
      dispatch(fetchUserPreferences())
    }
  }, [dispatch, userPreferences])

  return (
    <PayloadUnwrapperM
      items={[userPreferences]}
      customSpinner={<ManageEmailsPending />}
      customError={<ManageEmailsError />}
    >
      {([userPreferences]) => {
        const toggleCaption = userPreferences.subscribedEmail
          ? 'Subscribed to receive email reminders of votes.'
          : 'Unsubscribed from email reminders of votes.'
        return (
          <>
            <Body mt={0} mb={10} fontWeight={FontWeight.bold}>
              Email subscription
            </Body>
            <ToggleSwitch
              onClick={async () => {
                dispatch(setSubscribedToEmails(!userPreferences.subscribedEmail))
              }}
              caption={toggleCaption}
              active={userPreferences.subscribedEmail}
              toggleName="email subscription"
            />
          </>
        )
      }}
    </PayloadUnwrapperM>
  )
}

const ManageEmailsPending: React.FC = () => (
  <>
    <Body mt={0} mb={10} fontWeight={FontWeight.bold}>
      Email subscription
    </Body>
    <ToggleSwitch
      disabled
      onClick={() => ({})}
      caption="Fetching your email preferences"
      active
      toggleName="email subscription"
    />
  </>
)

const ManageEmailsError: React.FC = () => (
  <>
    <Body mt={0} mb={10} fontWeight={FontWeight.bold}>
      Email subscription
    </Body>
    <ToggleSwitch
      disabled
      onClick={() => ({})}
      caption="Oh no, there has been an error. Please come back later to update this preference."
      active
      toggleName="email subscription"
    />
  </>
)
