import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetWebPushVAPIDPublicKeyResponse } from '../../../utils/proto/tumelo/dashboardbff/v1/service'
import { Payload } from '../../payload'

interface State {
  publicVAPIDKey: Payload<Uint8Array>
  subscription: Payload<PushSubscription | undefined>
  registration: Payload<ServiceWorkerRegistration>
}

const initialState: State = {
  publicVAPIDKey: 'not-initialised',
  subscription: 'not-initialised',
  registration: 'not-initialised',
}

const pushSubscriptionsSlice = createSlice({
  name: 'push',
  initialState,
  reducers: {
    setVapidKey: (state, action: PayloadAction<GetWebPushVAPIDPublicKeyResponse>) => {
      state.publicVAPIDKey = base64ToUint8Array(action.payload.vapidPublicKey)
    },
    setVapidKeyPending: (state) => {
      state.publicVAPIDKey = 'pending'
    },
    setVapidKeyError: (state) => {
      state.publicVAPIDKey = 'error'
    },
    setRegistration: (state, action: PayloadAction<ServiceWorkerRegistration>) => {
      state.registration = action.payload
    },
    setRegistrationPending: (state) => {
      state.registration = 'pending'
    },
    setRegistrationError: (state) => {
      state.registration = 'error'
    },
    setSubscription: (state, action: PayloadAction<PushSubscription | undefined>) => {
      state.subscription = action.payload
    },
    setSubscriptionPending: (state) => {
      state.subscription = 'pending'
    },
    setSubscriptionError: (state) => {
      state.subscription = 'error'
    },
  },
})

// const base64ToUint8Array = (base64: string) => {
//   const padding = '='.repeat((4 - (base64.length % 4)) % 4)
//   const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/')

//   const rawData = window.atob(b64)

//   return new Uint8Array([...rawData].map((c) => c.charCodeAt(0)))
// }

const base64ToUint8Array = (base64: string) => {
  const padding = '='.repeat((4 - (base64.length % 4)) % 4)
  const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(b64)

  return new Uint8Array([...rawData].map((c) => c.charCodeAt(0)))
}

export default pushSubscriptionsSlice.reducer

export const {
  setVapidKey,
  setVapidKeyPending,
  setVapidKeyError,
  setRegistration,
  setRegistrationPending,
  setRegistrationError,
  setSubscription,
  setSubscriptionPending,
  setSubscriptionError,
} = pushSubscriptionsSlice.actions
