import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgTransparency = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M17.62 7.17C17.1255 5.38667 16.0573 3.81591 14.5805 2.70063C13.1037 1.58535 11.3006 0.987663 9.45 1C7.24316 0.970584 5.11463 1.81711 3.53077 3.35412C1.9469 4.89112 1.03685 6.99327 1 9.2C1.01703 10.8674 1.54076 12.4902 2.50166 13.8529C3.46256 15.2157 4.81518 16.254 6.38 16.83C6.87448 18.6133 7.94272 20.1841 9.41952 21.2994C10.8963 22.4147 12.6994 23.0123 14.55 23C16.7568 23.0294 18.8854 22.1829 20.4692 20.6459C22.0531 19.1089 22.9631 17.0067 23 14.8C22.983 13.1326 22.4592 11.5098 21.4983 10.1471C20.5374 8.78432 19.1848 7.74602 17.62 7.17ZM15.1 6.63C14.92 6.63 14.74 6.63 14.55 6.63C14.36 6.63 13.92 6.63 13.61 6.68L10.13 3.17C11.184 3.27737 12.1933 3.65135 13.0629 4.25669C13.9324 4.86202 14.6334 5.67878 15.1 6.63ZM11.18 7.29C10.66 7.50814 10.164 7.77959 9.7 8.1L5.85 4.24C6.32162 3.91581 6.83672 3.65994 7.38 3.48L11.18 7.29ZM8.08 9.54C7.7001 9.95718 7.36493 10.413 7.08 10.9L3.52 7.28C3.70488 6.73818 3.96763 6.22614 4.3 5.76L8.08 9.54ZM11.24 15L8.76 12.53C8.97783 12.0121 9.26733 11.5274 9.62 11.09L12.83 14.3C12.3368 14.6092 11.8012 14.845 11.24 15ZM14.43 12.84L11.25 9.66C11.7534 9.36636 12.2953 9.14423 12.86 9L15.27 11.42C15.0575 11.9333 14.7748 12.4145 14.43 12.85V12.84ZM3.27 10.09L6.27 13.09C6.18543 13.492 6.13193 13.8998 6.11 14.31C5.35997 13.8534 4.71773 13.2398 4.22748 12.5113C3.73722 11.7828 3.41057 10.9568 3.27 10.09ZM14.55 20.87C13.3744 20.877 12.2206 20.5524 11.2212 19.9333C10.2218 19.3142 9.41726 18.4257 8.9 17.37C9.08 17.37 9.26 17.37 9.45 17.37C11.5749 17.3935 13.6287 16.606 15.1932 15.1679C16.7576 13.7298 17.7149 11.7493 17.87 9.63C18.7628 10.1593 19.5029 10.9111 20.0182 11.8121C20.5336 12.713 20.8064 13.7321 20.81 14.77C20.8009 15.582 20.6316 16.3842 20.3118 17.1306C19.992 17.877 19.528 18.5529 18.9464 19.1196C18.3648 19.6863 17.6771 20.1327 16.9227 20.433C16.1682 20.7334 15.3619 20.8819 14.55 20.87Z" })));
};
