/* eslint-disable deprecation/deprecation */
import { Collapse } from '@mui/material';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Stack } from '@tumelo/designsystem';
import { getMinMaxTimestamp } from '../../application/OrganizationBreakdown/BasedOn';
import { TypographyCaption } from '../../buildingBlocks/Typography/Text';
import { timestampToDate } from '../../domain';
import { Formatting } from '../../utils';
export const ValidBetween = ({ breakdownBasedOn, helpText }) => {
    const [isOpen, setOpen] = React.useState(false);
    const { colors } = useTheme();
    const sentence = createSentence(breakdownBasedOn);
    if (sentence === undefined) {
        return null;
    }
    return (React.createElement(Margin, null,
        React.createElement(Stack, { flexDirection: "row" },
            React.createElement(TypographyCaption, null, sentence),
            helpText ? (React.createElement(Icon, { onClick: () => setOpen(!isOpen) }, isOpen ? (React.createElement("svg", { fill: colors.primary.medium, xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20" },
                React.createElement("g", { id: "Group_13185", "data-name": "Group 13185", transform: "translate(-400 -40)" },
                    React.createElement("path", { id: "_", "data-name": "(?)", d: "M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,10,18Z", transform: "translate(400 40)", fill: "#3a3c62" }),
                    React.createElement("path", { id: "Path_9357", "data-name": "Path 9357", d: "M14.164,5.613a.676.676,0,0,0-.958,0L9.885,8.927,6.564,5.606a.677.677,0,0,0-.958.958L8.927,9.885,5.606,13.206a.677.677,0,1,0,.958.958l3.321-3.321,3.321,3.321a.677.677,0,1,0,.958-.958L10.843,9.885l3.321-3.321A.681.681,0,0,0,14.164,5.613Z", transform: "translate(400.092 40)", fill: "#3a3c62", stroke: "#3a3c62", strokeWidth: "0.5" })))) : (React.createElement("svg", { fill: colors.primary.medium, xmlns: "http://www.w3.org/2000/svg", id: "helpIcon", width: "20", height: "20", "data-name": "Layer 2", viewBox: "0 0 20 20" },
                React.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
                    React.createElement("path", { id: "Path_8428", d: "M9 16h2v-2H9zm1-16a10 10 0 1 0 10 10A10 10 0 0 0 10 0zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm0-14a4 4 0 0 0-4 4h2a2 2 0 0 1 4 0c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5a4 4 0 0 0-4-4z", className: "cls-1", "data-name": "Path 8428" })))))) : null),
        helpText ? (React.createElement(Collapse, { in: isOpen },
            React.createElement(HelperText, null, helpText))) : null));
};
const createSentence = (basedOn) => {
    const minMax = getMinMaxTimestamp(basedOn);
    switch (minMax.length) {
        case 1:
            return `This data was collected on ${Formatting.date(timestampToDate(minMax[0]))}`;
        case 2:
            return `This data was collected between ${Formatting.date(timestampToDate(minMax[0]))} and ${Formatting.date(timestampToDate(minMax[1]))}`;
        default:
            return undefined;
    }
};
const Margin = styled.div `
  margin: 2rem 0 1rem;
`;
const HelperText = styled(TypographyCaption) `
  color: ${({ theme }) => theme.colors.grey.dark};
  white-space: pre-wrap;
  margin: 0;
`;
const Icon = styled.div `
  display: inline-flex;
  cursor: pointer;
  margin-left: 1.2rem;
  color: ${({ theme }) => theme.colors.primary.medium};
`;
