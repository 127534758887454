import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgArrowleft = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.08002 11.54C3.98 11.2965 3.98 11.0235 4.08002 10.78C4.12583 10.6564 4.19742 10.5439 4.29002 10.45L11.29 3.45C11.4783 3.26169 11.7337 3.15591 12 3.15591C12.2663 3.15591 12.5217 3.26169 12.71 3.45C12.8983 3.6383 13.0041 3.8937 13.0041 4.16C13.0041 4.4263 12.8983 4.68169 12.71 4.87L7.41002 10.16H19C19.2652 10.16 19.5196 10.2654 19.7071 10.4529C19.8947 10.6404 20 10.8948 20 11.16C20 11.4252 19.8947 11.6796 19.7071 11.8671C19.5196 12.0546 19.2652 12.16 19 12.16H7.41002L12.71 17.45C12.8033 17.5432 12.8772 17.6539 12.9277 17.7757C12.9781 17.8976 13.0041 18.0281 13.0041 18.16C13.0041 18.2919 12.9781 18.4224 12.9277 18.5442C12.8772 18.6661 12.8033 18.7768 12.71 18.87C12.6168 18.9632 12.5061 19.0372 12.3843 19.0877C12.2624 19.1381 12.1319 19.1641 12 19.1641C11.8682 19.1641 11.7376 19.1381 11.6158 19.0877C11.4939 19.0372 11.3833 18.9632 11.29 18.87L4.29002 11.87C4.20169 11.7728 4.13066 11.6612 4.08002 11.54Z" })));
};
