import * as React from 'react';
import { useTheme } from 'styled-components';
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
export const SvgChevrondown = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.29 15.71C11.383 15.8037 11.4936 15.8781 11.6154 15.9289C11.7373 15.9797 11.868 16.0058 12 16.0058C12.132 16.0058 12.2627 15.9797 12.3846 15.9289C12.5064 15.8781 12.617 15.8037 12.71 15.71L18.71 9.71C18.8032 9.61676 18.8772 9.50607 18.9277 9.38425C18.9781 9.26243 19.0041 9.13186 19.0041 9C19.0041 8.86814 18.9781 8.73757 18.9277 8.61575C18.8772 8.49393 18.8032 8.38324 18.71 8.29C18.6168 8.19676 18.5061 8.1228 18.3843 8.07234C18.2624 8.02188 18.1319 7.99591 18 7.99591C17.8681 7.99591 17.7376 8.02188 17.6158 8.07234C17.4939 8.1228 17.3832 8.19676 17.29 8.29L12 13.59L6.71 8.29C6.5217 8.1017 6.2663 7.99591 6 7.99591C5.7337 7.99591 5.47831 8.1017 5.29 8.29C5.1017 8.47831 4.99591 8.7337 4.99591 9C4.99591 9.2663 5.1017 9.5217 5.29 9.71L11.29 15.71Z" })));
};
