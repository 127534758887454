/* eslint-disable deprecation/deprecation */
import React from 'react';
import styled from 'styled-components';
import { Modal } from '@mui/material';
import { Button, SmallBody, FontWeight } from '../../buildingBlocks';
import { Breakpoint } from '../../styles';
import { hexAlpha } from '../../utils';
// The display of this component is to be handled by its consumer
export const CookieBanner = ({ warningText, onClickReject, onClickAccept }) => {
    return (React.createElement(Modal, { open: true, role: "dialog", "aria-labelledby": "cookiePolicyDesc" },
        React.createElement(CookieBannerBox, { warningText: warningText, onClickReject: onClickReject, onClickAccept: onClickAccept })));
};
export const CookieBannerBox = ({ warningText, onClickReject, onClickAccept }) => {
    return (React.createElement(Box, null,
        React.createElement(WarningText, null,
            React.createElement(SmallBody, { id: "cookiePolicyDesc", margin: 0, fontWeight: FontWeight.semiBold }, warningText)),
        React.createElement(Buttons, null,
            React.createElement(Button, { size: "small", onClick: onClickAccept },
                "All the cookies",
                ' ',
                React.createElement("span", { role: "img", "aria-label": "cookie emoji" }, "\uD83C\uDF6A")),
            React.createElement(Button, { size: "small", inverse: true, onClick: onClickReject }, "No cookies for you!"))));
};
const Buttons = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    &:first-child {
      margin-right: 1.3rem;
    }
  }
  // Using an existing breakpoints doesn't look as good
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    width: 100%;
    > * {
      &:first-child {
        margin: 0 0 1.3rem;
      }
    }
  }
`;
const WarningText = styled.div `
  width: 65%;
  padding-right: 1rem;
  display: inline-block;
  @media (max-width: ${Breakpoint.tablet}) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 1.4rem;
  }
`;
const Box = styled.div `
  border-radius: 4px;
  width: calc(100% - 2rem);
  box-sizing: border-box;
  min-height: 10rem;
  padding: 1.6rem;
  display: flex;
  background: ${({ theme }) => hexAlpha(theme.colors.white, 0.92)};
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${Breakpoint.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
  position: fixed;
  left: 1rem;
  bottom: 1rem;
`;
