/* eslint-disable deprecation/deprecation */
import React from 'react';
import styled from 'styled-components';
import { TypographyCaption } from '../../../buildingBlocks';
import { Breakpoint, BreakpointAsMaxValue } from '../../../styles';
import { useBreakpoints } from '../../../utils';
import { Content } from '../../ContentContainer';
import { FiltersIcon } from '../../../buildingBlocks/Icons/FiltersIcon';
import { DesktopSearchMenu } from './components/DesktopSearchMenu';
import { MobileSearchMenu } from './components/MobileSearchMenu';
export const SearchMenuLayout = ({ menuHeading, menu, toggleMenuCallback, isMenuOpenOnMobile, children, }) => {
    const { isTabletOrSmaller } = useBreakpoints();
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            isTabletOrSmaller && (React.createElement(MobileSearchMenu, { heading: menuHeading, isOpen: isMenuOpenOnMobile, toggleOpen: toggleMenuCallback }, menu)),
            !isTabletOrSmaller && React.createElement(DesktopSearchMenu, { heading: menuHeading }, menu)),
        React.createElement(Content, { tabIndex: 0 },
            React.createElement(FiltersButton, { tabIndex: 0, onClick: toggleMenuCallback, type: "button" },
                React.createElement(FiltersIcon, { height: 22, width: 22 }),
                React.createElement(TypographyCaption, { m: 0 }, "Filters")),
            children)));
};
const Container = styled.aside `
  display: flex;
  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    flex-direction: column;
  }
`;
const FiltersButton = styled.button `
  border: none;
  cursor: pointer;
  background: none;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 1.5rem;
  svg {
    margin-right: 1.6rem;
  }
  @media (min-width: ${Breakpoint.tablet}) {
    display: none;
  }
`;
