/* eslint-disable deprecation/deprecation */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Collapse, IconButton } from '@mui/material';
import { CancelOutlined, HelpOutlineOutlined } from '@mui/icons-material';
import { TypographyCaption } from '../Typography';
import { Breakpoint } from '../../styles/media-breakpoints';
export const WithHelp = ({ children, helpText }) => {
    const [isOpen, setOpen] = React.useState(false);
    const { colors } = useContext(ThemeContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(Flex, null,
            children,
            helpText && (React.createElement(StyledIconButton, { title: "Toggle help", disableRipple: true, onClick: () => setOpen(!isOpen) }, isOpen ? (React.createElement(CancelOutlined, { style: { fontSize: '2rem' } })) : (React.createElement(HelpOutlineOutlined, { style: { fontSize: '2rem' } }))))),
        React.createElement(Help, null,
            React.createElement(Collapse, { in: isOpen },
                React.createElement(TypographyCaption, { as: "p", color: colors.grey.dark }, helpText)))));
};
const Help = styled.div `
  order: 1;
  flex-basis: 100%;
  flex-grow: 1;
  @media (max-width: ${Breakpoint.tablet}) {
    order: 0;
  }
`;
const Flex = styled.div `
  display: flex;
`;
const StyledIconButton = styled(IconButton) `
  color: ${({ theme }) => theme.colors.primary.medium};
  align-self: flex-start;
`;
