/* eslint-disable deprecation/deprecation */
import React from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'
import { Poll, Caption, timestampToDate } from '@tumelo/shared'
import { TotalVotesContainer } from './TotalVotes'

interface Props {
  poll: Poll
}

export const ResultsDue: React.FC<Props> = ({ poll }) => (
  <Row>
    <TotalVotesContainer poll={poll} />
    <Row>
      <Column>
        <ResultDate weight="bold">AGM date</ResultDate>
        <ResultDate data-testid="results-due">
          {format(timestampToDate(poll.relationships.generalMeeting.date), 'dd/MM/yyyy')}
        </ResultDate>
      </Column>
    </Row>
  </Row>
)

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 5.5rem;
`

const Column = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  text-align: right;
`

const ResultDate = styled(Caption)`
  line-height: 2rem;
`
