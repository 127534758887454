/* eslint-disable consistent-return, default-case, spaced-comment */
import * as z from 'myzod'

const UserConfigurableCardSectionSchema = z
  .object({
    userCanDisable: z.boolean(),
    id: z.number().default(1),
  })
  .default({ id: 1, userCanDisable: false })
export type UserConfigurableCardSection = z.Infer<typeof UserConfigurableCardSectionSchema>

const TestCardSchema = z.object(
  {
    type: z.literal('test-card'),
    userConfig: UserConfigurableCardSectionSchema,
  },
  { allowUnknown: true }
)

export type TestCard = z.Infer<typeof TestCardSchema>

const CustomContentCardSchema = z.object(
  {
    type: z.literal('custom-content-card'),
    title: z.string(),
    body: z.string(), //markdown
    backgroundColor: z.string().optional(),
    callToAction: z
      .object({
        type: z.literal('external-link').default(), //although currently useless, presumption here is that we will probably need a DU here to allow addition of internal links etc
        text: z.string(),
        targetUrl: z.string(),
        openNewTab: z.boolean().default(true),
      })
      .optional(),
    userConfig: UserConfigurableCardSectionSchema,
  },
  { allowUnknown: true }
)

export type CustomContentCard = z.Infer<typeof CustomContentCardSchema>

const YourVotingInterestsCardSchema = z.object(
  {
    type: z.literal('your-voting-interests-card'),
    minVotesThreshold: z.number().default(15),
    userConfig: UserConfigurableCardSectionSchema,
  },
  { allowUnknown: true }
)

export type YourVotingInterestsCard = z.Infer<typeof YourVotingInterestsCardSchema>

const SeeWhereYourVoteGoesCardSchema = z.object(
  {
    type: z.literal('see-where-your-vote-goes-card'),
    userConfig: UserConfigurableCardSectionSchema,
  },
  { allowUnknown: true }
)
export type SeeWhereYourVoteGoesCard = z.Infer<typeof SeeWhereYourVoteGoesCardSchema>

const YourColleaguesVotedCardSchema = z.object(
  {
    type: z.literal('your-colleagues-voted-card'),
    userConfig: UserConfigurableCardSectionSchema,
  },
  { allowUnknown: true }
)
export type YourColleaguesVotedCard = z.Infer<typeof YourColleaguesVotedCardSchema>

const RecentMostPopularVoteResultSchema = z.object(
  {
    type: z.literal('recent-most-popular-vote-result-card'),
    windowDays: z.number().default(15),
    userConfig: UserConfigurableCardSectionSchema,
  },
  { allowUnknown: true }
)
export type RecentMostPopularVoteResultCard = z.Infer<typeof RecentMostPopularVoteResultSchema>

const ReturningCardSchema = z.union([
  TestCardSchema,
  CustomContentCardSchema,
  YourVotingInterestsCardSchema,
  SeeWhereYourVoteGoesCardSchema,
  YourColleaguesVotedCardSchema,
  RecentMostPopularVoteResultSchema,
])
export type ReturningCard = z.Infer<typeof ReturningCardSchema>

export const matchCardExhaustive =
  <T>({
    testCard,
    customContentCard,
    yourVotingInterestsCard,
    seeWhereYourVoteGoesCard,
    yourColleaguesVotedCard,
    recentMostPopularVoteCard,
  }: {
    testCard: (x: TestCard) => T
    customContentCard: (x: CustomContentCard) => T
    yourVotingInterestsCard: (x: YourVotingInterestsCard) => T
    seeWhereYourVoteGoesCard: (x: SeeWhereYourVoteGoesCard) => T
    yourColleaguesVotedCard: (x: YourColleaguesVotedCard) => T
    recentMostPopularVoteCard: (x: RecentMostPopularVoteResultCard) => T
  }) =>
  (card: ReturningCard) => {
    switch (card.type) {
      case 'test-card':
        return testCard(card)
      case 'custom-content-card':
        return customContentCard(card)
      case 'your-voting-interests-card':
        return yourVotingInterestsCard(card)
      case 'see-where-your-vote-goes-card':
        return seeWhereYourVoteGoesCard(card)
      case 'your-colleagues-voted-card':
        return yourColleaguesVotedCard(card)
      case 'recent-most-popular-vote-result-card':
        return recentMostPopularVoteCard(card)
    }
  }

export const toNiceCardName = matchCardExhaustive({
  customContentCard: (c) => `Custom content card "${c.title}"`,
  recentMostPopularVoteCard: () => 'Recent most popular vote card',
  testCard: () => 'Test card',
  seeWhereYourVoteGoesCard: () => 'See where your vote goes card',
  yourColleaguesVotedCard: () => 'Your colleagues voted card',
  yourVotingInterestsCard: () => 'Your voting interests card',
})

export const ReturningCardsSchema = z
  .object({
    cards: z.array(ReturningCardSchema),
  })
  .default({
    cards: [
      { type: 'see-where-your-vote-goes-card', userConfig: { id: 1, userCanDisable: false } },
      { type: 'recent-most-popular-vote-result-card', windowDays: 15, userConfig: { id: 2, userCanDisable: false } },
      { type: 'your-voting-interests-card', minVotesThreshold: 15, userConfig: { id: 1, userCanDisable: false } },
      { type: 'your-colleagues-voted-card', userConfig: { id: 1, userCanDisable: false } },
    ],
  })

export type SoftConfigSettingsReturningCards = z.Infer<typeof ReturningCardsSchema>
