import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Payload } from '../../payload'
import { BallotWithPollAndOrg } from '../../types/PollWithOrganization/PollWithOrganization'

type InvestorVotesState = Payload<Array<BallotWithPollAndOrg>>

const getInitialState = (): InvestorVotesState => 'not-initialised'

const investorVotesSlice = createSlice({
  name: 'investorVotes',
  initialState: getInitialState(),
  reducers: {
    setInvestorVotes: (_, { payload }: PayloadAction<Payload<BallotWithPollAndOrg[]>>) => payload,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setPending: (_, __: PayloadAction<void>) => 'pending',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setError: (_, __: PayloadAction<void>) => 'error',
  },
})

export default investorVotesSlice.reducer

export const { setInvestorVotes, setError, setPending } = investorVotesSlice.actions
